// Step2.js

import React, { useState } from 'react'; 
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../global.css';


function Step2({onSubmit, advSt, initialPfrs}) {
  const [textAreas, setTextAreas] = useState(initialPfrs); // Start with passed in values


  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  return (
    <div>
      <div className='block'><h3 className="title is-4">Prayers for Relief</h3></div>
      <form onSubmit={onSubmit}>
      <div className='block'>
        {textAreas.map((text, index) => (
          <div key={index} className="box is-draft">
            <h3 className="title is-5">Prayer for Relief {index + 1}</h3>
            <textarea className="textarea" id= {`pfr${index}`} name={`pfr${index}`} defaultValue={text} rows="4" cols="50" />

            <div className="field">
              <label htmlFor={`ignore-pfr${index}`}>
              <input className="is-checkradio" type="checkbox" id={`ignore-pfr${index}`} name={`ignore-pfr${index}`}/>
              Ignore this Prayer for Relief
              </label>
            </div>

          </div>
        ))}
        </div>
        <div className='block'>
          <button type="button" className="button is-small is-secondary" onClick={addTextArea}>
            <span className='icon'>
              <Icon path={mdiPlus} size={1} />
            </span>
            <span>
              Add Prayer for Relief
            </span>
          </button>
        </div>
        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Update draft and continue</button>
        </div>
      </form>
    </div>
  );
}

export default Step2;
