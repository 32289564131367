import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from './Constants';
import { ensureHeaders } from './auth/useSetHeaders';
import './global.css';

const CaseViewer = () => {
  const { caseId } = useParams(); // Get the citation from the URL
  const [citationData, setCitationData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchCitationData = async () => {
      await ensureHeaders();
      try {
        const response = await axios.get(`${API_URL}opinions/${caseId}`);
        setCitationData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching citation data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCitationData();
  }, [caseId]);

  return (
    <div className="App">
      <div className="columns is-gapless" style={{ margin: '20px' }}>
        {/* Main content */}
        <div className="column is-three-quarters">
          <div className="dropdown-container">
            <h2>Citation Viewer</h2>
            {loading ? (
              <p>Loading citation details...</p>
            ) : citationData ? (
              <div>
                <h3>Citation Details</h3>
                <p>{citationData.opinion.html_with_citations}</p>
                {/* Display other citation information as needed */}
              </div>
            ) : (
              <p>No details found for citation {caseId}</p>
            )}
          </div>
        </div>
  
        {/* Bulma card on the right */}
        <div className="column is-one-quarter">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Case Details</p>
            </header>
            <div className="card-content">
              <div className="content">
                {citationData ? (
                  <>
                    <p>{citationData.cluster.case_name}</p>
                    <ul>
                      <li>Date Filed: {citationData.cluster.date_filed}</li>
                      <li>Judges: {citationData.cluster.judges}</li>
                    </ul>
                  </>
                ) : (
                  <p>Loading case details...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseViewer;
