// ModalNewDraft.js

import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from './Constants';
import 'bulma-switch/dist/css/bulma-switch.min.css';
import './global.css';

const ModalNewDraft = ({ isActive, stateSetter, refreshDrafts, projectId }) => {
    const [newDraftType, setNewDraftType] = useState('0');
    const [newDraftName, setNewDraftName] = useState('');
    
    function dismiss() {
        stateSetter(false);
    };

    const handleCreateDraft = async (e) => {
        e.preventDefault();
        try {
          await axios.post(API_URL + 'create-draft', {
            'proj-id': projectId,
            'draft-name': newDraftName,
            'draft-type': newDraftType
          });
          setNewDraftName('');
          setNewDraftType('0');
          refreshDrafts(); 
          dismiss();
        } catch (error) {
          console.error('Error creating draft:', error);
        }
      };

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="block">
        <div className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
          <h2 className="title is-4" style={{ marginBottom: '0px'}}>Create Draft</h2>
          <button className="modal-close is-large" aria-label="close" onClick={dismiss} style={{position: 'relative', top: 'auto', right: 'auto', color: '#000000'}}></button>
        </div>
          <form onSubmit={handleCreateDraft}>
              <div className="field">
              <label className="label">Draft Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={newDraftName}
                    onChange={(e) => setNewDraftName(e.target.value)}
                    placeholder="Enter draft name"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="label" htmlFor="draftType">Draft Type</label>
                  <select
                    className="select is-primary"
                    value={newDraftType}
                    onChange={(e) => setNewDraftType(e.target.value)}
                    required
                  >
                    <option value="0">Complaint</option>
                    <option value="1">Answer</option>
                    <option value="2">Request for Production</option>
                    <option value="3">Interrogatories</option>
                    <option value="4">Request for Admissions</option>
                    {/* <option value="5">Objections to RFPs</option> */}
                    <option value="6">Complaint Analyzer</option>
                  </select>
                </div>
              </div>
              <button className="button is-link" type="submit" style={{marginTop: '20px', width: '100%'}}>Create Draft</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalNewDraft;