import React, { useState } from "react";
import "./Research.css";
import axios from "axios";
import { API_URL } from "../Constants";
import { ensureHeaders } from '../auth/useSetHeaders';

const Research = () => {
  const [input, setInput] = useState("");
  const [results, setResults] = useState([]); // Store user inputs and API responses

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!input.trim()) {
      return; // Do nothing if input is empty
    }

    try {
      // Call the backend API
      await ensureHeaders();
      const response = await axios.post(API_URL + "research", {
        query: input,
      });

      // Append user input and API result to the results array
      setResults((prevResults) => [
        ...prevResults,
        { input, response: response.data }, // Store both input and the API response
      ]);

      setInput(""); // Clear the input field after submission
    } catch (error) {
      console.error("Error calling the API:", error);
      // Optionally, handle errors (e.g., show an error message)
      setResults((prevResults) => [
        ...prevResults,
        { input, response: "Error fetching data. Please try again." },
      ]);
    }
  };

  return (
    <div className="research-container">

      {/* Results Section */}
      <div className="results-container">
        {results.map((result, index) => (
          <div key={index} className="result-item">
            <p><strong>Input:</strong> {result.input}</p>
            <p><strong>Response:</strong> {result.response}</p>
            <hr />
          </div>
        ))}
      </div>

      {/* Input Form */}
      <form className="input-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="chat-input"
          placeholder="Type your research question..."
          value={input}
          onChange={handleInputChange}
        />
        <button type="submit" className="submit-button">
          Send
        </button>
      </form>
    </div>
  );
};

export default Research;
