// Step2.js

import React, { useState } from "react";
import '../global.css';

function Step2({onSubmit, inputObj}) {

  // State to track visibility of each card content
  const [visibleCards, setVisibleCards] = useState(
    Object.fromEntries(Object.keys(inputObj).map((key) => [key, false]))
  );

  // Toggle visibility function
  const toggleVisibility = (key) => {
    setVisibleCards((prevVisibleCards) => ({
      ...prevVisibleCards,
      [key]: !prevVisibleCards[key] // Toggle the visibility
    }));
  };
  

  return (
    <form onSubmit={onSubmit}>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Legal Element Assessment</h1>
      </div>
      <div>
        {Object.entries(inputObj).map(([key, value]) => (
          <div key={key} className="card box is-draft" style={{ padding: '0' }}>
            <header className="card-header" style={{ boxShadow: 'none' }}>
              <h3 className="card-header-title title is-6" style={{ marginBottom: "0px", padding: '16px 24px 16px 24px' }}>{key}</h3>
              <button
                type="button"
                className="card-header-icon"
                aria-label="toggle visibility"
                onClick={() => toggleVisibility(key)}
              >
                <span className="icon">
                  <i
                    className={`fas ${visibleCards[key] ? "fa-angle-up" : "fa-angle-down"}`}
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>
            {visibleCards[key] && (
              <div className="card-content" style={{ padding: '0px 24px 12px 24px' }}>
                <div className="content">
                  <p className="body" style={{ marginBottom: '20px' }}>{value.descrpiton}</p>
                  <b className="title is-7" style={{ marginBottom: '8px' }}>Legal element checker</b>

                  {value.elements.map((element, index) => (
                    <div key={index} className="table-row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <b className="body" style={{ fontWeight: '600', flex: 1, display: 'flex', alignItems: 'center' }}>
                        {index+1}. {element.name} - {element.source}
                        </b>
                        <span>
                          {element.satisfied ? (
                            <span className="tag met" disabled>Met</span>
                          ) : (
                            <span className="tag not-met" disabled>Not met</span>
                          )}
                        </span>
                      </div>
                      
                      <div style={{ width: '100%' }}>
                        <div>
                        {!element.satisfied && element.analysis && ( //focus here.
                          <div style={{ marginTop: "12px", marginBottom: "8px", color: "red", backgroundColor: "#F5F5F5", borderRadius: "8px", padding: "8px 12px" }}>
                            {element.analysis.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                        </div>  
                      </div>

                      
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit" style={{ marginTop:'20px'}}>View recommendations</button>
        </div>
      </form>
  );
}

export default Step2;
