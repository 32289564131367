// Step3.js

import React from 'react';
import '../global.css';


function Step3({onSubmit, recomendations}) {
  return (
    <div>      
      <div class="block" style={{ marginBottom: '8px' }}>
        <h1 className="title is-4">Recomendations</h1>
      </div>

      <div className='block'>You can make edits directly in the input box. Your changes will be saved and available for download in the next step.</div>
      <form onSubmit={onSubmit}>
        <div className='block'>
        <textarea className="textarea" name="recomendations" id="recomendations" defaultValue={recomendations.body} rows="20" cols="60"></textarea>
        </div>

        {/* citations go here if not inline. */}
        <ul>
          {recomendations.citations.map((citation, index) => (
            <li key={index}>
              <a href={`${citation.prefix}/${citation.id}`} target="_blank" rel="noopener noreferrer">
                {citation.displayName}
              </a>
            </li>
          ))}
        </ul>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Proceed to download</button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
