import React, { useState } from 'react';
import '../global.css';

function CourtSelector({venuSetter}) {
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [thirdValue, setThirdValue] = useState('');

  const handleFirstChange = (event) => {
    setFirstValue(event.target.value);
    setSecondValue(''); // Reset second value when the first value changes
  };

  const handleSecondChange = (event) => {
    setSecondValue(event.target.value);
    if (firstValue === 'federal'){
        venuSetter(event.target.value);
    }
  };

  const handleThirdChange = (event) => {
    setThirdValue(event.target.value);
    venuSetter(secondValue + " " + event.target.value);
  };

  return (
    <div>
      <label className='label' htmlFor="venue">Jurisdiction / Venue:</label>
      <select className='select' id="firstSelector" value={firstValue} onChange={handleFirstChange}>
        <option value="">Select an option</option>
        <option value="federal">Federal Court</option>
        <option value="state">State Court</option>
      </select>

      {firstValue && (
        <div>
          <label className='label' htmlFor="secondSelector" style={{marginTop: '20px'}}>{firstValue === 'state' ? 'State' : 'District'}</label>
          <select className='select' id="secondSelector" value={secondValue} onChange={handleSecondChange}>
            <option value="">Select a {firstValue === 'state' ? 'State' : 'District'}</option>
            {firstValue === 'state' && (
              <>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
              </>
            )}
            {firstValue === 'federal' && (
              <>
                <optgroup label="United States District Court for the">
                <option value="District of Alabama, Middle">District of Alabama, Middle</option>
                <option value="District of Alabama, Northern">District of Alabama, Northern</option>
                <option value="District of Alabama, Southern">District of Alabama, Southern</option>
                <option value="District of Alaska">District of Alaska</option>
                <option value="District of Arizona">District of Arizona</option>
                <option value="Eastern District of Arkansas">Eastern District of Arkansas</option>
                <option value="Western District of Arkansas">Western District of Arkansas</option>
                <option value="Central District of California">Central District of California</option>
                <option value="Eastern District of California">Eastern District of California</option>
                <option value="Northern District of California">Northern District of California</option>
                <option value="Southern District of California">Southern District of California</option>
                <option value="District of Colorado">District of Colorado</option>
                <option value="District of Connecticut">District of Connecticut</option>
                <option value="District of Delaware">District of Delaware</option>
                <option value="District of Columbia">District of Columbia</option>
                <option value="Northern District of Florida">Northern District of Florida</option>
                <option value="Middle District of Florida">Middle District of Florida</option>
                <option value="Southern District of Florida">Southern District of Florida</option>
                <option value="Northern District of Georgia">Northern District of Georgia</option>
                <option value="Middle District of Georgia">Middle District of Georgia</option>
                <option value="Southern District of Georgia">Southern District of Georgia</option>
                <option value="District of Guam">District of Guam</option>
                <option value="District of Hawaii">District of Hawaii</option>
                <option value="District of Idaho">District of Idaho</option>
                <option value="Central District of Illinois">Central District of Illinois</option>
                <option value="Northern District of Illinois">Northern District of Illinois</option>
                <option value="Southern District of Illinois">Southern District of Illinois</option>
                <option value="Northern District of Indiana">Northern District of Indiana</option>
                <option value="Southern District of Indiana">Southern District of Indiana</option>
                <option value="Northern District of Iowa">Northern District of Iowa</option>
                <option value="Southern District of Iowa">Southern District of Iowa</option>
                <option value="District of Kansas">District of Kansas</option>
                <option value="Eastern District of Kentucky">Eastern District of Kentucky</option>
                <option value="Western District of Kentucky">Western District of Kentucky</option>
                <option value="Eastern District of Louisiana">Eastern District of Louisiana</option>
                <option value="Middle District of Louisiana">Middle District of Louisiana</option>
                <option value="Western District of Louisiana">Western District of Louisiana</option>
                <option value="District of Maine">District of Maine</option>
                <option value="District of Maryland">District of Maryland</option>
                <option value="District of Massachusetts">District of Massachusetts</option>
                <option value="Eastern District of Michigan">Eastern District of Michigan</option>
                <option value="Western District of Michigan">Western District of Michigan</option>
                <option value="District of Minnesota">District of Minnesota</option>
                <option value="Northern District of Mississippi">Northern District of Mississippi</option>
                <option value="Southern District of Mississippi">Southern District of Mississippi</option>
                <option value="Eastern District of Missouri">Eastern District of Missouri</option>
                <option value="Western District of Missouri">Western District of Missouri</option>
                <option value="District of Montana">District of Montana</option>
                <option value="District of Nebraska">District of Nebraska</option>
                <option value="District of Nevada">District of Nevada</option>
                <option value="District of New Hampshire">District of New Hampshire</option>
                <option value="District of New Jersey">District of New Jersey</option>
                <option value="District of New Mexico">District of New Mexico</option>
                <option value="Eastern District of New York">Eastern District of New York</option>
                <option value="Northern District of New York">Northern District of New York</option>
                <option value="Southern District of New York">Southern District of New York</option>
                <option value="Western District of New York">Western District of New York</option>
                <option value="Eastern District of North Carolina">Eastern District of North Carolina</option>
                <option value="Middle District of North Carolina">Middle District of North Carolina</option>
                <option value="Western District of North Carolina">Western District of North Carolina</option>
                <option value="District of North Dakota">District of North Dakota</option>
                <option value="Northern District of Ohio">Northern District of Ohio</option>
                <option value="Southern District of Ohio">Southern District of Ohio</option>
                <option value="Eastern District of Oklahoma">Eastern District of Oklahoma</option>
                <option value="Northern District of Oklahoma">Northern District of Oklahoma</option>
                <option value="Western District of Oklahoma">Western District of Oklahoma</option>
                <option value="District of Oregon">District of Oregon</option>
                <option value="Eastern District of Pennsylvania">Eastern District of Pennsylvania</option>
                <option value="Middle District of Pennsylvania">Middle District of Pennsylvania</option>
                <option value="Western District of Pennsylvania">Western District of Pennsylvania</option>
                <option value="District of Puerto Rico">District of Puerto Rico</option>
                <option value="District of Rhode Island">District of Rhode Island</option>
                <option value="District of South Carolina">District of South Carolina</option>
                <option value="District of South Dakota">District of South Dakota</option>
                <option value="Eastern District of Tennessee">Eastern District of Tennessee</option>
                <option value="Middle District of Tennessee">Middle District of Tennessee</option>
                <option value="Western District of Tennessee">Western District of Tennessee</option>
                <option value="Eastern District of Texas">Eastern District of Texas</option>
                <option value="Northern District of Texas">Northern District of Texas</option>
                <option value="Southern District of Texas">Southern District of Texas</option>
                <option value="Western District of Texas">Western District of Texas</option>
                <option value="District of Utah">District of Utah</option>
                <option value="District of Vermont">District of Vermont</option>
                <option value="Eastern District of Virginia">Eastern District of Virginia</option>
                <option value="Western District of Virginia">Western District of Virginia</option>
                <option value="Eastern District of Washington">Eastern District of Washington</option>
                <option value="Western District of Washington">Western District of Washington</option>
                <option value="Northern District of West Virginia">Northern District of West Virginia</option>
                <option value="Southern District of West Virginia">Southern District of West Virginia</option>
                <option value="Eastern District of Wisconsin">Eastern District of Wisconsin</option>
                <option value="Western District of Wisconsin">Western District of Wisconsin</option>
                <option value="District of Wyoming">District of Wyoming</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="District of the Virgin Islands">District of the Virgin Islands</option>
                </optgroup>
              </>
            )}
          </select>
        </div>
      )}
        {secondValue && firstValue === 'state' && (
        <div>
          <label className='label' htmlFor="secondSelector" style={{marginTop: '20px'}}>District:</label>
          <select className='select' id="secondSelector" value={thirdValue} onChange={handleThirdChange}>
            <option value="">Select a District</option>
            {secondValue === 'Alabama' && (
              <>
                <option value="Autauga County Circuit Court">Autauga County Circuit Court</option>
                <option value="Baldwin County Circuit Court">Baldwin County Circuit Court</option>
                <option value="Barbour County Circuit Court">Barbour County Circuit Court</option>
                <option value="Bibb County Circuit Court">Bibb County Circuit Court</option>
                <option value="Blount County Circuit Court">Blount County Circuit Court</option>
                <option value="Bullock County Circuit Court">Bullock County Circuit Court</option>
                <option value="Butler County Circuit Court">Butler County Circuit Court</option>
                <option value="Calhoun County Circuit Court">Calhoun County Circuit Court</option>
                <option value="Chambers County Circuit Court">Chambers County Circuit Court</option>
                <option value="Cherokee County Circuit Court">Cherokee County Circuit Court</option>
                <option value="Chilton County Circuit Court">Chilton County Circuit Court</option>
                <option value="Choctaw County Circuit Court">Choctaw County Circuit Court</option>
                <option value="Clarke County Circuit Court">Clarke County Circuit Court</option>
                <option value="Clay County Circuit Court">Clay County Circuit Court</option>
                <option value="Cleburne County Circuit Court">Cleburne County Circuit Court</option>
                <option value="Coffee County Circuit Court">Coffee County Circuit Court</option>
                <option value="Colbert County Circuit Court">Colbert County Circuit Court</option>
                <option value="Conecuh County Circuit Court">Conecuh County Circuit Court</option>
                <option value="Coosa County Circuit Court">Coosa County Circuit Court</option>
                <option value="Covington County Circuit Court">Covington County Circuit Court</option>
                <option value="Crenshaw County Circuit Court">Crenshaw County Circuit Court</option>
                <option value="Cullman County Circuit Court">Cullman County Circuit Court</option>
                <option value="Dale County Circuit Court">Dale County Circuit Court</option>
                <option value="Dallas County Circuit Court">Dallas County Circuit Court</option>
                <option value="DeKalb County Circuit Court">DeKalb County Circuit Court</option>
                <option value="Elmore County Circuit Court">Elmore County Circuit Court</option>
                <option value="Escambia County Circuit Court">Escambia County Circuit Court</option>
                <option value="Etowah County Circuit Court">Etowah County Circuit Court</option>
                <option value="Fayette County Circuit Court">Fayette County Circuit Court</option>
                <option value="Franklin County Circuit Court">Franklin County Circuit Court</option>
                <option value="Geneva County Circuit Court">Geneva County Circuit Court</option>
                <option value="Greene County Circuit Court">Greene County Circuit Court</option>
                <option value="Hale County Circuit Court">Hale County Circuit Court</option>
                <option value="Henry County Circuit Court">Henry County Circuit Court</option>
                <option value="Houston County Circuit Court">Houston County Circuit Court</option>
                <option value="Jackson County Circuit Court">Jackson County Circuit Court</option>
                <option value="Jefferson County Circuit Court">Jefferson County Circuit Court</option>
                <option value="Lamar County Circuit Court">Lamar County Circuit Court</option>
                <option value="Lauderdale County Circuit Court">Lauderdale County Circuit Court</option>
                <option value="Lawrence County Circuit Court">Lawrence County Circuit Court</option>
                <option value="Lee County Circuit Court">Lee County Circuit Court</option>
                <option value="Limestone County Circuit Court">Limestone County Circuit Court</option>
                <option value="Lowndes County Circuit Court">Lowndes County Circuit Court</option>
                <option value="Macon County Circuit Court">Macon County Circuit Court</option>
                <option value="Madison County Circuit Court">Madison County Circuit Court</option>
                <option value="Marengo County Circuit Court">Marengo County Circuit Court</option>
                <option value="Marion County Circuit Court">Marion County Circuit Court</option>
                <option value="Marshall County Circuit Court">Marshall County Circuit Court</option>
                <option value="Mobile County Circuit Court">Mobile County Circuit Court</option>
                <option value="Monroe County Circuit Court">Monroe County Circuit Court</option>
                <option value="Montgomery County Circuit Court">Montgomery County Circuit Court</option>
                <option value="Morgan County Circuit Court">Morgan County Circuit Court</option>
                <option value="Perry County Circuit Court">Perry County Circuit Court</option>
                <option value="Pickens County Circuit Court">Pickens County Circuit Court</option>
                <option value="Pike County Circuit Court">Pike County Circuit Court</option>
                <option value="Randolph County Circuit Court">Randolph County Circuit Court</option>
                <option value="Russell County Circuit Court">Russell County Circuit Court</option>
                <option value="St. Clair County Circuit Court">St. Clair County Circuit Court</option>
                <option value="Shelby County Circuit Court">Shelby County Circuit Court</option>
                <option value="Sumter County Circuit Court">Sumter County Circuit Court</option>
                <option value="Talladega County Circuit Court">Talladega County Circuit Court</option>
                <option value="Tallapoosa County Circuit Court">Tallapoosa County Circuit Court</option>
                <option value="Tuscaloosa County Circuit Court">Tuscaloosa County Circuit Court</option>
                <option value="Walker County Circuit Court">Walker County Circuit Court</option>
                <option value="Washington County Circuit Court">Washington County Circuit Court</option>
                <option value="Wilcox County Circuit Court">Wilcox County Circuit Court</option>
                <option value="Winston County Circuit Court">Winston County Circuit Court</option>
              </>
            )}
            {secondValue === 'Alaska' && (
              <>
                <option value="First Judicial District (Juneau)">First Judicial District (Juneau)</option>
                <option value="Second Judicial District (Nome)">Second Judicial District (Nome)</option>
                <option value="Third Judicial District (Anchorage)">Third Judicial District (Anchorage)</option>
                <option value="Fourth Judicial District (Fairbanks)">Fourth Judicial District (Fairbanks)</option>
              </>
            )}
            {secondValue === 'California' && (
              <>
                <option value="Alameda County Superior Court">Alameda County Superior Court</option>
                <option value="Alpine County Superior Court">Alpine County Superior Court</option>
                <option value="Amador County Superior Court">Amador County Superior Court</option>
                <option value="Butte County Superior Court">Butte County Superior Court</option>
                <option value="Calaveras County Superior Court">Calaveras County Superior Court</option>
                <option value="Colusa County Superior Court">Colusa County Superior Court</option>
                <option value="Contra Costa County Superior Court">Contra Costa County Superior Court</option>
                <option value="Del Norte County Superior Court">Del Norte County Superior Court</option>
                <option value="El Dorado County Superior Court">El Dorado County Superior Court</option>
                <option value="Fresno County Superior Court">Fresno County Superior Court</option>
                <option value="Glenn County Superior Court">Glenn County Superior Court</option>
                <option value="Humboldt County Superior Court">Humboldt County Superior Court</option>
                <option value="Imperial County Superior Court">Imperial County Superior Court</option>
                <option value="Inyo County Superior Court">Inyo County Superior Court</option>
                <option value="Kern County Superior Court">Kern County Superior Court</option>
                <option value="Kings County Superior Court">Kings County Superior Court</option>
                <option value="Lake County Superior Court">Lake County Superior Court</option>
                <option value="Lassen County Superior Court">Lassen County Superior Court</option>
                <option value="Los Angeles County Superior Court">Los Angeles County Superior Court</option>
                <option value="Madera County Superior Court">Madera County Superior Court</option>
                <option value="Marin County Superior Court">Marin County Superior Court</option>
                <option value="Mariposa County Superior Court">Mariposa County Superior Court</option>
                <option value="Mendocino County Superior Court">Mendocino County Superior Court</option>
                <option value="Merced County Superior Court">Merced County Superior Court</option>
                <option value="Modoc County Superior Court">Modoc County Superior Court</option>
                <option value="Mono County Superior Court">Mono County Superior Court</option>
                <option value="Monterey County Superior Court">Monterey County Superior Court</option>
                <option value="Napa County Superior Court">Napa County Superior Court</option>
                <option value="Nevada County Superior Court">Nevada County Superior Court</option>
                <option value="Orange County Superior Court">Orange County Superior Court</option>
                <option value="Placer County Superior Court">Placer County Superior Court</option>
                <option value="Plumas County Superior Court">Plumas County Superior Court</option>
                <option value="Riverside County Superior Court">Riverside County Superior Court</option>
                <option value="Sacramento County Superior Court">Sacramento County Superior Court</option>
                <option value="San Benito County Superior Court">San Benito County Superior Court</option>
                <option value="San Bernardino County Superior Court">San Bernardino County Superior Court</option>
                <option value="San Diego County Superior Court">San Diego County Superior Court</option>
                <option value="San Francisco County Superior Court">San Francisco County Superior Court</option>
                <option value="San Joaquin County Superior Court">San Joaquin County Superior Court</option>
                <option value="San Luis Obispo County Superior Court">San Luis Obispo County Superior Court</option>
                <option value="San Mateo County Superior Court">San Mateo County Superior Court</option>
                <option value="Santa Barbara County Superior Court">Santa Barbara County Superior Court</option>
                <option value="Santa Clara County Superior Court">Santa Clara County Superior Court</option>
                <option value="Santa Cruz County Superior Court">Santa Cruz County Superior Court</option>
                <option value="Shasta County Superior Court">Shasta County Superior Court</option>
                <option value="Sierra County Superior Court">Sierra County Superior Court</option>
                <option value="Siskiyou County Superior Court">Siskiyou County Superior Court</option>
                <option value="Solano County Superior Court">Solano County Superior Court</option>
                <option value="Sonoma County Superior Court">Sonoma County Superior Court</option>
                <option value="Stanislaus County Superior Court">Stanislaus County Superior Court</option>
                <option value="Sutter County Superior Court">Sutter County Superior Court</option>
                <option value="Tehama County Superior Court">Tehama County Superior Court</option>
                <option value="Trinity County Superior Court">Trinity County Superior Court</option>
                <option value="Tulare County Superior Court">Tulare County Superior Court</option>
                <option value="Tuolumne County Superior Court">Tuolumne County Superior Court</option>
                <option value="Ventura County Superior Court">Ventura County Superior Court</option>
                <option value="Yolo County Superior Court">Yolo County Superior Court</option>
                <option value="Yuba County Superior Court">Yuba County Superior Court</option>
              </>
            )}
            {secondValue === 'Arizona' && (
  <>
    <option value="Apache County Superior Court">Apache County Superior Court</option>
    <option value="Cochise County Superior Court">Cochise County Superior Court</option>
    <option value="Coconino County Superior Court">Coconino County Superior Court</option>
    <option value="Gila County Superior Court">Gila County Superior Court</option>
    <option value="Graham County Superior Court">Graham County Superior Court</option>
    <option value="Greenlee County Superior Court">Greenlee County Superior Court</option>
    <option value="La Paz County Superior Court">La Paz County Superior Court</option>
    <option value="Maricopa County Superior Court">Maricopa County Superior Court</option>
    <option value="Mohave County Superior Court">Mohave County Superior Court</option>
    <option value="Navajo County Superior Court">Navajo County Superior Court</option>
    <option value="Pima County Superior Court">Pima County Superior Court</option>
    <option value="Pinal County Superior Court">Pinal County Superior Court</option>
    <option value="Santa Cruz County Superior Court">Santa Cruz County Superior Court</option>
    <option value="Yavapai County Superior Court">Yavapai County Superior Court</option>
    <option value="Yuma County Superior Court">Yuma County Superior Court</option>
  </>
)}
            {secondValue === 'Arkansas' && (
  <>
    <option value="First Judicial Circuit Court">First Judicial Circuit Court</option>
    <option value="Second Judicial Circuit Court">Second Judicial Circuit Court</option>
    <option value="Third Judicial Circuit Court">Third Judicial Circuit Court</option>
    <option value="Fourth Judicial Circuit Court">Fourth Judicial Circuit Court</option>
    <option value="Fifth Judicial Circuit Court">Fifth Judicial Circuit Court</option>
    <option value="Sixth Judicial Circuit Court">Sixth Judicial Circuit Court</option>
    <option value="Seventh Judicial Circuit Court">Seventh Judicial Circuit Court</option>
    <option value="Eighth Judicial Circuit Court - North">Eighth Judicial Circuit Court - North</option>
    <option value="Eighth Judicial Circuit Court - South">Eighth Judicial Circuit Court - South</option>
    <option value="Ninth Judicial Circuit Court - East">Ninth Judicial Circuit Court - East</option>
    <option value="Ninth Judicial Circuit Court - West">Ninth Judicial Circuit Court - West</option>
    <option value="Tenth Judicial Circuit Court">Tenth Judicial Circuit Court</option>
    <option value="Eleventh Judicial Circuit Court - East">Eleventh Judicial Circuit Court - East</option>
    <option value="Eleventh Judicial Circuit Court - West">Eleventh Judicial Circuit Court - West</option>
    <option value="Twelfth Judicial Circuit Court">Twelfth Judicial Circuit Court</option>
    <option value="Thirteenth Judicial Circuit Court">Thirteenth Judicial Circuit Court</option>
    <option value="Fourteenth Judicial Circuit Court">Fourteenth Judicial Circuit Court</option>
    <option value="Fifteenth Judicial Circuit Court">Fifteenth Judicial Circuit Court</option>
    <option value="Sixteenth Judicial Circuit Court">Sixteenth Judicial Circuit Court</option>
    <option value="Seventeenth Judicial Circuit Court">Seventeenth Judicial Circuit Court</option>
    <option value="Eighteenth Judicial Circuit Court - East">Eighteenth Judicial Circuit Court - East</option>
    <option value="Eighteenth Judicial Circuit Court - West">Eighteenth Judicial Circuit Court - West</option>
    <option value="Nineteenth Judicial Circuit Court - East">Nineteenth Judicial Circuit Court - East</option>
    <option value="Nineteenth Judicial Circuit Court - West">Nineteenth Judicial Circuit Court - West</option>
    <option value="Twentieth Judicial Circuit Court">Twentieth Judicial Circuit Court</option>
    <option value="Twenty-First Judicial Circuit Court">Twenty-First Judicial Circuit Court</option>
    <option value="Twenty-Second Judicial Circuit Court">Twenty-Second Judicial Circuit Court</option>
  </>
)}
{secondValue === 'Colorado' && (
  <>
    <option value="Adams County Court">Adams County Court</option>
    <option value="Alamosa County Court">Alamosa County Court</option>
    <option value="Arapahoe County Court">Arapahoe County Court</option>
    <option value="Archuleta County Court">Archuleta County Court</option>
    <option value="Baca County Court">Baca County Court</option>
    <option value="Bent County Court">Bent County Court</option>
    <option value="Boulder County Court">Boulder County Court</option>
    <option value="Broomfield County Court">Broomfield County Court</option>
    <option value="Chaffee County Court">Chaffee County Court</option>
    <option value="Cheyenne County Court">Cheyenne County Court</option>
    <option value="Clear Creek County Court">Clear Creek County Court</option>
    <option value="Conejos County Court">Conejos County Court</option>
    <option value="Costilla County Court">Costilla County Court</option>
    <option value="Crowley County Court">Crowley County Court</option>
    <option value="Custer County Court">Custer County Court</option>
    <option value="Delta County Court">Delta County Court</option>
    <option value="Denver County Court">Denver County Court</option>
    <option value="Dolores County Court">Dolores County Court</option>
    <option value="Douglas County Court">Douglas County Court</option>
    <option value="Eagle County Court">Eagle County Court</option>
    <option value="Elbert County Court">Elbert County Court</option>
    <option value="El Paso County Court">El Paso County Court</option>
    <option value="Fremont County Court">Fremont County Court</option>
    <option value="Garfield County Court">Garfield County Court</option>
    <option value="Gilpin County Court">Gilpin County Court</option>
    <option value="Grand County Court">Grand County Court</option>
    <option value="Gunnison County Court">Gunnison County Court</option>
    <option value="Hinsdale County Court">Hinsdale County Court</option>
    <option value="Huerfano County Court">Huerfano County Court</option>
    <option value="Jackson County Court">Jackson County Court</option>
    <option value="Jefferson County Court">Jefferson County Court</option>
    <option value="Kiowa County Court">Kiowa County Court</option>
    <option value="Kit Carson County Court">Kit Carson County Court</option>
    <option value="Lake County Court">Lake County Court</option>
    <option value="La Plata County Court">La Plata County Court</option>
    <option value="Larimer County Court">Larimer County Court</option>
    <option value="Las Animas County Court">Las Animas County Court</option>
    <option value="Lincoln County Court">Lincoln County Court</option>
    <option value="Logan County Court">Logan County Court</option>
    <option value="Mesa County Court">Mesa County Court</option>
    <option value="Mineral County Court">Mineral County Court</option>
    <option value="Moffat County Court">Moffat County Court</option>
    <option value="Montezuma County Court">Montezuma County Court</option>
    <option value="Montrose County Court">Montrose County Court</option>
    <option value="Morgan County Court">Morgan County Court</option>
    <option value="Otero County Court">Otero County Court</option>
    <option value="Ouray County Court">Ouray County Court</option>
    <option value="Park County Court">Park County Court</option>
    <option value="Phillips County Court">Phillips County Court</option>
    <option value="Pitkin County Court">Pitkin County Court</option>
    <option value="Prowers County Court">Prowers County Court</option>
    <option value="Pueblo County Court">Pueblo County Court</option>
    <option value="Rio Blanco County Court">Rio Blanco County Court</option>
    <option value="Rio Grande County Court">Rio Grande County Court</option>
    <option value="Routt County Court">Routt County Court</option>
    <option value="Saguache County Court">Saguache County Court</option>
    <option value="San Juan County Court">San Juan County Court</option>
    <option value="San Miguel County Court">San Miguel County Court</option>
    <option value="Sedgwick County Court">Sedgwick County Court</option>
    <option value="Summit County Court">Summit County Court</option>
    <option value="Teller County Court">Teller County Court</option>
    <option value="Washington County Court">Washington County Court</option>
    <option value="Weld County Court">Weld County Court</option>
    <option value="Yuma County Court">Yuma County Court</option>

    <option value="1st Judicial District Court">1st Judicial District Court (Jefferson, Gilpin)</option>
    <option value="2nd Judicial District Court">2nd Judicial District Court (Denver)</option>
    <option value="3rd Judicial District Court">3rd Judicial District Court (Huerfano, Las Animas)</option>
    <option value="4th Judicial District Court">4th Judicial District Court (El Paso, Teller)</option>
    <option value="5th Judicial District Court">5th Judicial District Court (Clear Creek, Eagle, Lake, Summit)</option>
    <option value="6th Judicial District Court">6th Judicial District Court (Archuleta, La Plata, San Juan)</option>
    <option value="7th Judicial District Court">7th Judicial District Court (Delta, Gunnison, Hinsdale, Montrose, Ouray, San Miguel)</option>
    <option value="8th Judicial District Court">8th Judicial District Court (Jackson, Larimer)</option>
    <option value="9th Judicial District Court">9th Judicial District Court (Garfield, Pitkin, Rio Blanco)</option>
    <option value="10th Judicial District Court">10th Judicial District Court (Pueblo)</option>
    <option value="11th Judicial District Court">11th Judicial District Court (Chaffee, Custer, Fremont, Park)</option>
    <option value="12th Judicial District Court">12th Judicial District Court (Alamosa, Conejos, Costilla, Mineral, Rio Grande, Saguache)</option>
    <option value="13th Judicial District Court">13th Judicial District Court (Kit Carson, Logan, Morgan, Phillips, Sedgwick, Washington, Yuma)</option>
    <option value="14th Judicial District Court">14th Judicial District Court (Grand, Moffat, Routt)</option>
    <option value="15th Judicial District Court">15th Judicial District Court (Baca, Cheyenne, Kiowa, Prowers)</option>
    <option value="16th Judicial District Court">16th Judicial District Court (Bent, Crowley, Otero)</option>
    <option value="17th Judicial District Court">17th Judicial District Court (Adams, Broomfield)</option>
    <option value="18th Judicial District Court">18th Judicial District Court (Arapahoe, Douglas, Elbert, Lincoln)</option>
    <option value="19th Judicial District Court">19th Judicial District Court (Weld)</option>
    <option value="20th Judicial District Court">20th Judicial District Court (Boulder)</option>
    <option value="21st Judicial District Court">21st Judicial District Court (Mesa)</option>
    <option value="22nd Judicial District Court">22nd Judicial District Court (Dolores, Montezuma)</option>
  </>
)}
{secondValue === 'Connecticut' && (
  <>
    <option value="Ansonia-Milford Judicial District Court">Ansonia-Milford Judicial District Court</option>
    <option value="Danbury Judicial District Court">Danbury Judicial District Court</option>
    <option value="Fairfield Judicial District Court">Fairfield Judicial District Court</option>
    <option value="Hartford Judicial District Court">Hartford Judicial District Court</option>
    <option value="Litchfield Judicial District Court">Litchfield Judicial District Court</option>
    <option value="Middlesex Judicial District Court">Middlesex Judicial District Court</option>
    <option value="New Britain Judicial District Court">New Britain Judicial District Court</option>
    <option value="New Haven Judicial District Court">New Haven Judicial District Court</option>
    <option value="New London Judicial District Court">New London Judicial District Court</option>
    <option value="Stamford-Norwalk Judicial District Court">Stamford-Norwalk Judicial District Court</option>
    <option value="Tolland Judicial District Court">Tolland Judicial District Court</option>
    <option value="Waterbury Judicial District Court">Waterbury Judicial District Court</option>
    <option value="Windham Judicial District Court">Windham Judicial District Court</option>
  </>
)}
{secondValue === 'Delaware' && (
  <>
    <option value="New Castle County Superior Court">New Castle County Superior Court</option>
    <option value="Kent County Superior Court">Kent County Superior Court</option>
    <option value="Sussex County Superior Court">Sussex County Superior Court</option>
  </>
)}
{secondValue === 'Florida' && (
  <>
    <option value="Escambia County Court">Escambia County Court</option>
    <option value="Okaloosa County Court">Okaloosa County Court</option>
    <option value="Santa Rosa County Court">Santa Rosa County Court</option>
    <option value="Walton County Court">Walton County Court</option>
    <option value="Franklin County Court">Franklin County Court</option>
    <option value="Gadsden County Court">Gadsden County Court</option>
    <option value="Jefferson County Court">Jefferson County Court</option>
    <option value="Leon County Court">Leon County Court</option>
    <option value="Liberty County Court">Liberty County Court</option>
    <option value="Wakulla County Court">Wakulla County Court</option>
    <option value="Columbia County Court">Columbia County Court</option>
    <option value="Dixie County Court">Dixie County Court</option>
    <option value="Hamilton County Court">Hamilton County Court</option>
    <option value="Lafayette County Court">Lafayette County Court</option>
    <option value="Madison County Court">Madison County Court</option>
    <option value="Suwannee County Court">Suwannee County Court</option>
    <option value="Taylor County Court">Taylor County Court</option>
    <option value="Clay County Court">Clay County Court</option>
    <option value="Duval County Court">Duval County Court</option>
    <option value="Nassau County Court">Nassau County Court</option>
    <option value="Citrus County Court">Citrus County Court</option>
    <option value="Hernando County Court">Hernando County Court</option>
    <option value="Lake County Court">Lake County Court</option>
    <option value="Marion County Court">Marion County Court</option>
    <option value="Sumter County Court">Sumter County Court</option>
    <option value="Pasco County Court">Pasco County Court</option>
    <option value="Pinellas County Court">Pinellas County Court</option>
    <option value="Flagler County Court">Flagler County Court</option>
    <option value="Putnam County Court">Putnam County Court</option>
    <option value="St. Johns County Court">St. Johns County Court</option>
    <option value="Volusia County Court">Volusia County Court</option>
    <option value="Alachua County Court">Alachua County Court</option>
    <option value="Baker County Court">Baker County Court</option>
    <option value="Bradford County Court">Bradford County Court</option>
    <option value="Gilchrist County Court">Gilchrist County Court</option>
    <option value="Levy County Court">Levy County Court</option>
    <option value="Union County Court">Union County Court</option>
    <option value="Orange County Court">Orange County Court</option>
    <option value="Osceola County Court">Osceola County Court</option>
    <option value="Hardee County Court">Hardee County Court</option>
    <option value="Highlands County Court">Highlands County Court</option>
    <option value="Polk County Court">Polk County Court</option>
    <option value="Miami-Dade County Court">Miami-Dade County Court</option>
    <option value="DeSoto County Court">DeSoto County Court</option>
    <option value="Manatee County Court">Manatee County Court</option>
    <option value="Sarasota County Court">Sarasota County Court</option>
    <option value="Hillsborough County Court">Hillsborough County Court</option>
    <option value="Bay County Court">Bay County Court</option>
    <option value="Calhoun County Court">Calhoun County Court</option>
    <option value="Gulf County Court">Gulf County Court</option>
    <option value="Holmes County Court">Holmes County Court</option>
    <option value="Jackson County Court">Jackson County Court</option>
    <option value="Washington County Court">Washington County Court</option>
    <option value="Palm Beach County Court">Palm Beach County Court</option>
    <option value="Monroe County Court">Monroe County Court</option>
    <option value="Broward County Court">Broward County Court</option>
    <option value="Brevard County Court">Brevard County Court</option>
    <option value="Seminole County Court">Seminole County Court</option>
    <option value="Indian River County Court">Indian River County Court</option>
    <option value="Martin County Court">Martin County Court</option>
    <option value="Okeechobee County Court">Okeechobee County Court</option>
    <option value="St. Lucie County Court">St. Lucie County Court</option>
    <option value="Charlotte County Court">Charlotte County Court</option>
    <option value="Collier County Court">Collier County Court</option>
    <option value="Glades County Court">Glades County Court</option>
    <option value="Hendry County Court">Hendry County Court</option>
    <option value="Lee County Court">Lee County Court</option>
    <option value="First Judicial Circuit Court">First Judicial Circuit Court</option>
    <option value="Second Judicial Circuit Court">Second Judicial Circuit Court</option>
    <option value="Third Judicial Circuit Court">Third Judicial Circuit Court</option>
    <option value="Fourth Judicial Circuit Court">Fourth Judicial Circuit Court</option>
    <option value="Fifth Judicial Circuit Court">Fifth Judicial Circuit Court</option>
    <option value="Sixth Judicial Circuit Court">Sixth Judicial Circuit Court</option>
    <option value="Seventh Judicial Circuit Court">Seventh Judicial Circuit Court</option>
    <option value="Eighth Judicial Circuit Court">Eighth Judicial Circuit Court</option>
    <option value="Ninth Judicial Circuit Court">Ninth Judicial Circuit Court</option>
    <option value="Tenth Judicial Circuit Court">Tenth Judicial Circuit Court</option>
    <option value="Eleventh Judicial Circuit Court">Eleventh Judicial Circuit Court</option>
    <option value="Twelfth Judicial Circuit Court">Twelfth Judicial Circuit Court</option>
    <option value="Thirteenth Judicial Circuit Court">Thirteenth Judicial Circuit Court</option>
    <option value="Fourteenth Judicial Circuit Court">Fourteenth Judicial Circuit Court</option>
    <option value="Fifteenth Judicial Circuit Court">Fifteenth Judicial Circuit Court</option>
    <option value="Sixteenth Judicial Circuit Court">Sixteenth Judicial Circuit Court</option>
    <option value="Seventeenth Judicial Circuit Court">Seventeenth Judicial Circuit Court</option>
    <option value="Eighteenth Judicial Circuit Court">Eighteenth Judicial Circuit Court</option>
    <option value="Nineteenth Judicial Circuit Court">Nineteenth Judicial Circuit Court</option>
    <option value="Twentieth Judicial Circuit Court">Twentieth Judicial Circuit Court</option>
  </>
)}
{secondValue === 'Georgia' && (
  <>
    <option value="Alcovy Judicial Circuit">Alcovy Judicial Circuit (Newton, Walton)</option>
    <option value="Appalachian Judicial Circuit">Appalachian Judicial Circuit (Fannin, Gilmer, Pickens)</option>
    <option value="Atlanta Judicial Circuit">Atlanta Judicial Circuit (Fulton)</option>
    <option value="Atlantic Judicial Circuit">Atlantic Judicial Circuit (Bryan, Evans, Liberty, Long, McIntosh, Tattnall)</option>
    <option value="Augusta Judicial Circuit">Augusta Judicial Circuit (Burke, Columbia, Richmond)</option>
    <option value="Bell-Forsyth Judicial Circuit">Bell-Forsyth Judicial Circuit (Forsyth)</option>
    <option value="Blue Ridge Judicial Circuit">Blue Ridge Judicial Circuit (Cherokee)</option>
    <option value="Brunswick Judicial Circuit">Brunswick Judicial Circuit (Appling, Camden, Glynn, Jeff Davis, Wayne)</option>
    <option value="Chattahoochee Judicial Circuit">Chattahoochee Judicial Circuit (Chattahoochee, Harris, Marion, Muscogee, Talbot, Taylor)</option>
    <option value="Cherokee Judicial Circuit">Cherokee Judicial Circuit (Bartow, Gordon)</option>
    <option value="Clayton Judicial Circuit">Clayton Judicial Circuit (Clayton)</option>
    <option value="Cobb Judicial Circuit">Cobb Judicial Circuit (Cobb)</option>
    <option value="Conasauga Judicial Circuit">Conasauga Judicial Circuit (Murray, Whitfield)</option>
    <option value="Coweta Judicial Circuit">Coweta Judicial Circuit (Carroll, Coweta, Heard, Meriwether, Troup)</option>
    <option value="Dougherty Judicial Circuit">Dougherty Judicial Circuit (Dougherty)</option>
    <option value="Douglas Judicial Circuit">Douglas Judicial Circuit (Douglas)</option>
    <option value="Dublin Judicial Circuit">Dublin Judicial Circuit (Johnson, Laurens, Treutlen, Twiggs)</option>
    <option value="Eastern Judicial Circuit">Eastern Judicial Circuit (Chatham)</option>
    <option value="Enotah Judicial Circuit">Enotah Judicial Circuit (Lumpkin, Towns, Union, White)</option>
    <option value="Flint Judicial Circuit">Flint Judicial Circuit (Henry)</option>
    <option value="Griffin Judicial Circuit">Griffin Judicial Circuit (Fayette, Pike, Spalding, Upson)</option>
    <option value="Gwinnett Judicial Circuit">Gwinnett Judicial Circuit (Gwinnett)</option>
    <option value="Houston Judicial Circuit">Houston Judicial Circuit (Houston)</option>
    <option value="Lookout Mountain Judicial Circuit">Lookout Mountain Judicial Circuit (Catoosa, Chattooga, Dade, Walker)</option>
    <option value="Macon Judicial Circuit">Macon Judicial Circuit (Bibb, Crawford, Peach)</option>
    <option value="Middle Judicial Circuit">Middle Judicial Circuit (Bulloch, Candler, Emanuel, Jefferson, Toombs, Washington)</option>
    <option value="Mountain Judicial Circuit">Mountain Judicial Circuit (Habersham, Rabun, Stephens)</option>
    <option value="Northeastern Judicial Circuit">Northeastern Judicial Circuit (Hall, Dawson)</option>
    <option value="Northern Judicial Circuit">Northern Judicial Circuit (Elbert, Franklin, Hart, Madison, Oglethorpe)</option>
    <option value="Ocmulgee Judicial Circuit">Ocmulgee Judicial Circuit (Baldwin, Greene, Hancock, Jasper, Jones, Morgan, Putnam, Wilkinson)</option>
    <option value="Oconee Judicial Circuit">Oconee Judicial Circuit (Bleckley, Dodge, Montgomery, Pulaski, Telfair, Wheeler)</option>
    <option value="Pacific Judicial Circuit">Pacific Judicial Circuit (Wayne)</option>
    <option value="Pataula Judicial Circuit">Pataula Judicial Circuit (Clay, Early, Miller, Quitman, Randolph, Seminole, Terrell)</option>
    <option value="Piedmont Judicial Circuit">Piedmont Judicial Circuit (Barrow, Jackson, Banks)</option>
    <option value="Rockdale Judicial Circuit">Rockdale Judicial Circuit (Rockdale)</option>
    <option value="Rome Judicial Circuit">Rome Judicial Circuit (Floyd)</option>
    <option value="South Georgia Judicial Circuit">South Georgia Judicial Circuit (Baker, Calhoun, Decatur, Grady, Mitchell)</option>
    <option value="Southern Judicial Circuit">Southern Judicial Circuit (Brooks, Colquitt, Echols, Lowndes, Thomas)</option>
    <option value="Southwestern Judicial Circuit">Southwestern Judicial Circuit (Lee, Macon, Schley, Stewart, Sumter, Webster)</option>
    <option value="Stone Mountain Judicial Circuit">Stone Mountain Judicial Circuit (DeKalb)</option>
    <option value="Tallapoosa Judicial Circuit">Tallapoosa Judicial Circuit (Haralson, Polk)</option>
    <option value="Tifton Judicial Circuit">Tifton Judicial Circuit (Irwin, Tift, Turner, Worth)</option>
    <option value="Toombs Judicial Circuit">Toombs Judicial Circuit (Glascock, Lincoln, McDuffie, Taliaferro, Warren, Wilkes)</option>
    <option value="Towaliga Judicial Circuit">Towaliga Judicial Circuit (Butts, Lamar, Monroe)</option>
    <option value="Waycross Judicial Circuit">Waycross Judicial Circuit (Bacon, Brantley, Charlton, Coffee, Pierce, Ware)</option>
    <option value="Western Judicial Circuit">Western Judicial Circuit (Clarke, Oconee)</option>
  </>
)}
{secondValue === 'Hawaii' && (
  <>
    <option value="First Circuit Court">First Circuit Court (Oahu)</option>
    <option value="Second Circuit Court">Second Circuit Court (Maui, Molokai, Lanai)</option>
    <option value="Third Circuit Court">Third Circuit Court (Hawaii Island)</option>
    <option value="Fifth Circuit Court">Fifth Circuit Court (Kauai)</option>
  </>
)}
{secondValue === 'Idaho' && (
  <>
    {/* First Judicial District */}
    <option value="First Judicial District - Benewah County District Court">Benewah County District Court</option>
    <option value="First Judicial District - Bonner County District Court">Bonner County District Court</option>
    <option value="First Judicial District - Boundary County District Court">Boundary County District Court</option>
    <option value="First Judicial District - Kootenai County District Court">Kootenai County District Court</option>
    <option value="First Judicial District - Shoshone County District Court">Shoshone County District Court</option>
    {/* Second Judicial District */}
    <option value="Second Judicial District - Clearwater County District Court">Clearwater County District Court</option>
    <option value="Second Judicial District - Idaho County District Court">Idaho County District Court</option>
    <option value="Second Judicial District - Latah County District Court">Latah County District Court</option>
    <option value="Second Judicial District - Lewis County District Court">Lewis County District Court</option>
    <option value="Second Judicial District - Nez Perce County District Court">Nez Perce County District Court</option>
    {/* Third Judicial District */}
    <option value="Third Judicial District - Adams County District Court">Adams County District Court</option>
    <option value="Third Judicial District - Canyon County District Court">Canyon County District Court</option>
    <option value="Third Judicial District - Gem County District Court">Gem County District Court</option>
    <option value="Third Judicial District - Owyhee County District Court">Owyhee County District Court</option>
    <option value="Third Judicial District - Payette County District Court">Payette County District Court</option>
    <option value="Third Judicial District - Washington County District Court">Washington County District Court</option>
    {/* Fourth Judicial District */}
    <option value="Fourth Judicial District - Ada County District Court">Ada County District Court</option>
    <option value="Fourth Judicial District - Boise County District Court">Boise County District Court</option>
    <option value="Fourth Judicial District - Elmore County District Court">Elmore County District Court</option>
    <option value="Fourth Judicial District - Valley County District Court">Valley County District Court</option>
    {/* Fifth Judicial District */}
    <option value="Fifth Judicial District - Blaine County District Court">Blaine County District Court</option>
    <option value="Fifth Judicial District - Camas County District Court">Camas County District Court</option>
    <option value="Fifth Judicial District - Cassia County District Court">Cassia County District Court</option>
    <option value="Fifth Judicial District - Gooding County District Court">Gooding County District Court</option>
    <option value="Fifth Judicial District - Jerome County District Court">Jerome County District Court</option>
    <option value="Fifth Judicial District - Lincoln County District Court">Lincoln County District Court</option>
    <option value="Fifth Judicial District - Minidoka County District Court">Minidoka County District Court</option>
    <option value="Fifth Judicial District - Twin Falls County District Court">Twin Falls County District Court</option>
    {/* Sixth Judicial District */}
    <option value="Sixth Judicial District - Bannock County District Court">Bannock County District Court</option>
    <option value="Sixth Judicial District - Bear Lake County District Court">Bear Lake County District Court</option>
    <option value="Sixth Judicial District - Caribou County District Court">Caribou County District Court</option>
    <option value="Sixth Judicial District - Franklin County District Court">Franklin County District Court</option>
    <option value="Sixth Judicial District - Oneida County District Court">Oneida County District Court</option>
    <option value="Sixth Judicial District - Power County District Court">Power County District Court</option>
    {/* Seventh Judicial District */}
    <option value="Seventh Judicial District - Bingham County District Court">Bingham County District Court</option>
    <option value="Seventh Judicial District - Bonneville County District Court">Bonneville County District Court</option>
    <option value="Seventh Judicial District - Butte County District Court">Butte County District Court</option>
    <option value="Seventh Judicial District - Clark County District Court">Clark County District Court</option>
    <option value="Seventh Judicial District - Custer County District Court">Custer County District Court</option>
    <option value="Seventh Judicial District - Fremont County District Court">Fremont County District Court</option>
    <option value="Seventh Judicial District - Jefferson County District Court">Jefferson County District Court</option>
    <option value="Seventh Judicial District - Lemhi County District Court">Lemhi County District Court</option>
    <option value="Seventh Judicial District - Madison County District Court">Madison County District Court</option>
    <option value="Seventh Judicial District - Teton County District Court">Teton County District Court</option>
  </>
)}
{secondValue === 'Illinois' && (
  <>
  {/* First Judicial Circuit */}
  <option value="First Judicial Circuit - Alexander County Circuit Court">Alexander County Circuit Court</option>
    <option value="First Judicial Circuit - Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="First Judicial Circuit - Johnson County Circuit Court">Johnson County Circuit Court</option>
    <option value="First Judicial Circuit - Massac County Circuit Court">Massac County Circuit Court</option>
    <option value="First Judicial Circuit - Pope County Circuit Court">Pope County Circuit Court</option>
    <option value="First Judicial Circuit - Pulaski County Circuit Court">Pulaski County Circuit Court</option>
    <option value="First Judicial Circuit - Saline County Circuit Court">Saline County Circuit Court</option>
    <option value="First Judicial Circuit - Union County Circuit Court">Union County Circuit Court</option>
    <option value="First Judicial Circuit - Williamson County Circuit Court">Williamson County Circuit Court</option>
    {/* Second Judicial Circuit */}
    <option value="Second Judicial Circuit - Crawford County Circuit Court">Crawford County Circuit Court</option>
    <option value="Second Judicial Circuit - Edwards County Circuit Court">Edwards County Circuit Court</option>
    <option value="Second Judicial Circuit - Franklin County Circuit Court">Franklin County Circuit Court</option>
    <option value="Second Judicial Circuit - Gallatin County Circuit Court">Gallatin County Circuit Court</option>
    <option value="Second Judicial Circuit - Hamilton County Circuit Court">Hamilton County Circuit Court</option>
    <option value="Second Judicial Circuit - Hardin County Circuit Court">Hardin County Circuit Court</option>
    <option value="Second Judicial Circuit - Jefferson County Circuit Court">Jefferson County Circuit Court</option>
    <option value="Second Judicial Circuit - Lawrence County Circuit Court">Lawrence County Circuit Court</option>
    <option value="Second Judicial Circuit - Richland County Circuit Court">Richland County Circuit Court</option>
    <option value="Second Judicial Circuit - Wabash County Circuit Court">Wabash County Circuit Court</option>
    <option value="Second Judicial Circuit - Wayne County Circuit Court">Wayne County Circuit Court</option>
    <option value="Second Judicial Circuit - White County Circuit Court">White County Circuit Court</option>
    {/* Third Judicial Circuit */}
    <option value="Third Judicial Circuit - Bond County Circuit Court">Bond County Circuit Court</option>
    <option value="Third Judicial Circuit - Madison County Circuit Court">Madison County Circuit Court</option>
    {/* Fourth Judicial Circuit */}
    <option value="Fourth Judicial Circuit - Christian County Circuit Court">Christian County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Clinton County Circuit Court">Clinton County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Effingham County Circuit Court">Effingham County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Fayette County Circuit Court">Fayette County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Jasper County Circuit Court">Jasper County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Marion County Circuit Court">Marion County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Shelby County Circuit Court">Shelby County Circuit Court</option>
    {/* Fifth Judicial Circuit */}
    <option value="Fifth Judicial Circuit - Clark County Circuit Court">Clark County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Coles County Circuit Court">Coles County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Cumberland County Circuit Court">Cumberland County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Edgar County Circuit Court">Edgar County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Vermilion County Circuit Court">Vermilion County Circuit Court</option>
    {/* Sixth Judicial Circuit */}
    <option value="Sixth Judicial Circuit - Champaign County Circuit Court">Champaign County Circuit Court</option>
    <option value="Sixth Judicial Circuit - DeWitt County Circuit Court">DeWitt County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Douglas County Circuit Court">Douglas County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Macon County Circuit Court">Macon County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Moultrie County Circuit Court">Moultrie County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Piatt County Circuit Court">Piatt County Circuit Court</option>
    {/* Seventh Judicial Circuit */}
    <option value="Seventh Judicial Circuit - Greene County Circuit Court">Greene County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Jersey County Circuit Court">Jersey County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Macoupin County Circuit Court">Macoupin County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Morgan County Circuit Court">Morgan County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Sangamon County Circuit Court">Sangamon County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Scott County Circuit Court">Scott County Circuit Court</option>
    {/* Eighth Judicial Circuit */}
    <option value="Eighth Judicial Circuit - Adams County Circuit Court">Adams County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Brown County Circuit Court">Brown County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Calhoun County Circuit Court">Calhoun County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Cass County Circuit Court">Cass County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Mason County Circuit Court">Mason County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Menard County Circuit Court">Menard County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Pike County Circuit Court">Pike County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Schuyler County Circuit Court">Schuyler County Circuit Court</option>
    {/* Ninth Judicial Circuit */}
    <option value="Ninth Judicial Circuit - Fulton County Circuit Court">Fulton County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Hancock County Circuit Court">Hancock County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Henderson County Circuit Court">Henderson County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Knox County Circuit Court">Knox County Circuit Court</option>
    <option value="Ninth Judicial Circuit - McDonough County Circuit Court">McDonough County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Warren County Circuit Court">Warren County Circuit Court</option>
    {/* Tenth Judicial Circuit */}
    <option value="Tenth Judicial Circuit - Marshall County Circuit Court">Marshall County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Peoria County Circuit Court">Peoria County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Putnam County Circuit Court">Putnam County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Stark County Circuit Court">Stark County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Tazewell County Circuit Court">Tazewell County Circuit Court</option>
    {/* Eleventh Judicial Circuit */}
    <option value="Eleventh Judicial Circuit - Ford County Circuit Court">Ford County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Livingston County Circuit Court">Livingston County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Logan County Circuit Court">Logan County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - McLean County Circuit Court">McLean County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Woodford County Circuit Court">Woodford County Circuit Court</option>
    {/* Twelfth Judicial Circuit */}
    <option value="Twelfth Judicial Circuit - Will County Circuit Court">Will County Circuit Court</option>
    {/* Thirteenth Judicial Circuit */}
    <option value="Thirteenth Judicial Circuit - Bureau County Circuit Court">Bureau County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - Grundy County Circuit Court">Grundy County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - LaSalle County Circuit Court">LaSalle County Circuit Court</option>
    {/* Fourteenth Judicial Circuit */}
    <option value="Fourteenth Judicial Circuit - Henry County Circuit Court">Henry County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Mercer County Circuit Court">Mercer County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Rock Island County Circuit Court">Rock Island County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Whiteside County Circuit Court">Whiteside County Circuit Court</option>
    {/* Fifteenth Judicial Circuit */}
    <option value="Fifteenth Judicial Circuit - Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Jo Daviess County Circuit Court">Jo Daviess County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Lee County Circuit Court">Lee County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Ogle County Circuit Court">Ogle County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Stephenson County Circuit Court">Stephenson County Circuit Court</option>
    {/* Sixteenth Judicial Circuit */}
    <option value="Sixteenth Judicial Circuit - Kane County Circuit Court">Kane County Circuit Court</option>
    {/* Seventeenth Judicial Circuit */}
    <option value="Seventeenth Judicial Circuit - Boone County Circuit Court">Boone County Circuit Court</option>
    <option value="Seventeenth Judicial Circuit - Winnebago County Circuit Court">Winnebago County Circuit Court</option>
    {/* Eighteenth Judicial Circuit */}
    <option value="Eighteenth Judicial Circuit - DuPage County Circuit Court">DuPage County Circuit Court</option>
    {/* Nineteenth Judicial Circuit */}
    <option value="Nineteenth Judicial Circuit - Lake County Circuit Court">Lake County Circuit Court</option>
    {/* Twentieth Judicial Circuit */}
    <option value="Twentieth Judicial Circuit - Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - Perry County Circuit Court">Perry County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - Randolph County Circuit Court">Randolph County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - St. Clair County Circuit Court">St. Clair County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - Washington County Circuit Court">Washington County Circuit Court</option>
    {/* Twenty-First Judicial Circuit */}
    <option value="Twenty-First Judicial Circuit - Iroquois County Circuit Court">Iroquois County Circuit Court</option>
    <option value="Twenty-First Judicial Circuit - Kankakee County Circuit Court">Kankakee County Circuit Court</option>
    {/* Twenty-Second Judicial Circuit */}
    <option value="Twenty-Second Judicial Circuit - McHenry County Circuit Court">McHenry County Circuit Court</option>
    {/* Twenty-Third Judicial Circuit */}
    <option value="Twenty-Third Judicial Circuit - DeKalb County Circuit Court">DeKalb County Circuit Court</option>
    <option value="Twenty-Third Judicial Circuit - Kendall County Circuit Court">Kendall County Circuit Court</option>
    {/* Twenty-Fourth Judicial Circuit */}
    <option value="Twenty-Fourth Judicial Circuit - Rock Island County Circuit Court">Rock Island County Circuit Court</option>
  </>
)}
{secondValue === 'Indiana' && (
  <>
    <option value="Adams County Circuit Court">Adams County Circuit Court</option>
    <option value="Allen County Circuit Court">Allen County Circuit Court</option>
    <option value="Bartholomew County Circuit Court">Bartholomew County Circuit Court</option>
    <option value="Benton County Circuit Court">Benton County Circuit Court</option>
    <option value="Blackford County Circuit Court">Blackford County Circuit Court</option>
    <option value="Boone County Circuit Court">Boone County Circuit Court</option>
    <option value="Brown County Circuit Court">Brown County Circuit Court</option>
    <option value="Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Cass County Circuit Court">Cass County Circuit Court</option>
    <option value="Clark County Circuit Court">Clark County Circuit Court</option>
    <option value="Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Clinton County Circuit Court">Clinton County Circuit Court</option>
    <option value="Crawford County Circuit Court">Crawford County Circuit Court</option>
    <option value="Daviess County Circuit Court">Daviess County Circuit Court</option>
    <option value="Dearborn County Circuit Court">Dearborn County Circuit Court</option>
    <option value="Decatur County Circuit Court">Decatur County Circuit Court</option>
    <option value="DeKalb County Circuit Court">DeKalb County Circuit Court</option>
    <option value="Delaware County Circuit Court">Delaware County Circuit Court</option>
    <option value="Dubois County Circuit Court">Dubois County Circuit Court</option>
    <option value="Elkhart County Circuit Court">Elkhart County Circuit Court</option>
    <option value="Fayette County Circuit Court">Fayette County Circuit Court</option>
    <option value="Floyd County Circuit Court">Floyd County Circuit Court</option>
    <option value="Fountain County Circuit Court">Fountain County Circuit Court</option>
    <option value="Franklin County Circuit Court">Franklin County Circuit Court</option>
    <option value="Fulton County Circuit Court">Fulton County Circuit Court</option>
    <option value="Gibson County Circuit Court">Gibson County Circuit Court</option>
    <option value="Grant County Circuit Court">Grant County Circuit Court</option>
    <option value="Greene County Circuit Court">Greene County Circuit Court</option>
    <option value="Hamilton County Circuit Court">Hamilton County Circuit Court</option>
    <option value="Hancock County Circuit Court">Hancock County Circuit Court</option>
    <option value="Harrison County Circuit Court">Harrison County Circuit Court</option>
    <option value="Hendricks County Circuit Court">Hendricks County Circuit Court</option>
    <option value="Henry County Circuit Court">Henry County Circuit Court</option>
    <option value="Howard County Circuit Court">Howard County Circuit Court</option>
    <option value="Huntington County Circuit Court">Huntington County Circuit Court</option>
    <option value="Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Jasper County Circuit Court">Jasper County Circuit Court</option>
    <option value="Jay County Circuit Court">Jay County Circuit Court</option>
    <option value="Jefferson County Circuit Court">Jefferson County Circuit Court</option>
    <option value="Jennings County Circuit Court">Jennings County Circuit Court</option>
    <option value="Johnson County Circuit Court">Johnson County Circuit Court</option>
    <option value="Knox County Circuit Court">Knox County Circuit Court</option>
    <option value="Kosciusko County Circuit Court">Kosciusko County Circuit Court</option>
    <option value="LaGrange County Circuit Court">LaGrange County Circuit Court</option>
    <option value="Lake County Circuit Court">Lake County Circuit Court</option>
    <option value="LaPorte County Circuit Court">LaPorte County Circuit Court</option>
    <option value="Lawrence County Circuit Court">Lawrence County Circuit Court</option>
    <option value="Madison County Circuit Court">Madison County Circuit Court</option>
    <option value="Marion County Circuit Court">Marion County Circuit Court</option>
    <option value="Marshall County Circuit Court">Marshall County Circuit Court</option>
    <option value="Martin County Circuit Court">Martin County Circuit Court</option>
    <option value="Miami County Circuit Court">Miami County Circuit Court</option>
    <option value="Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Morgan County Circuit Court">Morgan County Circuit Court</option>
    <option value="Newton County Circuit Court">Newton County Circuit Court</option>
    <option value="Noble County Circuit Court">Noble County Circuit Court</option>
    <option value="Ohio County Circuit Court">Ohio County Circuit Court</option>
    <option value="Orange County Circuit Court">Orange County Circuit Court</option>
    <option value="Owen County Circuit Court">Owen County Circuit Court</option>
    <option value="Parke County Circuit Court">Parke County Circuit Court</option>
    <option value="Perry County Circuit Court">Perry County Circuit Court</option>
    <option value="Pike County Circuit Court">Pike County Circuit Court</option>
    <option value="Porter County Circuit Court">Porter County Circuit Court</option>
    <option value="Posey County Circuit Court">Posey County Circuit Court</option>
    <option value="Pulaski County Circuit Court">Pulaski County Circuit Court</option>
    <option value="Putnam County Circuit Court">Putnam County Circuit Court</option>
    <option value="Randolph County Circuit Court">Randolph County Circuit Court</option>
    <option value="Ripley County Circuit Court">Ripley County Circuit Court</option>
    <option value="Rush County Circuit Court">Rush County Circuit Court</option>
    <option value="St. Joseph County Circuit Court">St. Joseph County Circuit Court</option>
    <option value="Scott County Circuit Court">Scott County Circuit Court</option>
    <option value="Shelby County Circuit Court">Shelby County Circuit Court</option>
    <option value="Spencer County Circuit Court">Spencer County Circuit Court</option>
    <option value="Starke County Circuit Court">Starke County Circuit Court</option>
    <option value="Steuben County Circuit Court">Steuben County Circuit Court</option>
    <option value="Sullivan County Circuit Court">Sullivan County Circuit Court</option>
    <option value="Switzerland County Circuit Court">Switzerland County Circuit Court</option>
    <option value="Tippecanoe County Circuit Court">Tippecanoe County Circuit Court</option>
    <option value="Tipton County Circuit Court">Tipton County Circuit Court</option>
    <option value="Union County Circuit Court">Union County Circuit Court</option>
    <option value="Vanderburgh County Circuit Court">Vanderburgh County Circuit Court</option>
    <option value="Vermillion County Circuit Court">Vermillion County Circuit Court</option>
    <option value="Vigo County Circuit Court">Vigo County Circuit Court</option>
    <option value="Wabash County Circuit Court">Wabash County Circuit Court</option>
    <option value="Warren County Circuit Court">Warren County Circuit Court</option>
    <option value="Warrick County Circuit Court">Warrick County Circuit Court</option>
    <option value="Washington County Circuit Court">Washington County Circuit Court</option>
    <option value="Wayne County Circuit Court">Wayne County Circuit Court</option>
    <option value="Wells County Circuit Court">Wells County Circuit Court</option>
    <option value="White County Circuit Court">White County Circuit Court</option>
    <option value="Whitley County Circuit Court">Whitley County Circuit Court</option>
  </>
)}
{secondValue === 'Iowa' && (
  <>
    {/* First Judicial District */}
    <option value="First Judicial District - Allamakee County District Court">Allamakee County District Court</option>
    <option value="First Judicial District - Black Hawk County District Court">Black Hawk County District Court</option>
    <option value="First Judicial District - Buchanan County District Court">Buchanan County District Court</option>
    <option value="First Judicial District - Chickasaw County District Court">Chickasaw County District Court</option>
    <option value="First Judicial District - Clayton County District Court">Clayton County District Court</option>
    <option value="First Judicial District - Delaware County District Court">Delaware County District Court</option>
    <option value="First Judicial District - Dubuque County District Court">Dubuque County District Court</option>
    <option value="First Judicial District - Fayette County District Court">Fayette County District Court</option>
    <option value="First Judicial District - Grundy County District Court">Grundy County District Court</option>
    <option value="First Judicial District - Howard County District Court">Howard County District Court</option>
    <option value="First Judicial District - Winneshiek County District Court">Winneshiek County District Court</option>

    {/* Second Judicial District */}
    <option value="Second Judicial District - Boone County District Court">Boone County District Court</option>
    <option value="Second Judicial District - Calhoun County District Court">Calhoun County District Court</option>
    <option value="Second Judicial District - Carroll County District Court">Carroll County District Court</option>
    <option value="Second Judicial District - Cerro Gordo County District Court">Cerro Gordo County District Court</option>
    <option value="Second Judicial District - Emmet County District Court">Emmet County District Court</option>
    <option value="Second Judicial District - Franklin County District Court">Franklin County District Court</option>
    <option value="Second Judicial District - Greene County District Court">Greene County District Court</option>
    <option value="Second Judicial District - Hamilton County District Court">Hamilton County District Court</option>
    <option value="Second Judicial District - Hancock County District Court">Hancock County District Court</option>
    <option value="Second Judicial District - Hardin County District Court">Hardin County District Court</option>
    <option value="Second Judicial District - Humboldt County District Court">Humboldt County District Court</option>
    <option value="Second Judicial District - Kossuth County District Court">Kossuth County District Court</option>
    <option value="Second Judicial District - Marshall County District Court">Marshall County District Court</option>
    <option value="Second Judicial District - Palo Alto County District Court">Palo Alto County District Court</option>
    <option value="Second Judicial District - Pocahontas County District Court">Pocahontas County District Court</option>
    <option value="Second Judicial District - Sac County District Court">Sac County District Court</option>
    <option value="Second Judicial District - Story County District Court">Story County District Court</option>
    <option value="Second Judicial District - Webster County District Court">Webster County District Court</option>
    <option value="Second Judicial District - Winnebago County District Court">Winnebago County District Court</option>
    <option value="Second Judicial District - Worth County District Court">Worth County District Court</option>
    <option value="Second Judicial District - Wright County District Court">Wright County District Court</option>
    {/* Third Judicial District */}
    <option value="Third Judicial District - Buena Vista County District Court">Buena Vista County District Court</option>
    <option value="Third Judicial District - Cherokee County District Court">Cherokee County District Court</option>
    <option value="Third Judicial District - Clay County District Court">Clay County District Court</option>
    <option value="Third Judicial District - Crawford County District Court">Crawford County District Court</option>
    <option value="Third Judicial District - Dickinson County District Court">Dickinson County District Court</option>
    <option value="Third Judicial District - Ida County District Court">Ida County District Court</option>
    <option value="Third Judicial District - Lyon County District Court">Lyon County District Court</option>
    <option value="Third Judicial District - Monona County District Court">Monona County District Court</option>
    <option value="Third Judicial District - O'Brien County District Court">O'Brien County District Court</option>
    <option value="Third Judicial District - Osceola County District Court">Osceola County District Court</option>
    <option value="Third Judicial District - Plymouth County District Court">Plymouth County District Court</option>
    <option value="Third Judicial District - Sioux County District Court">Sioux County District Court</option>
    <option value="Third Judicial District - Woodbury County District Court">Woodbury County District Court</option>
    {/* Fourth Judicial District */}
    <option value="Fourth Judicial District - Audubon County District Court">Audubon County District Court</option>
    <option value="Fourth Judicial District - Cass County District Court">Cass County District Court</option>
    <option value="Fourth Judicial District - Fremont County District Court">Fremont County District Court</option>
    <option value="Fourth Judicial District - Harrison County District Court">Harrison County District Court</option>
    <option value="Fourth Judicial District - Mills County District Court">Mills County District Court</option>
    <option value="Fourth Judicial District - Montgomery County District Court">Montgomery County District Court</option>
    <option value="Fourth Judicial District - Page County District Court">Page County District Court</option>
    <option value="Fourth Judicial District - Pottawattamie County District Court">Pottawattamie County District Court</option>
    <option value="Fourth Judicial District - Shelby County District Court">Shelby County District Court</option>
    {/* Fifth Judicial District */}
    <option value="Fifth Judicial District - Adair County District Court">Adair County District Court</option>
    <option value="Fifth Judicial District - Adams County District Court">Adams County District Court</option>
    <option value="Fifth Judicial District - Clarke County District Court">Clarke County District Court</option>
    <option value="Fifth Judicial District - Dallas County District Court">Dallas County District Court</option>
    <option value="Fifth Judicial District - Decatur County District Court">Decatur County District Court</option>
    <option value="Fifth Judicial District - Guthrie County District Court">Guthrie County District Court</option>
    <option value="Fifth Judicial District - Jasper County District Court">Jasper County District Court</option>
    <option value="Fifth Judicial District - Lucas County District Court">Lucas County District Court</option>
    <option value="Fifth Judicial District - Madison County District Court">Madison County District Court</option>
    <option value="Fifth Judicial District - Marion County District Court">Marion County District Court</option>
    <option value="Fifth Judicial District - Polk County District Court">Polk County District Court</option>
    <option value="Fifth Judicial District - Ringgold County District Court">Ringgold County District Court</option>
    <option value="Fifth Judicial District - Taylor County District Court">Taylor County District Court</option>
    <option value="Fifth Judicial District - Union County District Court">Union County District Court</option>
    <option value="Fifth Judicial District - Warren County District Court">Warren County District Court</option>
    <option value="Fifth Judicial District - Wayne County District Court">Wayne County District Court</option>
    {/* Sixth Judicial District */}
    <option value="Sixth Judicial District - Benton County District Court">Benton County District Court</option>
    <option value="Sixth Judicial District - Iowa County District Court">Iowa County District Court</option>
    <option value="Sixth Judicial District - Johnson County District Court">Johnson County District Court</option>
    <option value="Sixth Judicial District - Jones County District Court">Jones County District Court</option>
    <option value="Sixth Judicial District - Linn County District Court">Linn County District Court</option>
    <option value="Sixth Judicial District - Tama County District Court">Tama County District Court</option>
    {/* Seventh Judicial District */}
    <option value="Seventh Judicial District - Cedar County District Court">Cedar County District Court</option>
    <option value="Seventh Judicial District - Clinton County District Court">Clinton County District Court</option>
    <option value="Seventh Judicial District - Jackson County District Court">Jackson County District Court</option>
    <option value="Seventh Judicial District - Muscatine County District Court">Muscatine County District Court</option>
    <option value="Seventh Judicial District - Scott County District Court">Scott County District Court</option>
    {/* Eighth Judicial District */}
    <option value="Eighth Judicial District - Appanoose County District Court">Appanoose County District Court</option>
    <option value="Eighth Judicial District - Davis County District Court">Davis County District Court</option>
    <option value="Eighth Judicial District - Des Moines County District Court">Des Moines County District Court</option>
    <option value="Eighth Judicial District - Henry County District Court">Henry County District Court</option>
    <option value="Eighth Judicial District - Jefferson County District Court">Jefferson County District Court</option>
    <option value="Eighth Judicial District - Keokuk County District Court">Keokuk County District Court</option>
    <option value="Eighth Judicial District - Lee County District Court">Lee County District Court</option>
    <option value="Eighth Judicial District - Louisa County District Court">Louisa County District Court</option>
    <option value="Eighth Judicial District - Mahaska County District Court">Mahaska County District Court</option>
    <option value="Eighth Judicial District - Van Buren County District Court">Van Buren County District Court</option>
    <option value="Eighth Judicial District - Wapello County District Court">Wapello County District Court</option>
    <option value="Eighth Judicial District - Washington County District Court">Washington County District Court</option>
    </>
  )}
{secondValue === 'Kansas' && (
  <>
    {/* First Judicial District */}
    <option value="First Judicial District - Atchison County District Court">Atchison County District Court</option>
    <option value="First Judicial District - Leavenworth County District Court">Leavenworth County District Court</option>
    
    {/* Second Judicial District */}
    <option value="Second Judicial District - Jackson County District Court">Jackson County District Court</option>
    <option value="Second Judicial District - Jefferson County District Court">Jefferson County District Court</option>
    <option value="Second Judicial District - Pottawatomie County District Court">Pottawatomie County District Court</option>
    <option value="Second Judicial District - Wabaunsee County District Court">Wabaunsee County District Court</option>
    
    {/* Third Judicial District */}
    <option value="Third Judicial District - Shawnee County District Court">Shawnee County District Court</option>
    
    {/* Fourth Judicial District */}
    <option value="Fourth Judicial District - Anderson County District Court">Anderson County District Court</option>
    <option value="Fourth Judicial District - Coffey County District Court">Coffey County District Court</option>
    <option value="Fourth Judicial District - Franklin County District Court">Franklin County District Court</option>
    <option value="Fourth Judicial District - Osage County District Court">Osage County District Court</option>
    
    {/* Fifth Judicial District */}
    <option value="Fifth Judicial District - Chase County District Court">Chase County District Court</option>
    <option value="Fifth Judicial District - Lyon County District Court">Lyon County District Court</option>
    
    {/* Sixth Judicial District */}
    <option value="Sixth Judicial District - Bourbon County District Court">Bourbon County District Court</option>
    <option value="Sixth Judicial District - Linn County District Court">Linn County District Court</option>
    <option value="Sixth Judicial District - Miami County District Court">Miami County District Court</option>
    
    {/* Seventh Judicial District */}
    <option value="Seventh Judicial District - Douglas County District Court">Douglas County District Court</option>
    
    {/* Eighth Judicial District */}
    <option value="Eighth Judicial District - Dickinson County District Court">Dickinson County District Court</option>
    <option value="Eighth Judicial District - Geary County District Court">Geary County District Court</option>
    <option value="Eighth Judicial District - Marion County District Court">Marion County District Court</option>
    <option value="Eighth Judicial District - Morris County District Court">Morris County District Court</option>
    
    {/* Ninth Judicial District */}
    <option value="Ninth Judicial District - Harvey County District Court">Harvey County District Court</option>
    <option value="Ninth Judicial District - McPherson County District Court">McPherson County District Court</option>
    
    {/* Tenth Judicial District */}
    <option value="Tenth Judicial District - Johnson County District Court">Johnson County District Court</option>
    
    {/* Eleventh Judicial District */}
    <option value="Eleventh Judicial District - Cherokee County District Court">Cherokee County District Court</option>
    <option value="Eleventh Judicial District - Crawford County District Court">Crawford County District Court</option>
    <option value="Eleventh Judicial District - Labette County District Court">Labette County District Court</option>
    <option value="Eleventh Judicial District - Montgomery County District Court">Montgomery County District Court</option>
    
    {/* Twelfth Judicial District */}
    <option value="Twelfth Judicial District - Cloud County District Court">Cloud County District Court</option>
    <option value="Twelfth Judicial District - Jewell County District Court">Jewell County District Court</option>
    <option value="Twelfth Judicial District - Lincoln County District Court">Lincoln County District Court</option>
    <option value="Twelfth Judicial District - Mitchell County District Court">Mitchell County District Court</option>
    <option value="Twelfth Judicial District - Republic County District Court">Republic County District Court</option>
    <option value="Twelfth Judicial District - Washington County District Court">Washington County District Court</option>
    
    {/* Thirteenth Judicial District */}
    <option value="Thirteenth Judicial District - Butler County District Court">Butler County District Court</option>
    <option value="Thirteenth Judicial District - Elk County District Court">Elk County District Court</option>
    <option value="Thirteenth Judicial District - Greenwood County District Court">Greenwood County District Court</option>
    
    {/* Fourteenth Judicial District */}
    <option value="Fourteenth Judicial District - Chautauqua County District Court">Chautauqua County District Court</option>
    <option value="Fourteenth Judicial District - Montgomery County District Court">Montgomery County District Court</option>
    
    {/* Fifteenth Judicial District */}
    <option value="Fifteenth Judicial District - Cheyenne County District Court">Cheyenne County District Court</option>
    <option value="Fifteenth Judicial District - Logan County District Court">Logan County District Court</option>
    <option value="Fifteenth Judicial District - Rawlins County District Court">Rawlins County District Court</option>
    <option value="Fifteenth Judicial District - Sheridan County District Court">Sheridan County District Court</option>
    <option value="Fifteenth Judicial District - Sherman County District Court">Sherman County District Court</option>
    <option value="Fifteenth Judicial District - Thomas County District Court">Thomas County District Court</option>
    <option value="Fifteenth Judicial District - Wallace County District Court">Wallace County District Court</option>
    
    {/* Sixteenth Judicial District */}
    <option value="Sixteenth Judicial District - Clark County District Court">Clark County District Court</option>
    <option value="Sixteenth Judicial District - Comanche County District Court">Comanche County District Court</option>
    <option value="Sixteenth Judicial District - Ford County District Court">Ford County District Court</option>
    <option value="Sixteenth Judicial District - Gray County District Court">Gray County District Court</option>
    <option value="Sixteenth Judicial District - Kiowa County District Court">Kiowa County District Court</option>
    <option value="Sixteenth Judicial District - Meade County District Court">Meade County District Court</option>
    
    {/* Seventeenth Judicial District */}
    <option value="Seventeenth Judicial District - Decatur County District Court">Decatur County District Court</option>
    <option value="Seventeenth Judicial District - Graham County District Court">Graham County District Court</option>
    <option value="Seventeenth Judicial District - Norton County District Court">Norton County District Court</option>
    <option value="Seventeenth Judicial District - Osborne County District Court">Osborne County District Court</option>
    <option value="Seventeenth Judicial District - Phillips County District Court">Phillips County District Court</option>
    <option value="Seventeenth Judicial District - Smith County District Court">Smith County District Court</option>
    
    {/* Eighteenth Judicial District */}
    <option value="Eighteenth Judicial District - Sedgwick County District Court">Sedgwick County District Court</option>
    
    {/* Nineteenth Judicial District */}
    <option value="Nineteenth Judicial District - Cowley County District Court">Cowley County District Court</option>
    
    {/* Twentieth Judicial District */}
    <option value="Twentieth Judicial District - Barton County District Court">Barton County District Court</option>
    <option value="Twentieth Judicial District - Ellsworth County District Court">Ellsworth County District Court</option>
    <option value="Twentieth Judicial District - Rice County District Court">Rice County District Court</option>
    <option value="Twentieth Judicial District - Russell County District Court">Russell County District Court</option>
    <option value="Twentieth Judicial District - Stafford County District Court">Stafford County District Court</option>
    
    {/* Twenty-First Judicial District */}
    <option value="Twenty-First Judicial District - Clay County District Court">Clay County District Court</option>
    <option value="Twenty-First Judicial District - Riley County District Court">Riley County District Court</option>
    
    {/* Twenty-Second Judicial District */}
    <option value="Twenty-Second Judicial District - Brown County District Court">Brown County District Court</option>
    <option value="Twenty-Second Judicial District - Doniphan County District Court">Doniphan County District Court</option>
    
    {/* Twenty-Third Judicial District */}
    <option value="Twenty-Third Judicial District - Ellis County District Court">Ellis County District Court</option>
    <option value="Twenty-Third Judicial District - Gove County District Court">Gove County District Court</option>
    <option value="Twenty-Third Judicial District - Rooks County District Court">Rooks County District Court</option>
    <option value="Twenty-Third Judicial District - Trego County District Court">Trego County District Court</option>
    
    {/* Twenty-Fourth Judicial District */}
    <option value="Twenty-Fourth Judicial District - Edwards County District Court">Edwards County District Court</option>
    <option value="Twenty-Fourth Judicial District - Hodgeman County District Court">Hodgeman County District Court</option>
    <option value="Twenty-Fourth Judicial District - Lane County District Court">Lane County District Court</option>
    <option value="Twenty-Fourth Judicial District - Ness County District Court">Ness County District Court</option>
    <option value="Twenty-Fourth Judicial District - Pawnee County District Court">Pawnee County District Court</option>
    
    {/* Twenty-Fifth Judicial District */}
    <option value="Twenty-Fifth Judicial District - Finney County District Court">Finney County District Court</option>
    <option value="Twenty-Fifth Judicial District - Greeley County District Court">Greeley County District Court</option>
    <option value="Twenty-Fifth Judicial District - Hamilton County District Court">Hamilton County District Court</option>
    <option value="Twenty-Fifth Judicial District - Kearny County District Court">Kearny County District Court</option>
    <option value="Twenty-Fifth Judicial District - Scott County District Court">Scott County District Court</option>
    <option value="Twenty-Fifth Judicial District - Wichita County District Court">Wichita County District Court</option>
    
    {/* Twenty-Sixth Judicial District */}
    <option value="Twenty-Sixth Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="Twenty-Sixth Judicial District - Haskell County District Court">Haskell County District Court</option>
    <option value="Twenty-Sixth Judicial District - Morton County District Court">Morton County District Court</option>
    <option value="Twenty-Sixth Judicial District - Seward County District Court">Seward County District Court</option>
    <option value="Twenty-Sixth Judicial District - Stanton County District Court">Stanton County District Court</option>
    
    {/* Twenty-Seventh Judicial District */}
    <option value="Twenty-Seventh Judicial District - Reno County District Court">Reno County District Court</option>
    
    {/* Twenty-Eighth Judicial District */}
    <option value="Twenty-Eighth Judicial District - Ottawa County District Court">Ottawa County District Court</option>
    <option value="Twenty-Eighth Judicial District - Saline County District Court">Saline County District Court</option>
    
    {/* Twenty-Ninth Judicial District */}
    <option value="Twenty-Ninth Judicial District - Wyandotte County District Court">Wyandotte County District Court</option>
    
    {/* Thirtieth Judicial District */}
    <option value="Thirtieth Judicial District - Barber County District Court">Barber County District Court</option>
    <option value="Thirtieth Judicial District - Harper County District Court">Harper County District Court</option>
    <option value="Thirtieth Judicial District - Kingman County District Court">Kingman County District Court</option>
    <option value="Thirtieth Judicial District - Pratt County District Court">Pratt County District Court</option>
    
    {/* Thirty-First Judicial District */}
    <option value="Thirty-First Judicial District - Allen County District Court">Allen County District Court</option>
    <option value="Thirty-First Judicial District - Neosho County District Court">Neosho County District Court</option>
    <option value="Thirty-First Judicial District - Wilson County District Court">Wilson County District Court</option>
    <option value="Thirty-First Judicial District - Woodson County District Court">Woodson County District Court</option>
  </>
)}
{secondValue === 'Kentucky' && (
  <>
    <option value="First Judicial Circuit - Fulton County Circuit Court">Fulton County Circuit Court</option>
    <option value="First Judicial Circuit - Hickman County Circuit Court">Hickman County Circuit Court</option>
    <option value="First Judicial Circuit - Carlisle County Circuit Court">Carlisle County Circuit Court</option>
    <option value="First Judicial Circuit - Ballard County Circuit Court">Ballard County Circuit Court</option>
    <option value="Second Judicial Circuit - McCracken County Circuit Court">McCracken County Circuit Court</option>
    <option value="Third Judicial Circuit - Christian County Circuit Court">Christian County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Hopkins County Circuit Court">Hopkins County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Crittenden County Circuit Court">Crittenden County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Union County Circuit Court">Union County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Webster County Circuit Court">Webster County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Daviess County Circuit Court">Daviess County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Logan County Circuit Court">Logan County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Todd County Circuit Court">Todd County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Warren County Circuit Court">Warren County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Hardin County Circuit Court">Hardin County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Hart County Circuit Court">Hart County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Larue County Circuit Court">Larue County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Nelson County Circuit Court">Nelson County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Green County Circuit Court">Green County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Marion County Circuit Court">Marion County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Taylor County Circuit Court">Taylor County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Henry County Circuit Court">Henry County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Oldham County Circuit Court">Oldham County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Trimble County Circuit Court">Trimble County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - Garrard County Circuit Court">Garrard County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - Jessamine County Circuit Court">Jessamine County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Bourbon County Circuit Court">Bourbon County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Scott County Circuit Court">Scott County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Woodford County Circuit Court">Woodford County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Grant County Circuit Court">Grant County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Owen County Circuit Court">Owen County Circuit Court</option>
    <option value="Sixteenth Judicial Circuit - Kenton County Circuit Court">Kenton County Circuit Court</option>
    <option value="Seventeenth Judicial Circuit - Campbell County Circuit Court">Campbell County Circuit Court</option>
    <option value="Eighteenth Judicial Circuit - Harrison County Circuit Court">Harrison County Circuit Court</option>
    <option value="Eighteenth Judicial Circuit - Pendleton County Circuit Court">Pendleton County Circuit Court</option>
    <option value="Nineteenth Judicial Circuit - Bracken County Circuit Court">Bracken County Circuit Court</option>
    <option value="Nineteenth Judicial Circuit - Fleming County Circuit Court">Fleming County Circuit Court</option>
    <option value="Nineteenth Judicial Circuit - Mason County Circuit Court">Mason County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - Greenup County Circuit Court">Greenup County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - Lewis County Circuit Court">Lewis County Circuit Court</option>
    <option value="Twenty-First Judicial Circuit - Bath County Circuit Court">Bath County Circuit Court</option>
    <option value="Twenty-First Judicial Circuit - Menifee County Circuit Court">Menifee County Circuit Court</option>
    <option value="Twenty-First Judicial Circuit - Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Twenty-First Judicial Circuit - Rowan County Circuit Court">Rowan County Circuit Court</option>
    <option value="Twenty-Second Judicial Circuit - Fayette County Circuit Court">Fayette County Circuit Court</option>
    <option value="Twenty-Third Judicial Circuit - Estill County Circuit Court">Estill County Circuit Court</option>
    <option value="Twenty-Third Judicial Circuit - Lee County Circuit Court">Lee County Circuit Court</option>
    <option value="Twenty-Third Judicial Circuit - Owsley County Circuit Court">Owsley County Circuit Court</option>
    <option value="Twenty-Third Judicial Circuit - Wolfe County Circuit Court">Wolfe County Circuit Court</option>
    <option value="Twenty-Fourth Judicial Circuit - Johnson County Circuit Court">Johnson County Circuit Court</option>
    <option value="Twenty-Fourth Judicial Circuit - Lawrence County Circuit Court">Lawrence County Circuit Court</option>
    <option value="Twenty-Fourth Judicial Circuit - Martin County Circuit Court">Martin County Circuit Court</option>
    <option value="Twenty-Fifth Judicial Circuit - Clark County Circuit Court">Clark County Circuit Court</option>
    <option value="Twenty-Fifth Judicial Circuit - Madison County Circuit Court">Madison County Circuit Court</option>
    <option value="Twenty-Sixth Judicial Circuit - Harlan County Circuit Court">Harlan County Circuit Court</option>
    <option value="Twenty-Seventh Judicial Circuit - Knox County Circuit Court">Knox County Circuit Court</option>
    <option value="Twenty-Seventh Judicial Circuit - Laurel County Circuit Court">Laurel County Circuit Court</option>
    <option value="Twenty-Eighth Judicial Circuit - Pulaski County Circuit Court">Pulaski County Circuit Court</option>
    <option value="Twenty-Ninth Judicial Circuit - Adair County Circuit Court">Adair County Circuit Court</option>
    <option value="Twenty-Ninth Judicial Circuit - Casey County Circuit Court">Casey County Circuit Court</option>
    <option value="Twenty-Ninth Judicial Circuit - Cumberland County Circuit Court">Cumberland County Circuit Court</option>
    <option value="Twenty-Ninth Judicial Circuit - Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Twenty-Ninth Judicial Circuit - Russell County Circuit Court">Russell County Circuit Court</option>
    <option value="Thirtieth Judicial Circuit - Jefferson County Circuit Court">Jefferson County Circuit Court</option>
    <option value="Thirty-First Judicial Circuit - Floyd County Circuit Court">Floyd County Circuit Court</option>
    <option value="Thirty-Second Judicial Circuit - Boyd County Circuit Court">Boyd County Circuit Court</option>
    <option value="Thirty-Third Judicial Circuit - Perry County Circuit Court">Perry County Circuit Court</option>
    <option value="Thirty-Fourth Judicial Circuit - Whitley County Circuit Court">Whitley County Circuit Court</option>
    <option value="Thirty-Fifth Judicial Circuit - Pike County Circuit Court">Pike County Circuit Court</option>
    <option value="Thirty-Sixth Judicial Circuit - Knott County Circuit Court">Knott County Circuit Court</option>
    <option value="Thirty-Sixth Judicial Circuit - Magoffin County Circuit Court">Magoffin County Circuit Court</option>
    <option value="Thirty-Seventh Judicial Circuit - Carter County Circuit Court">Carter County Circuit Court</option>
    <option value="Thirty-Seventh Judicial Circuit - Elliott County Circuit Court">Elliott County Circuit Court</option>
    <option value="Thirty-Eighth Judicial Circuit - Butler County Circuit Court">Butler County Circuit Court</option>
    <option value="Thirty-Eighth Judicial Circuit - Edmonson County Circuit Court">Edmonson County Circuit Court</option>
    <option value="Thirty-Ninth Judicial Circuit - Breathitt County Circuit Court">Breathitt County Circuit Court</option>
    <option value="Thirty-Ninth Judicial Circuit - Powell County Circuit Court">Powell County Circuit Court</option>
    <option value="Fortieth Judicial Circuit - Clinton County Circuit Court">Clinton County Circuit Court</option>
    <option value="Fortieth Judicial Circuit - Wayne County Circuit Court">Wayne County Circuit Court</option>
    <option value="Forty-First Judicial Circuit - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Forty-First Judicial Circuit - Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Forty-Second Judicial Circuit - Bell County Circuit Court">Bell County Circuit Court</option>
    <option value="Forty-Third Judicial Circuit - Barren County Circuit Court">Barren County Circuit Court</option>
    <option value="Forty-Fourth Judicial Circuit - McCreary County Circuit Court">McCreary County Circuit Court</option>
    <option value="Forty-Fourth Judicial Circuit - Whitley County Circuit Court">Whitley County Circuit Court</option>
    <option value="Forty-Fifth Judicial Circuit - Trimble County Circuit Court">Trimble County Circuit Court</option>
    <option value="Forty-Sixth Judicial Circuit - McLean County Circuit Court">McLean County Circuit Court</option>
    <option value="Forty-Seventh Judicial Circuit - Johnson County Circuit Court">Johnson County Circuit Court</option>
    <option value="Forty-Eighth Judicial Circuit - Knox County Circuit Court">Knox County Circuit Court</option>
    <option value="Forty-Ninth Judicial Circuit - Owsley County Circuit Court">Owsley County Circuit Court</option>
    <option value="Fiftieth Judicial Circuit - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Fifty-First Judicial Circuit - Anderson County Circuit Court">Anderson County Circuit Court</option>
    <option value="Fifty-Second Judicial Circuit - Franklin County Circuit Court">Franklin County Circuit Court</option>
    <option value="Fifty-Third Judicial Circuit - Grant County Circuit Court">Grant County Circuit Court</option>
    <option value="Fifty-Fourth Judicial Circuit - Shelby County Circuit Court">Shelby County Circuit Court</option>
    <option value="Fifty-Fifth Judicial Circuit - Marshall County Circuit Court">Marshall County Circuit Court</option>
    <option value="Fifty-Sixth Judicial Circuit - Spencer County Circuit Court">Spencer County Circuit Court</option>
    <option value="Fifty-Seventh Judicial Circuit - Bourbon County Circuit Court">Bourbon County Circuit Court</option>
  </>
)}
{secondValue === 'Louisiana' && (
  <>
    <option value="First Judicial District - Caddo Parish District Court">Caddo Parish District Court</option>
    <option value="Second Judicial District - Bienville Parish District Court">Bienville Parish District Court</option>
    <option value="Second Judicial District - Claiborne Parish District Court">Claiborne Parish District Court</option>
    <option value="Second Judicial District - Jackson Parish District Court">Jackson Parish District Court</option>
    <option value="Third Judicial District - Lincoln Parish District Court">Lincoln Parish District Court</option>
    <option value="Third Judicial District - Union Parish District Court">Union Parish District Court</option>
    <option value="Fourth Judicial District - Morehouse Parish District Court">Morehouse Parish District Court</option>
    <option value="Fourth Judicial District - Ouachita Parish District Court">Ouachita Parish District Court</option>
    <option value="Fifth Judicial District - Franklin Parish District Court">Franklin Parish District Court</option>
    <option value="Fifth Judicial District - Richland Parish District Court">Richland Parish District Court</option>
    <option value="Fifth Judicial District - West Carroll Parish District Court">West Carroll Parish District Court</option>
    <option value="Sixth Judicial District - East Carroll Parish District Court">East Carroll Parish District Court</option>
    <option value="Sixth Judicial District - Madison Parish District Court">Madison Parish District Court</option>
    <option value="Sixth Judicial District - Tensas Parish District Court">Tensas Parish District Court</option>
    <option value="Seventh Judicial District - Catahoula Parish District Court">Catahoula Parish District Court</option>
    <option value="Seventh Judicial District - Concordia Parish District Court">Concordia Parish District Court</option>
    <option value="Eighth Judicial District - Winn Parish District Court">Winn Parish District Court</option>
    <option value="Ninth Judicial District - Rapides Parish District Court">Rapides Parish District Court</option>
    <option value="Tenth Judicial District - Natchitoches Parish District Court">Natchitoches Parish District Court</option>
    <option value="Eleventh Judicial District - Sabine Parish District Court">Sabine Parish District Court</option>
    <option value="Twelfth Judicial District - Avoyelles Parish District Court">Avoyelles Parish District Court</option>
    <option value="Thirteenth Judicial District - Evangeline Parish District Court">Evangeline Parish District Court</option>
    <option value="Fourteenth Judicial District - Calcasieu Parish District Court">Calcasieu Parish District Court</option>
    <option value="Fifteenth Judicial District - Acadia Parish District Court">Acadia Parish District Court</option>
    <option value="Fifteenth Judicial District - Lafayette Parish District Court">Lafayette Parish District Court</option>
    <option value="Fifteenth Judicial District - Vermilion Parish District Court">Vermilion Parish District Court</option>
    <option value="Sixteenth Judicial District - Iberia Parish District Court">Iberia Parish District Court</option>
    <option value="Sixteenth Judicial District - St. Martin Parish District Court">St. Martin Parish District Court</option>
    <option value="Sixteenth Judicial District - St. Mary Parish District Court">St. Mary Parish District Court</option>
    <option value="Seventeenth Judicial District - Lafourche Parish District Court">Lafourche Parish District Court</option>
    <option value="Eighteenth Judicial District - Iberville Parish District Court">Iberville Parish District Court</option>
    <option value="Eighteenth Judicial District - Pointe Coupee Parish District Court">Pointe Coupee Parish District Court</option>
    <option value="Eighteenth Judicial District - West Baton Rouge Parish District Court">West Baton Rouge Parish District Court</option>
    <option value="Nineteenth Judicial District - East Baton Rouge Parish District Court">East Baton Rouge Parish District Court</option>
    <option value="Twentieth Judicial District - East Feliciana Parish District Court">East Feliciana Parish District Court</option>
    <option value="Twentieth Judicial District - West Feliciana Parish District Court">West Feliciana Parish District Court</option>
    <option value="Twenty-First Judicial District - Livingston Parish District Court">Livingston Parish District Court</option>
    <option value="Twenty-First Judicial District - St. Helena Parish District Court">St. Helena Parish District Court</option>
    <option value="Twenty-First Judicial District - Tangipahoa Parish District Court">Tangipahoa Parish District Court</option>
    <option value="Twenty-Second Judicial District - St. Tammany Parish District Court">St. Tammany Parish District Court</option>
    <option value="Twenty-Second Judicial District - Washington Parish District Court">Washington Parish District Court</option>
    <option value="Twenty-Third Judicial District - Ascension Parish District Court">Ascension Parish District Court</option>
    <option value="Twenty-Third Judicial District - Assumption Parish District Court">Assumption Parish District Court</option>
    <option value="Twenty-Third Judicial District - St. James Parish District Court">St. James Parish District Court</option>
    <option value="Twenty-Fourth Judicial District - Jefferson Parish District Court">Jefferson Parish District Court</option>
    <option value="Twenty-Fifth Judicial District - Plaquemines Parish District Court">Plaquemines Parish District Court</option>
    <option value="Twenty-Sixth Judicial District - Bossier Parish District Court">Bossier Parish District Court</option>
    <option value="Twenty-Sixth Judicial District - Webster Parish District Court">Webster Parish District Court</option>
    <option value="Twenty-Seventh Judicial District - St. Landry Parish District Court">St. Landry Parish District Court</option>
    <option value="Twenty-Eighth Judicial District - LaSalle Parish District Court">LaSalle Parish District Court</option>
    <option value="Twenty-Ninth Judicial District - St. Charles Parish District Court">St. Charles Parish District Court</option>
    <option value="Thirtieth Judicial District - Vernon Parish District Court">Vernon Parish District Court</option>
    <option value="Thirty-First Judicial District - Jefferson Davis Parish District Court">Jefferson Davis Parish District Court</option>
    <option value="Thirty-Second Judicial District - Terrebonne Parish District Court">Terrebonne Parish District Court</option>
    <option value="Thirty-Third Judicial District - Allen Parish District Court">Allen Parish District Court</option>
    <option value="Thirty-Fourth Judicial District - St. Bernard Parish District Court">St. Bernard Parish District Court</option>
    <option value="Thirty-Fifth Judicial District - Grant Parish District Court">Grant Parish District Court</option>
    <option value="Thirty-Sixth Judicial District - Beauregard Parish District Court">Beauregard Parish District Court</option>
    <option value="Thirty-Seventh Judicial District - Caldwell Parish District Court">Caldwell Parish District Court</option>
    <option value="Thirty-Eighth Judicial District - Cameron Parish District Court">Cameron Parish District Court</option>
    <option value="Thirty-Ninth Judicial District - Red River Parish District Court">Red River Parish District Court</option>
    <option value="Fortieth Judicial District - St. John the Baptist Parish District Court">St. John the Baptist Parish District Court</option>
    <option value="Forty-First Judicial District - Orleans Parish Criminal District Court">Orleans Parish Criminal District Court</option>
    <option value="Forty-Second Judicial District - DeSoto Parish District Court">DeSoto Parish District Court</option>
  </>
)}
{secondValue === 'Maine' && (
  <>
    <option value="Androscoggin County Superior Court">Androscoggin County Superior Court</option>
    <option value="Aroostook County Superior Court">Aroostook County Superior Court</option>
    <option value="Cumberland County Superior Court">Cumberland County Superior Court</option>
    <option value="Franklin County Superior Court">Franklin County Superior Court</option>
    <option value="Hancock County Superior Court">Hancock County Superior Court</option>
    <option value="Kennebec County Superior Court">Kennebec County Superior Court</option>
    <option value="Knox County Superior Court">Knox County Superior Court</option>
    <option value="Lincoln County Superior Court">Lincoln County Superior Court</option>
    <option value="Oxford County Superior Court">Oxford County Superior Court</option>
    <option value="Penobscot County Superior Court">Penobscot County Superior Court</option>
    <option value="Piscataquis County Superior Court">Piscataquis County Superior Court</option>
    <option value="Sagadahoc County Superior Court">Sagadahoc County Superior Court</option>
    <option value="Somerset County Superior Court">Somerset County Superior Court</option>
    <option value="Waldo County Superior Court">Waldo County Superior Court</option>
    <option value="Washington County Superior Court">Washington County Superior Court</option>
    <option value="York County Superior Court">York County Superior Court</option>
  </>
)}
{secondValue === 'Maryland' && (
  <>
    <option value="Allegany County Circuit Court">Allegany County Circuit Court</option>
    <option value="Anne Arundel County Circuit Court">Anne Arundel County Circuit Court</option>
    <option value="Baltimore City Circuit Court">Baltimore City Circuit Court</option>
    <option value="Baltimore County Circuit Court">Baltimore County Circuit Court</option>
    <option value="Calvert County Circuit Court">Calvert County Circuit Court</option>
    <option value="Caroline County Circuit Court">Caroline County Circuit Court</option>
    <option value="Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Cecil County Circuit Court">Cecil County Circuit Court</option>
    <option value="Charles County Circuit Court">Charles County Circuit Court</option>
    <option value="Dorchester County Circuit Court">Dorchester County Circuit Court</option>
    <option value="Frederick County Circuit Court">Frederick County Circuit Court</option>
    <option value="Garrett County Circuit Court">Garrett County Circuit Court</option>
    <option value="Harford County Circuit Court">Harford County Circuit Court</option>
    <option value="Howard County Circuit Court">Howard County Circuit Court</option>
    <option value="Kent County Circuit Court">Kent County Circuit Court</option>
    <option value="Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Prince George's County Circuit Court">Prince George's County Circuit Court</option>
    <option value="Queen Anne's County Circuit Court">Queen Anne's County Circuit Court</option>
    <option value="St. Mary's County Circuit Court">St. Mary's County Circuit Court</option>
    <option value="Somerset County Circuit Court">Somerset County Circuit Court</option>
    <option value="Talbot County Circuit Court">Talbot County Circuit Court</option>
    <option value="Washington County Circuit Court">Washington County Circuit Court</option>
    <option value="Wicomico County Circuit Court">Wicomico County Circuit Court</option>
    <option value="Worcester County Circuit Court">Worcester County Circuit Court</option>
  </>
)}
{secondValue === 'Massachusetts' && (
  <>
    <option value="Barnstable County Superior Court">Barnstable County Superior Court</option>
    <option value="Berkshire County Superior Court">Berkshire County Superior Court</option>
    <option value="Bristol County Superior Court">Bristol County Superior Court</option>
    <option value="Dukes County Superior Court">Dukes County Superior Court</option>
    <option value="Essex County Superior Court">Essex County Superior Court</option>
    <option value="Franklin County Superior Court">Franklin County Superior Court</option>
    <option value="Hampden County Superior Court">Hampden County Superior Court</option>
    <option value="Hampshire County Superior Court">Hampshire County Superior Court</option>
    <option value="Middlesex County Superior Court">Middlesex County Superior Court</option>
    <option value="Nantucket County Superior Court">Nantucket County Superior Court</option>
    <option value="Norfolk County Superior Court">Norfolk County Superior Court</option>
    <option value="Plymouth County Superior Court">Plymouth County Superior Court</option>
    <option value="Suffolk County Superior Court">Suffolk County Superior Court</option>
    <option value="Worcester County Superior Court">Worcester County Superior Court</option>
  </>
)}
{secondValue === 'Michigan' && (
  <>
    <option value="Alcona County Circuit Court">Alcona County Circuit Court</option>
    <option value="Alger County Circuit Court">Alger County Circuit Court</option>
    <option value="Allegan County Circuit Court">Allegan County Circuit Court</option>
    <option value="Alpena County Circuit Court">Alpena County Circuit Court</option>
    <option value="Antrim County Circuit Court">Antrim County Circuit Court</option>
    <option value="Arenac County Circuit Court">Arenac County Circuit Court</option>
    <option value="Baraga County Circuit Court">Baraga County Circuit Court</option>
    <option value="Barry County Circuit Court">Barry County Circuit Court</option>
    <option value="Bay County Circuit Court">Bay County Circuit Court</option>
    <option value="Benzie County Circuit Court">Benzie County Circuit Court</option>
    <option value="Berrien County Circuit Court">Berrien County Circuit Court</option>
    <option value="Branch County Circuit Court">Branch County Circuit Court</option>
    <option value="Calhoun County Circuit Court">Calhoun County Circuit Court</option>
    <option value="Cass County Circuit Court">Cass County Circuit Court</option>
    <option value="Charlevoix County Circuit Court">Charlevoix County Circuit Court</option>
    <option value="Cheboygan County Circuit Court">Cheboygan County Circuit Court</option>
    <option value="Chippewa County Circuit Court">Chippewa County Circuit Court</option>
    <option value="Clare County Circuit Court">Clare County Circuit Court</option>
    <option value="Clinton County Circuit Court">Clinton County Circuit Court</option>
    <option value="Crawford County Circuit Court">Crawford County Circuit Court</option>
    <option value="Delta County Circuit Court">Delta County Circuit Court</option>
    <option value="Dickinson County Circuit Court">Dickinson County Circuit Court</option>
    <option value="Eaton County Circuit Court">Eaton County Circuit Court</option>
    <option value="Emmet County Circuit Court">Emmet County Circuit Court</option>
    <option value="Genesee County Circuit Court">Genesee County Circuit Court</option>
    <option value="Gladwin County Circuit Court">Gladwin County Circuit Court</option>
    <option value="Gogebic County Circuit Court">Gogebic County Circuit Court</option>
    <option value="Grand Traverse County Circuit Court">Grand Traverse County Circuit Court</option>
    <option value="Gratiot County Circuit Court">Gratiot County Circuit Court</option>
    <option value="Hillsdale County Circuit Court">Hillsdale County Circuit Court</option>
    <option value="Houghton County Circuit Court">Houghton County Circuit Court</option>
    <option value="Huron County Circuit Court">Huron County Circuit Court</option>
    <option value="Ingham County Circuit Court">Ingham County Circuit Court</option>
    <option value="Ionia County Circuit Court">Ionia County Circuit Court</option>
    <option value="Iosco County Circuit Court">Iosco County Circuit Court</option>
    <option value="Iron County Circuit Court">Iron County Circuit Court</option>
    <option value="Isabella County Circuit Court">Isabella County Circuit Court</option>
    <option value="Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Kalamazoo County Circuit Court">Kalamazoo County Circuit Court</option>
    <option value="Kalkaska County Circuit Court">Kalkaska County Circuit Court</option>
    <option value="Kent County Circuit Court">Kent County Circuit Court</option>
    <option value="Keweenaw County Circuit Court">Keweenaw County Circuit Court</option>
    <option value="Lake County Circuit Court">Lake County Circuit Court</option>
    <option value="Lapeer County Circuit Court">Lapeer County Circuit Court</option>
    <option value="Leelanau County Circuit Court">Leelanau County Circuit Court</option>
    <option value="Lenawee County Circuit Court">Lenawee County Circuit Court</option>
    <option value="Livingston County Circuit Court">Livingston County Circuit Court</option>
    <option value="Luce County Circuit Court">Luce County Circuit Court</option>
    <option value="Mackinac County Circuit Court">Mackinac County Circuit Court</option>
    <option value="Macomb County Circuit Court">Macomb County Circuit Court</option>
    <option value="Manistee County Circuit Court">Manistee County Circuit Court</option>
    <option value="Marquette County Circuit Court">Marquette County Circuit Court</option>
    <option value="Mason County Circuit Court">Mason County Circuit Court</option>
    <option value="Mecosta County Circuit Court">Mecosta County Circuit Court</option>
    <option value="Menominee County Circuit Court">Menominee County Circuit Court</option>
    <option value="Midland County Circuit Court">Midland County Circuit Court</option>
    <option value="Missaukee County Circuit Court">Missaukee County Circuit Court</option>
    <option value="Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Montcalm County Circuit Court">Montcalm County Circuit Court</option>
    <option value="Montmorency County Circuit Court">Montmorency County Circuit Court</option>
    <option value="Muskegon County Circuit Court">Muskegon County Circuit Court</option>
    <option value="Newaygo County Circuit Court">Newaygo County Circuit Court</option>
    <option value="Oakland County Circuit Court">Oakland County Circuit Court</option>
    <option value="Oceana County Circuit Court">Oceana County Circuit Court</option>
    <option value="Ogemaw County Circuit Court">Ogemaw County Circuit Court</option>
    <option value="Ontonagon County Circuit Court">Ontonagon County Circuit Court</option>
    <option value="Osceola County Circuit Court">Osceola County Circuit Court</option>
    <option value="Oscoda County Circuit Court">Oscoda County Circuit Court</option>
    <option value="Otsego County Circuit Court">Otsego County Circuit Court</option>
    <option value="Ottawa County Circuit Court">Ottawa County Circuit Court</option>
    <option value="Presque Isle County Circuit Court">Presque Isle County Circuit Court</option>
    <option value="Roscommon County Circuit Court">Roscommon County Circuit Court</option>
    <option value="Saginaw County Circuit Court">Saginaw County Circuit Court</option>
    <option value="St. Clair County Circuit Court">St. Clair County Circuit Court</option>
    <option value="St. Joseph County Circuit Court">St. Joseph County Circuit Court</option>
    <option value="Sanilac County Circuit Court">Sanilac County Circuit Court</option>
    <option value="Schoolcraft County Circuit Court">Schoolcraft County Circuit Court</option>
    <option value="Shiawassee County Circuit Court">Shiawassee County Circuit Court</option>
    <option value="Tuscola County Circuit Court">Tuscola County Circuit Court</option>
    <option value="Van Buren County Circuit Court">Van Buren County Circuit Court</option>
    <option value="Washtenaw County Circuit Court">Washtenaw County Circuit Court</option>
    <option value="Wayne County Circuit Court">Wayne County Circuit Court</option>
    <option value="Wexford County Circuit Court">Wexford County Circuit Court</option>
  </>
)}
{secondValue === 'Minnesota' && (
  <>
    <option value="First Judicial District - Carver County District Court">Carver County District Court</option>
    <option value="First Judicial District - Dakota County District Court">Dakota County District Court</option>
    <option value="First Judicial District - Goodhue County District Court">Goodhue County District Court</option>
    <option value="First Judicial District - Le Sueur County District Court">Le Sueur County District Court</option>
    <option value="First Judicial District - McLeod County District Court">McLeod County District Court</option>
    <option value="First Judicial District - Scott County District Court">Scott County District Court</option>
    <option value="First Judicial District - Sibley County District Court">Sibley County District Court</option>

    <option value="Second Judicial District - Ramsey County District Court">Ramsey County District Court</option>

    <option value="Third Judicial District - Dodge County District Court">Dodge County District Court</option>
    <option value="Third Judicial District - Fillmore County District Court">Fillmore County District Court</option>
    <option value="Third Judicial District - Freeborn County District Court">Freeborn County District Court</option>
    <option value="Third Judicial District - Houston County District Court">Houston County District Court</option>
    <option value="Third Judicial District - Mower County District Court">Mower County District Court</option>
    <option value="Third Judicial District - Olmsted County District Court">Olmsted County District Court</option>
    <option value="Third Judicial District - Rice County District Court">Rice County District Court</option>
    <option value="Third Judicial District - Steele County District Court">Steele County District Court</option>
    <option value="Third Judicial District - Wabasha County District Court">Wabasha County District Court</option>
    <option value="Third Judicial District - Waseca County District Court">Waseca County District Court</option>

    <option value="Fourth Judicial District - Hennepin County District Court">Hennepin County District Court</option>

    <option value="Fifth Judicial District - Blue Earth County District Court">Blue Earth County District Court</option>
    <option value="Fifth Judicial District - Brown County District Court">Brown County District Court</option>
    <option value="Fifth Judicial District - Cottonwood County District Court">Cottonwood County District Court</option>
    <option value="Fifth Judicial District - Faribault County District Court">Faribault County District Court</option>
    <option value="Fifth Judicial District - Jackson County District Court">Jackson County District Court</option>
    <option value="Fifth Judicial District - Lincoln County District Court">Lincoln County District Court</option>
    <option value="Fifth Judicial District - Lyon County District Court">Lyon County District Court</option>
    <option value="Fifth Judicial District - Martin County District Court">Martin County District Court</option>
    <option value="Fifth Judicial District - Murray County District Court">Murray County District Court</option>
    <option value="Fifth Judicial District - Nicollet County District Court">Nicollet County District Court</option>
    <option value="Fifth Judicial District - Nobles County District Court">Nobles County District Court</option>
    <option value="Fifth Judicial District - Pipestone County District Court">Pipestone County District Court</option>
    <option value="Fifth Judicial District - Redwood County District Court">Redwood County District Court</option>
    <option value="Fifth Judicial District - Rock County District Court">Rock County District Court</option>
    <option value="Fifth Judicial District - Watonwan County District Court">Watonwan County District Court</option>

    <option value="Sixth Judicial District - Carlton County District Court">Carlton County District Court</option>
    <option value="Sixth Judicial District - Cook County District Court">Cook County District Court</option>
    <option value="Sixth Judicial District - Lake County District Court">Lake County District Court</option>
    <option value="Sixth Judicial District - St. Louis County District Court">St. Louis County District Court</option>

    <option value="Seventh Judicial District - Becker County District Court">Becker County District Court</option>
    <option value="Seventh Judicial District - Benton County District Court">Benton County District Court</option>
    <option value="Seventh Judicial District - Clay County District Court">Clay County District Court</option>
    <option value="Seventh Judicial District - Douglas County District Court">Douglas County District Court</option>
    <option value="Seventh Judicial District - Mille Lacs County District Court">Mille Lacs County District Court</option>
    <option value="Seventh Judicial District - Morrison County District Court">Morrison County District Court</option>
    <option value="Seventh Judicial District - Otter Tail County District Court">Otter Tail County District Court</option>
    <option value="Seventh Judicial District - Stearns County District Court">Stearns County District Court</option>
    <option value="Seventh Judicial District - Todd County District Court">Todd County District Court</option>
    <option value="Seventh Judicial District - Wadena County District Court">Wadena County District Court</option>
    <option value="Seventh Judicial District - Wilkin County District Court">Wilkin County District Court</option>

    <option value="Eighth Judicial District - Big Stone County District Court">Big Stone County District Court</option>
    <option value="Eighth Judicial District - Chippewa County District Court">Chippewa County District Court</option>
    <option value="Eighth Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="Eighth Judicial District - Kandiyohi County District Court">Kandiyohi County District Court</option>
    <option value="Eighth Judicial District - Lac qui Parle County District Court">Lac qui Parle County District Court</option>
    <option value="Eighth Judicial District - Meeker County District Court">Meeker County District Court</option>
    <option value="Eighth Judicial District - Pope County District Court">Pope County District Court</option>
    <option value="Eighth Judicial District - Renville County District Court">Renville County District Court</option>
    <option value="Eighth Judicial District - Stevens County District Court">Stevens County District Court</option>
    <option value="Eighth Judicial District - Swift County District Court">Swift County District Court</option>
    <option value="Eighth Judicial District - Traverse County District Court">Traverse County District Court</option>
    <option value="Eighth Judicial District - Yellow Medicine County District Court">Yellow Medicine County District Court</option>

    <option value="Ninth Judicial District - Aitkin County District Court">Aitkin County District Court</option>
    <option value="Ninth Judicial District - Beltrami County District Court">Beltrami County District Court</option>
    <option value="Ninth Judicial District - Cass County District Court">Cass County District Court</option>
    <option value="Ninth Judicial District - Clearwater County District Court">Clearwater County District Court</option>
    <option value="Ninth Judicial District - Crow Wing County District Court">Crow Wing County District Court</option>
    <option value="Ninth Judicial District - Hubbard County District Court">Hubbard County District Court</option>
    <option value="Ninth Judicial District - Itasca County District Court">Itasca County District Court</option>
    <option value="Ninth Judicial District - Kittson County District Court">Kittson County District Court</option>
    <option value="Ninth Judicial District - Koochiching County District Court">Koochiching County District Court</option>
    <option value="Ninth Judicial District - Lake of the Woods County District Court">Lake of the Woods County District Court</option>
    <option value="Ninth Judicial District - Mahnomen County District Court">Mahnomen County District Court</option>
    <option value="Ninth Judicial District - Marshall County District Court">Marshall County District Court</option>
    <option value="Ninth Judicial District - Norman County District Court">Norman County District Court</option>
    <option value="Ninth Judicial District - Pennington County District Court">Pennington County District Court</option>
    <option value="Ninth Judicial District - Polk County District Court">Polk County District Court</option>
    <option value="Ninth Judicial District - Red Lake County District Court">Red Lake County District Court</option>
    <option value="Ninth Judicial District - Roseau County District Court">Roseau County District Court</option>

    <option value="Tenth Judicial District - Anoka County District Court">Anoka County District Court</option>
    <option value="Tenth Judicial District - Chisago County District Court">Chisago County District Court</option>
    <option value="Tenth Judicial District - Isanti County District Court">Isanti County District Court</option>
    <option value="Tenth Judicial District - Kanabec County District Court">Kanabec County District Court</option>
    <option value="Tenth Judicial District - Pine County District Court">Pine County District Court</option>
    <option value="Tenth Judicial District - Sherburne County District Court">Sherburne County District Court</option>
    <option value="Tenth Judicial District - Washington County District Court">Washington County District Court</option>
    <option value="Tenth Judicial District - Wright County District Court">Wright County District Court</option>
  </>
)}
{secondValue === 'Mississippi' && (
  <>
    <option value="First Judicial District - Alcorn County Circuit Court">Alcorn County Circuit Court</option>
    <option value="First Judicial District - Itawamba County Circuit Court">Itawamba County Circuit Court</option>
    <option value="First Judicial District - Lee County Circuit Court">Lee County Circuit Court</option>
    <option value="First Judicial District - Monroe County Circuit Court">Monroe County Circuit Court</option>

    <option value="Second Judicial District - Hancock County Circuit Court">Hancock County Circuit Court</option>
    <option value="Second Judicial District - Harrison County Circuit Court">Harrison County Circuit Court</option>
    <option value="Second Judicial District - Stone County Circuit Court">Stone County Circuit Court</option>

    <option value="Third Judicial District - Benton County Circuit Court">Benton County Circuit Court</option>
    <option value="Third Judicial District - Calhoun County Circuit Court">Calhoun County Circuit Court</option>
    <option value="Third Judicial District - Chickasaw County Circuit Court">Chickasaw County Circuit Court</option>
    <option value="Third Judicial District - Lafayette County Circuit Court">Lafayette County Circuit Court</option>
    <option value="Third Judicial District - Marshall County Circuit Court">Marshall County Circuit Court</option>
    <option value="Third Judicial District - Tippah County Circuit Court">Tippah County Circuit Court</option>

    <option value="Fourth Judicial District - Leflore County Circuit Court">Leflore County Circuit Court</option>
    <option value="Fourth Judicial District - Sunflower County Circuit Court">Sunflower County Circuit Court</option>
    <option value="Fourth Judicial District - Washington County Circuit Court">Washington County Circuit Court</option>

    <option value="Fifth Judicial District - Attala County Circuit Court">Attala County Circuit Court</option>
    <option value="Fifth Judicial District - Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Fifth Judicial District - Choctaw County Circuit Court">Choctaw County Circuit Court</option>
    <option value="Fifth Judicial District - Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Fifth Judicial District - Webster County Circuit Court">Webster County Circuit Court</option>
    <option value="Fifth Judicial District - Winston County Circuit Court">Winston County Circuit Court</option>

    <option value="Sixth Judicial District - Adams County Circuit Court">Adams County Circuit Court</option>
    <option value="Sixth Judicial District - Amite County Circuit Court">Amite County Circuit Court</option>
    <option value="Sixth Judicial District - Franklin County Circuit Court">Franklin County Circuit Court</option>
    <option value="Sixth Judicial District - Wilkinson County Circuit Court">Wilkinson County Circuit Court</option>

    <option value="Seventh Judicial District - Hinds County Circuit Court">Hinds County Circuit Court</option>

    <option value="Eighth Judicial District - Leake County Circuit Court">Leake County Circuit Court</option>
    <option value="Eighth Judicial District - Neshoba County Circuit Court">Neshoba County Circuit Court</option>
    <option value="Eighth Judicial District - Newton County Circuit Court">Newton County Circuit Court</option>
    <option value="Eighth Judicial District - Scott County Circuit Court">Scott County Circuit Court</option>

    <option value="Ninth Judicial District - Issaquena County Circuit Court">Issaquena County Circuit Court</option>
    <option value="Ninth Judicial District - Sharkey County Circuit Court">Sharkey County Circuit Court</option>
    <option value="Ninth Judicial District - Warren County Circuit Court">Warren County Circuit Court</option>

    <option value="Tenth Judicial District - Clarke County Circuit Court">Clarke County Circuit Court</option>
    <option value="Tenth Judicial District - Kemper County Circuit Court">Kemper County Circuit Court</option>
    <option value="Tenth Judicial District - Lauderdale County Circuit Court">Lauderdale County Circuit Court</option>
    <option value="Tenth Judicial District - Wayne County Circuit Court">Wayne County Circuit Court</option>

    <option value="Eleventh Judicial District - Bolivar County Circuit Court">Bolivar County Circuit Court</option>
    <option value="Eleventh Judicial District - Coahoma County Circuit Court">Coahoma County Circuit Court</option>
    <option value="Eleventh Judicial District - Quitman County Circuit Court">Quitman County Circuit Court</option>
    <option value="Eleventh Judicial District - Tallahatchie County Circuit Court">Tallahatchie County Circuit Court</option>

    <option value="Twelfth Judicial District - Forrest County Circuit Court">Forrest County Circuit Court</option>
    <option value="Twelfth Judicial District - Perry County Circuit Court">Perry County Circuit Court</option>

    <option value="Thirteenth Judicial District - Covington County Circuit Court">Covington County Circuit Court</option>
    <option value="Thirteenth Judicial District - Jasper County Circuit Court">Jasper County Circuit Court</option>
    <option value="Thirteenth Judicial District - Simpson County Circuit Court">Simpson County Circuit Court</option>
    <option value="Thirteenth Judicial District - Smith County Circuit Court">Smith County Circuit Court</option>

    <option value="Fourteenth Judicial District - Chickasaw County Circuit Court">Chickasaw County Circuit Court</option>
    <option value="Fourteenth Judicial District - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Fourteenth Judicial District - Lowndes County Circuit Court">Lowndes County Circuit Court</option>
    <option value="Fourteenth Judicial District - Oktibbeha County Circuit Court">Oktibbeha County Circuit Court</option>
    <option value="Fourteenth Judicial District - Webster County Circuit Court">Webster County Circuit Court</option>

    <option value="Fifteenth Judicial District - George County Circuit Court">George County Circuit Court</option>
    <option value="Fifteenth Judicial District - Greene County Circuit Court">Greene County Circuit Court</option>
    <option value="Fifteenth Judicial District - Jackson County Circuit Court">Jackson County Circuit Court</option>

    <option value="Sixteenth Judicial District - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Sixteenth Judicial District - Lowndes County Circuit Court">Lowndes County Circuit Court</option>
    <option value="Sixteenth Judicial District - Noxubee County Circuit Court">Noxubee County Circuit Court</option>

    <option value="Seventeenth Judicial District - DeSoto County Circuit Court">DeSoto County Circuit Court</option>
    <option value="Seventeenth Judicial District - Panola County Circuit Court">Panola County Circuit Court</option>
    <option value="Seventeenth Judicial District - Tate County Circuit Court">Tate County Circuit Court</option>
    <option value="Seventeenth Judicial District - Yalobusha County Circuit Court">Yalobusha County Circuit Court</option>

    <option value="Eighteenth Judicial District - Jones County Circuit Court">Jones County Circuit Court</option>

    <option value="Nineteenth Judicial District - Greene County Circuit Court">Greene County Circuit Court</option>
    <option value="Nineteenth Judicial District - Perry County Circuit Court">Perry County Circuit Court</option>

    <option value="Twentieth Judicial District - Rankin County Circuit Court">Rankin County Circuit Court</option>

    <option value="Twenty-First Judicial District - Holmes County Circuit Court">Holmes County Circuit Court</option>
    <option value="Twenty-First Judicial District - Humphreys County Circuit Court">Humphreys County Circuit Court</option>
    <option value="Twenty-First Judicial District - Yazoo County Circuit Court">Yazoo County Circuit Court</option>

    <option value="Twenty-Second Judicial District - Claiborne County Circuit Court">Claiborne County Circuit Court</option>
    <option value="Twenty-Second Judicial District - Copiah County Circuit Court">Copiah County Circuit Court</option>
    <option value="Twenty-Second Judicial District - Jefferson County Circuit Court">Jefferson County Circuit Court</option>
  </>
)}
{secondValue === 'Missouri' && (
  <>
    <option value="First Judicial Circuit - Clark County Circuit Court">Clark County Circuit Court</option>
    <option value="First Judicial Circuit - Schuyler County Circuit Court">Schuyler County Circuit Court</option>
    <option value="First Judicial Circuit - Scotland County Circuit Court">Scotland County Circuit Court</option>
    <option value="Second Judicial Circuit - Adair County Circuit Court">Adair County Circuit Court</option>
    <option value="Second Judicial Circuit - Knox County Circuit Court">Knox County Circuit Court</option>
    <option value="Second Judicial Circuit - Lewis County Circuit Court">Lewis County Circuit Court</option>
    <option value="Third Judicial Circuit - Grundy County Circuit Court">Grundy County Circuit Court</option>
    <option value="Third Judicial Circuit - Harrison County Circuit Court">Harrison County Circuit Court</option>
    <option value="Third Judicial Circuit - Mercer County Circuit Court">Mercer County Circuit Court</option>
    <option value="Third Judicial Circuit - Putnam County Circuit Court">Putnam County Circuit Court</option>
    <option value="Third Judicial Circuit - Sullivan County Circuit Court">Sullivan County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Atchison County Circuit Court">Atchison County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Gentry County Circuit Court">Gentry County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Holt County Circuit Court">Holt County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Nodaway County Circuit Court">Nodaway County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Andrew County Circuit Court">Andrew County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Buchanan County Circuit Court">Buchanan County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Platte County Circuit Court">Platte County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Ray County Circuit Court">Ray County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Chariton County Circuit Court">Chariton County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Linn County Circuit Court">Linn County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Marion County Circuit Court">Marion County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Ralls County Circuit Court">Ralls County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - St. Charles County Circuit Court">St. Charles County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Audrain County Circuit Court">Audrain County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Warren County Circuit Court">Warren County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - Boone County Circuit Court">Boone County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - Callaway County Circuit Court">Callaway County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Howard County Circuit Court">Howard County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Randolph County Circuit Court">Randolph County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Lafayette County Circuit Court">Lafayette County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Saline County Circuit Court">Saline County Circuit Court</option>
    <option value="Sixteenth Judicial Circuit - Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Seventeenth Judicial Circuit - Cass County Circuit Court">Cass County Circuit Court</option>
    <option value="Seventeenth Judicial Circuit - Johnson County Circuit Court">Johnson County Circuit Court</option>
    <option value="Eighteenth Judicial Circuit - Cooper County Circuit Court">Cooper County Circuit Court</option>
    <option value="Eighteenth Judicial Circuit - Pettis County Circuit Court">Pettis County Circuit Court</option>
    <option value="Nineteenth Judicial Circuit - Cole County Circuit Court">Cole County Circuit Court</option>
    <option value="Twentieth Judicial Circuit - Franklin County Circuit Court">Franklin County Circuit Court</option>
    <option value="Twenty-First Judicial Circuit - St. Louis County Circuit Court">St. Louis County Circuit Court</option>
    <option value="Twenty-Second Judicial Circuit - City of St. Louis Circuit Court">City of St. Louis Circuit Court</option>
    <option value="Twenty-Third Judicial Circuit - Jefferson County Circuit Court">Jefferson County Circuit Court</option>
    <option value="Twenty-Fourth Judicial Circuit - Madison County Circuit Court">Madison County Circuit Court</option>
    <option value="Twenty-Fourth Judicial Circuit - Ste. Genevieve County Circuit Court">Ste. Genevieve County Circuit Court</option>
    <option value="Twenty-Fourth Judicial Circuit - St. Francois County Circuit Court">St. Francois County Circuit Court</option>
    <option value="Twenty-Fifth Judicial Circuit - Maries County Circuit Court">Maries County Circuit Court</option>
    <option value="Twenty-Fifth Judicial Circuit - Phelps County Circuit Court">Phelps County Circuit Court</option>
    <option value="Twenty-Fifth Judicial Circuit - Pulaski County Circuit Court">Pulaski County Circuit Court</option>
    <option value="Twenty-Fifth Judicial Circuit - Texas County Circuit Court">Texas County Circuit Court</option>
    <option value="Twenty-Sixth Judicial Circuit - Camden County Circuit Court">Camden County Circuit Court</option>
    <option value="Twenty-Sixth Judicial Circuit - Laclede County Circuit Court">Laclede County Circuit Court</option>
    <option value="Twenty-Sixth Judicial Circuit - Miller County Circuit Court">Miller County Circuit Court</option>
    <option value="Twenty-Sixth Judicial Circuit - Moniteau County Circuit Court">Moniteau County Circuit Court</option>
    <option value="Twenty-Sixth Judicial Circuit - Morgan County Circuit Court">Morgan County Circuit Court</option>
    <option value="Twenty-Seventh Judicial Circuit - Bates County Circuit Court">Bates County Circuit Court</option>
    <option value="Twenty-Seventh Judicial Circuit - Henry County Circuit Court">Henry County Circuit Court</option>
    <option value="Twenty-Seventh Judicial Circuit - St. Clair County Circuit Court">St. Clair County Circuit Court</option>
    <option value="Twenty-Eighth Judicial Circuit - Barton County Circuit Court">Barton County Circuit Court</option>
    <option value="Twenty-Eighth Judicial Circuit - Cedar County Circuit Court">Cedar County Circuit Court</option>
    <option value="Twenty-Eighth Judicial Circuit - Dade County Circuit Court">Dade County Circuit Court</option>
    <option value="Twenty-Eighth Judicial Circuit - Vernon County Circuit Court">Vernon County Circuit Court</option>
    <option value="Twenty-Ninth Judicial Circuit - Jasper County Circuit Court">Jasper County Circuit Court</option>
    <option value="Thirtieth Judicial Circuit - Benton County Circuit Court">Benton County Circuit Court</option>
    <option value="Thirtieth Judicial Circuit - Dallas County Circuit Court">Dallas County Circuit Court</option>
    <option value="Thirtieth Judicial Circuit - Hickory County Circuit Court">Hickory County Circuit Court</option>
    <option value="Thirtieth Judicial Circuit - Polk County Circuit Court">Polk County Circuit Court</option>
    <option value="Thirty-First Judicial Circuit - Greene County Circuit Court">Greene County Circuit Court</option>
    <option value="Thirty-Second Judicial Circuit - Bollinger County Circuit Court">Bollinger County Circuit Court</option>
    <option value="Thirty-Second Judicial Circuit - Cape Girardeau County Circuit Court">Cape Girardeau County Circuit Court</option>
    <option value="Thirty-Second Judicial Circuit - Perry County Circuit Court">Perry County Circuit Court</option>
    <option value="Thirty-Third Judicial Circuit - Mississippi County Circuit Court">Mississippi County Circuit Court</option>
    <option value="Thirty-Third Judicial Circuit - Scott County Circuit Court">Scott County Circuit Court</option>
    <option value="Thirty-Fourth Judicial Circuit - New Madrid County Circuit Court">New Madrid County Circuit Court</option>
    <option value="Thirty-Fourth Judicial Circuit - Pemiscot County Circuit Court">Pemiscot County Circuit Court</option>
    <option value="Thirty-Fifth Judicial Circuit - Dunklin County Circuit Court">Dunklin County Circuit Court</option>
    <option value="Thirty-Fifth Judicial Circuit - Stoddard County Circuit Court">Stoddard County Circuit Court</option>
    <option value="Thirty-Sixth Judicial Circuit - Butler County Circuit Court">Butler County Circuit Court</option>
    <option value="Thirty-Seventh Judicial Circuit - Carter County Circuit Court">Carter County Circuit Court</option>
    <option value="Thirty-Seventh Judicial Circuit - Ripley County Circuit Court">Ripley County Circuit Court</option>
    <option value="Thirty-Eighth Judicial Circuit - Christian County Circuit Court">Christian County Circuit Court</option>
    <option value="Thirty-Eighth Judicial Circuit - Taney County Circuit Court">Taney County Circuit Court</option>
    <option value="Thirty-Ninth Judicial Circuit - Barry County Circuit Court">Barry County Circuit Court</option>
    <option value="Thirty-Ninth Judicial Circuit - Stone County Circuit Court">Stone County Circuit Court</option>
    <option value="Fortieth Judicial Circuit - Shannon County Circuit Court">Shannon County Circuit Court</option>
    <option value="Fortieth Judicial Circuit - Oregon County Circuit Court">Oregon County Circuit Court</option>
    <option value="Fortieth Judicial Circuit - Reynolds County Circuit Court">Reynolds County Circuit Court</option>
    <option value="Forty-First Judicial Circuit - Macon County Circuit Court">Macon County Circuit Court</option>
    <option value="Forty-First Judicial Circuit - Shelby County Circuit Court">Shelby County Circuit Court</option>
    <option value="Forty-Second Judicial Circuit - Douglas County Circuit Court">Douglas County Circuit Court</option>
    <option value="Forty-Second Judicial Circuit - Ozark County Circuit Court">Ozark County Circuit Court</option>
    <option value="Forty-Second Judicial Circuit - Wright County Circuit Court">Wright County Circuit Court</option>
    <option value="Forty-Third Judicial Circuit - Clinton County Circuit Court">Clinton County Circuit Court</option>
    <option value="Forty-Third Judicial Circuit - DeKalb County Circuit Court">DeKalb County Circuit Court</option>
    <option value="Forty-Fourth Judicial Circuit - Douglas County Circuit Court">Douglas County Circuit Court</option>
    <option value="Forty-Fourth Judicial Circuit - Ozark County Circuit Court">Ozark County Circuit Court</option>
    <option value="Forty-Fourth Judicial Circuit - Wright County Circuit Court">Wright County Circuit Court</option>
    <option value="Forty-Fifth Judicial Circuit - Lincoln County Circuit Court">Lincoln County Circuit Court</option>
    <option value="Forty-Sixth Judicial Circuit - Taney County Circuit Court">Taney County Circuit Court</option>
    <option value="Forty-Sixth Judicial Circuit - Ozark County Circuit Court">Ozark County Circuit Court</option>
  </>
)}
{secondValue === 'Montana' && (
  <>
    <option value="First Judicial District - Broadwater County District Court">Broadwater County District Court</option>
    <option value="First Judicial District - Lewis and Clark County District Court">Lewis and Clark County District Court</option>

    <option value="Second Judicial District - Silver Bow County District Court">Silver Bow County District Court</option>

    <option value="Third Judicial District - Deer Lodge County District Court">Deer Lodge County District Court</option>
    <option value="Third Judicial District - Granite County District Court">Granite County District Court</option>

    <option value="Fourth Judicial District - Mineral County District Court">Mineral County District Court</option>
    <option value="Fourth Judicial District - Missoula County District Court">Missoula County District Court</option>

    <option value="Fifth Judicial District - Beaverhead County District Court">Beaverhead County District Court</option>
    <option value="Fifth Judicial District - Jefferson County District Court">Jefferson County District Court</option>
    <option value="Fifth Judicial District - Madison County District Court">Madison County District Court</option>

    <option value="Sixth Judicial District - Park County District Court">Park County District Court</option>
    <option value="Sixth Judicial District - Sweet Grass County District Court">Sweet Grass County District Court</option>

    <option value="Seventh Judicial District - Dawson County District Court">Dawson County District Court</option>
    <option value="Seventh Judicial District - McCone County District Court">McCone County District Court</option>
    <option value="Seventh Judicial District - Prairie County District Court">Prairie County District Court</option>
    <option value="Seventh Judicial District - Richland County District Court">Richland County District Court</option>
    <option value="Seventh Judicial District - Wibaux County District Court">Wibaux County District Court</option>

    <option value="Eighth Judicial District - Cascade County District Court">Cascade County District Court</option>

    <option value="Ninth Judicial District - Glacier County District Court">Glacier County District Court</option>
    <option value="Ninth Judicial District - Pondera County District Court">Pondera County District Court</option>
    <option value="Ninth Judicial District - Teton County District Court">Teton County District Court</option>
    <option value="Ninth Judicial District - Toole County District Court">Toole County District Court</option>

    <option value="Tenth Judicial District - Fergus County District Court">Fergus County District Court</option>
    <option value="Tenth Judicial District - Judith Basin County District Court">Judith Basin County District Court</option>
    <option value="Tenth Judicial District - Petroleum County District Court">Petroleum County District Court</option>

    <option value="Eleventh Judicial District - Flathead County District Court">Flathead County District Court</option>

    <option value="Twelfth Judicial District - Chouteau County District Court">Chouteau County District Court</option>
    <option value="Twelfth Judicial District - Hill County District Court">Hill County District Court</option>
    <option value="Twelfth Judicial District - Liberty County District Court">Liberty County District Court</option>

    <option value="Thirteenth Judicial District - Yellowstone County District Court">Yellowstone County District Court</option>

    <option value="Fourteenth Judicial District - Golden Valley County District Court">Golden Valley County District Court</option>
    <option value="Fourteenth Judicial District - Meagher County District Court">Meagher County District Court</option>
    <option value="Fourteenth Judicial District - Musselshell County District Court">Musselshell County District Court</option>
    <option value="Fourteenth Judicial District - Wheatland County District Court">Wheatland County District Court</option>

    <option value="Fifteenth Judicial District - Daniels County District Court">Daniels County District Court</option>
    <option value="Fifteenth Judicial District - Roosevelt County District Court">Roosevelt County District Court</option>
    <option value="Fifteenth Judicial District - Sheridan County District Court">Sheridan County District Court</option>

    <option value="Sixteenth Judicial District - Carter County District Court">Carter County District Court</option>
    <option value="Sixteenth Judicial District - Custer County District Court">Custer County District Court</option>
    <option value="Sixteenth Judicial District - Fallon County District Court">Fallon County District Court</option>
    <option value="Sixteenth Judicial District - Garfield County District Court">Garfield County District Court</option>
    <option value="Sixteenth Judicial District - Powder River County District Court">Powder River County District Court</option>
    <option value="Sixteenth Judicial District - Rosebud County District Court">Rosebud County District Court</option>
    <option value="Sixteenth Judicial District - Treasure County District Court">Treasure County District Court</option>

    <option value="Seventeenth Judicial District - Blaine County District Court">Blaine County District Court</option>
    <option value="Seventeenth Judicial District - Phillips County District Court">Phillips County District Court</option>

    <option value="Eighteenth Judicial District - Gallatin County District Court">Gallatin County District Court</option>

    <option value="Nineteenth Judicial District - Lincoln County District Court">Lincoln County District Court</option>

    <option value="Twentieth Judicial District - Lake County District Court">Lake County District Court</option>

    <option value="Twenty-First Judicial District - Ravalli County District Court">Ravalli County District Court</option>

    <option value="Twenty-Second Judicial District - Big Horn County District Court">Big Horn County District Court</option>
    <option value="Twenty-Second Judicial District - Carbon County District Court">Carbon County District Court</option>
  </>
)}
{secondValue === 'Nebraska' && (
  <>
    <option value="First Judicial District - Gage County District Court">Gage County District Court</option>
    <option value="First Judicial District - Jefferson County District Court">Jefferson County District Court</option>
    <option value="First Judicial District - Johnson County District Court">Johnson County District Court</option>
    <option value="First Judicial District - Nemaha County District Court">Nemaha County District Court</option>
    <option value="First Judicial District - Pawnee County District Court">Pawnee County District Court</option>
    <option value="First Judicial District - Richardson County District Court">Richardson County District Court</option>
    <option value="First Judicial District - Saline County District Court">Saline County District Court</option>
    <option value="First Judicial District - Thayer County District Court">Thayer County District Court</option>

    <option value="Second Judicial District - Cass County District Court">Cass County District Court</option>
    <option value="Second Judicial District - Otoe County District Court">Otoe County District Court</option>
    <option value="Second Judicial District - Sarpy County District Court">Sarpy County District Court</option>

    <option value="Third Judicial District - Lancaster County District Court">Lancaster County District Court</option>

    <option value="Fourth Judicial District - Douglas County District Court">Douglas County District Court</option>

    <option value="Fifth Judicial District - Boone County District Court">Boone County District Court</option>
    <option value="Fifth Judicial District - Butler County District Court">Butler County District Court</option>
    <option value="Fifth Judicial District - Colfax County District Court">Colfax County District Court</option>
    <option value="Fifth Judicial District - Hamilton County District Court">Hamilton County District Court</option>
    <option value="Fifth Judicial District - Merrick County District Court">Merrick County District Court</option>
    <option value="Fifth Judicial District - Nance County District Court">Nance County District Court</option>
    <option value="Fifth Judicial District - Platte County District Court">Platte County District Court</option>
    <option value="Fifth Judicial District - Polk County District Court">Polk County District Court</option>
    <option value="Fifth Judicial District - Saunders County District Court">Saunders County District Court</option>
    <option value="Fifth Judicial District - Seward County District Court">Seward County District Court</option>
    <option value="Fifth Judicial District - York County District Court">York County District Court</option>

    <option value="Sixth Judicial District - Burt County District Court">Burt County District Court</option>
    <option value="Sixth Judicial District - Cedar County District Court">Cedar County District Court</option>
    <option value="Sixth Judicial District - Dakota County District Court">Dakota County District Court</option>
    <option value="Sixth Judicial District - Dixon County District Court">Dixon County District Court</option>
    <option value="Sixth Judicial District - Dodge County District Court">Dodge County District Court</option>
    <option value="Sixth Judicial District - Thurston County District Court">Thurston County District Court</option>
    <option value="Sixth Judicial District - Washington County District Court">Washington County District Court</option>

    <option value="Seventh Judicial District - Antelope County District Court">Antelope County District Court</option>
    <option value="Seventh Judicial District - Cuming County District Court">Cuming County District Court</option>
    <option value="Seventh Judicial District - Knox County District Court">Knox County District Court</option>
    <option value="Seventh Judicial District - Madison County District Court">Madison County District Court</option>
    <option value="Seventh Judicial District - Pierce County District Court">Pierce County District Court</option>
    <option value="Seventh Judicial District - Stanton County District Court">Stanton County District Court</option>
    <option value="Seventh Judicial District - Wayne County District Court">Wayne County District Court</option>

    <option value="Eighth Judicial District - Blaine County District Court">Blaine County District Court</option>
    <option value="Eighth Judicial District - Boyd County District Court">Boyd County District Court</option>
    <option value="Eighth Judicial District - Brown County District Court">Brown County District Court</option>
    <option value="Eighth Judicial District - Cherry County District Court">Cherry County District Court</option>
    <option value="Eighth Judicial District - Custer County District Court">Custer County District Court</option>
    <option value="Eighth Judicial District - Garfield County District Court">Garfield County District Court</option>
    <option value="Eighth Judicial District - Holt County District Court">Holt County District Court</option>
    <option value="Eighth Judicial District - Hooker County District Court">Hooker County District Court</option>
    <option value="Eighth Judicial District - Keya Paha County District Court">Keya Paha County District Court</option>
    <option value="Eighth Judicial District - Loup County District Court">Loup County District Court</option>
    <option value="Eighth Judicial District - Rock County District Court">Rock County District Court</option>
    <option value="Eighth Judicial District - Thomas County District Court">Thomas County District Court</option>
    <option value="Eighth Judicial District - Valley County District Court">Valley County District Court</option>
    <option value="Eighth Judicial District - Wheeler County District Court">Wheeler County District Court</option>

    <option value="Ninth Judicial District - Buffalo County District Court">Buffalo County District Court</option>
    <option value="Ninth Judicial District - Hall County District Court">Hall County District Court</option>

    <option value="Tenth Judicial District - Adams County District Court">Adams County District Court</option>
    <option value="Tenth Judicial District - Clay County District Court">Clay County District Court</option>
    <option value="Tenth Judicial District - Franklin County District Court">Franklin County District Court</option>
    <option value="Tenth Judicial District - Harlan County District Court">Harlan County District Court</option>
    <option value="Tenth Judicial District - Kearney County District Court">Kearney County District Court</option>
    <option value="Tenth Judicial District - Nuckolls County District Court">Nuckolls County District Court</option>
    <option value="Tenth Judicial District - Phelps County District Court">Phelps County District Court</option>
    <option value="Tenth Judicial District - Webster County District Court">Webster County District Court</option>

    <option value="Eleventh Judicial District - Arthur County District Court">Arthur County District Court</option>
    <option value="Eleventh Judicial District - Chase County District Court">Chase County District Court</option>
    <option value="Eleventh Judicial District - Dawson County District Court">Dawson County District Court</option>
    <option value="Eleventh Judicial District - Dundy County District Court">Dundy County District Court</option>
    <option value="Eleventh Judicial District - Frontier County District Court">Frontier County District Court</option>
    <option value="Eleventh Judicial District - Furnas County District Court">Furnas County District Court</option>
    <option value="Eleventh Judicial District - Gosper County District Court">Gosper County District Court</option>
    <option value="Eleventh Judicial District - Hayes County District Court">Hayes County District Court</option>
    <option value="Eleventh Judicial District - Hitchcock County District Court">Hitchcock County District Court</option>
    <option value="Eleventh Judicial District - Keith County District Court">Keith County District Court</option>
    <option value="Eleventh Judicial District - Lincoln County District Court">Lincoln County District Court</option>
    <option value="Eleventh Judicial District - Logan County District Court">Logan County District Court</option>
    <option value="Eleventh Judicial District - McPherson County District Court">McPherson County District Court</option>
    <option value="Eleventh Judicial District - Perkins County District Court">Perkins County District Court</option>
    <option value="Eleventh Judicial District - Red Willow County District Court">Red Willow County District Court</option>

    <option value="Twelfth Judicial District - Banner County District Court">Banner County District Court</option>
    <option value="Twelfth Judicial District - Box Butte County District Court">Box Butte County District Court</option>
    <option value="Twelfth Judicial District - Cheyenne County District Court">Cheyenne County District Court</option>
    <option value="Twelfth Judicial District - Dawes County District Court">Dawes County District Court</option>
    <option value="Twelfth Judicial District - Deuel County District Court">Deuel County District Court</option>
    <option value="Twelfth Judicial District - Garden County District Court">Garden County District Court</option>
    <option value="Twelfth Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="Twelfth Judicial District - Kimball County District Court">Kimball County District Court</option>
    <option value="Twelfth Judicial District - Morrill County District Court">Morrill County District Court</option>
    <option value="Twelfth Judicial District - Scotts Bluff County District Court">Scotts Bluff County District Court</option>
    <option value="Twelfth Judicial District - Sheridan County District Court">Sheridan County District Court</option>
    <option value="Twelfth Judicial District - Sioux County District Court">Sioux County District Court</option>
  </>
)}
{secondValue === 'Nevada' && (
  <>
    <option value="First Judicial District - Carson City District Court">Carson City District Court</option>
    <option value="First Judicial District - Storey County District Court">Storey County District Court</option>
    <option value="Second Judicial District - Washoe County District Court">Washoe County District Court</option>
    <option value="Third Judicial District - Lyon County District Court">Lyon County District Court</option>
    <option value="Fourth Judicial District - Elko County District Court">Elko County District Court</option>
    <option value="Fifth Judicial District - Esmeralda County District Court">Esmeralda County District Court</option>
    <option value="Fifth Judicial District - Mineral County District Court">Mineral County District Court</option>
    <option value="Fifth Judicial District - Nye County District Court">Nye County District Court</option>
    <option value="Sixth Judicial District - Humboldt County District Court">Humboldt County District Court</option>
    <option value="Seventh Judicial District - Eureka County District Court">Eureka County District Court</option>
    <option value="Seventh Judicial District - Lincoln County District Court">Lincoln County District Court</option>
    <option value="Seventh Judicial District - White Pine County District Court">White Pine County District Court</option>
    <option value="Eighth Judicial District - Clark County District Court">Clark County District Court</option>
    <option value="Ninth Judicial District - Douglas County District Court">Douglas County District Court</option>
    <option value="Tenth Judicial District - Churchill County District Court">Churchill County District Court</option>
    <option value="Eleventh Judicial District - Lander County District Court">Lander County District Court</option>
    <option value="Eleventh Judicial District - Pershing County District Court">Pershing County District Court</option>
  </>
)}
{secondValue === 'New Hampshire' && (
  <>
    <option value="Belknap County Superior Court">Belknap County Superior Court</option>
    <option value="Carroll County Superior Court">Carroll County Superior Court</option>
    <option value="Cheshire County Superior Court">Cheshire County Superior Court</option>
    <option value="Coos County Superior Court">Coos County Superior Court</option>
    <option value="Grafton County Superior Court">Grafton County Superior Court</option>
    <option value="Hillsborough County Superior Court North">Hillsborough County Superior Court North</option>
    <option value="Hillsborough County Superior Court South">Hillsborough County Superior Court South</option>
    <option value="Merrimack County Superior Court">Merrimack County Superior Court</option>
    <option value="Rockingham County Superior Court">Rockingham County Superior Court</option>
    <option value="Strafford County Superior Court">Strafford County Superior Court</option>
    <option value="Sullivan County Superior Court">Sullivan County Superior Court</option>
  </>
)}
{secondValue === 'New Jersey' && (
  <>
    <option value="Atlantic County Superior Court">Atlantic County Superior Court</option>
    <option value="Bergen County Superior Court">Bergen County Superior Court</option>
    <option value="Burlington County Superior Court">Burlington County Superior Court</option>
    <option value="Camden County Superior Court">Camden County Superior Court</option>
    <option value="Cape May County Superior Court">Cape May County Superior Court</option>
    <option value="Cumberland County Superior Court">Cumberland County Superior Court</option>
    <option value="Essex County Superior Court">Essex County Superior Court</option>
    <option value="Gloucester County Superior Court">Gloucester County Superior Court</option>
    <option value="Hudson County Superior Court">Hudson County Superior Court</option>
    <option value="Hunterdon County Superior Court">Hunterdon County Superior Court</option>
    <option value="Mercer County Superior Court">Mercer County Superior Court</option>
    <option value="Middlesex County Superior Court">Middlesex County Superior Court</option>
    <option value="Monmouth County Superior Court">Monmouth County Superior Court</option>
    <option value="Morris County Superior Court">Morris County Superior Court</option>
    <option value="Ocean County Superior Court">Ocean County Superior Court</option>
    <option value="Passaic County Superior Court">Passaic County Superior Court</option>
    <option value="Salem County Superior Court">Salem County Superior Court</option>
    <option value="Somerset County Superior Court">Somerset County Superior Court</option>
    <option value="Sussex County Superior Court">Sussex County Superior Court</option>
    <option value="Union County Superior Court">Union County Superior Court</option>
    <option value="Warren County Superior Court">Warren County Superior Court</option>
  </>
)}
{secondValue === 'New Mexico' && (
  <>
    <option value="First Judicial District - Los Alamos County District Court">Los Alamos County District Court</option>
    <option value="First Judicial District - Rio Arriba County District Court">Rio Arriba County District Court</option>
    <option value="First Judicial District - Santa Fe County District Court">Santa Fe County District Court</option>

    <option value="Second Judicial District - Bernalillo County District Court">Bernalillo County District Court</option>

    <option value="Third Judicial District - Doña Ana County District Court">Doña Ana County District Court</option>

    <option value="Fourth Judicial District - Guadalupe County District Court">Guadalupe County District Court</option>
    <option value="Fourth Judicial District - Mora County District Court">Mora County District Court</option>
    <option value="Fourth Judicial District - San Miguel County District Court">San Miguel County District Court</option>

    <option value="Fifth Judicial District - Chaves County District Court">Chaves County District Court</option>
    <option value="Fifth Judicial District - Eddy County District Court">Eddy County District Court</option>
    <option value="Fifth Judicial District - Lea County District Court">Lea County District Court</option>

    <option value="Sixth Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="Sixth Judicial District - Hidalgo County District Court">Hidalgo County District Court</option>
    <option value="Sixth Judicial District - Luna County District Court">Luna County District Court</option>

    <option value="Seventh Judicial District - Catron County District Court">Catron County District Court</option>
    <option value="Seventh Judicial District - Sierra County District Court">Sierra County District Court</option>
    <option value="Seventh Judicial District - Socorro County District Court">Socorro County District Court</option>
    <option value="Seventh Judicial District - Torrance County District Court">Torrance County District Court</option>

    <option value="Eighth Judicial District - Colfax County District Court">Colfax County District Court</option>
    <option value="Eighth Judicial District - Taos County District Court">Taos County District Court</option>
    <option value="Eighth Judicial District - Union County District Court">Union County District Court</option>

    <option value="Ninth Judicial District - Curry County District Court">Curry County District Court</option>
    <option value="Ninth Judicial District - Roosevelt County District Court">Roosevelt County District Court</option>

    <option value="Tenth Judicial District - De Baca County District Court">De Baca County District Court</option>
    <option value="Tenth Judicial District - Harding County District Court">Harding County District Court</option>
    <option value="Tenth Judicial District - Quay County District Court">Quay County District Court</option>

    <option value="Eleventh Judicial District - McKinley County District Court">McKinley County District Court</option>
    <option value="Eleventh Judicial District - San Juan County District Court">San Juan County District Court</option>

    <option value="Twelfth Judicial District - Lincoln County District Court">Lincoln County District Court</option>
    <option value="Twelfth Judicial District - Otero County District Court">Otero County District Court</option>

    <option value="Thirteenth Judicial District - Cibola County District Court">Cibola County District Court</option>
    <option value="Thirteenth Judicial District - Sandoval County District Court">Sandoval County District Court</option>
    <option value="Thirteenth Judicial District - Valencia County District Court">Valencia County District Court</option>
  </>
)}
{secondValue === 'New York' && (
  <>
    <option value="First Judicial District - New York County Supreme Court">New York County Supreme Court</option>

    <option value="Second Judicial District - Kings County Supreme Court">Kings County Supreme Court</option>

    <option value="Third Judicial District - Albany County Supreme Court">Albany County Supreme Court</option>
    <option value="Third Judicial District - Columbia County Supreme Court">Columbia County Supreme Court</option>
    <option value="Third Judicial District - Greene County Supreme Court">Greene County Supreme Court</option>
    <option value="Third Judicial District - Rensselaer County Supreme Court">Rensselaer County Supreme Court</option>
    <option value="Third Judicial District - Schoharie County Supreme Court">Schoharie County Supreme Court</option>
    <option value="Third Judicial District - Sullivan County Supreme Court">Sullivan County Supreme Court</option>
    <option value="Third Judicial District - Ulster County Supreme Court">Ulster County Supreme Court</option>

    <option value="Fourth Judicial District - Clinton County Supreme Court">Clinton County Supreme Court</option>
    <option value="Fourth Judicial District - Essex County Supreme Court">Essex County Supreme Court</option>
    <option value="Fourth Judicial District - Franklin County Supreme Court">Franklin County Supreme Court</option>
    <option value="Fourth Judicial District - Fulton County Supreme Court">Fulton County Supreme Court</option>
    <option value="Fourth Judicial District - Hamilton County Supreme Court">Hamilton County Supreme Court</option>
    <option value="Fourth Judicial District - Montgomery County Supreme Court">Montgomery County Supreme Court</option>
    <option value="Fourth Judicial District - Saratoga County Supreme Court">Saratoga County Supreme Court</option>
    <option value="Fourth Judicial District - Schenectady County Supreme Court">Schenectady County Supreme Court</option>
    <option value="Fourth Judicial District - St. Lawrence County Supreme Court">St. Lawrence County Supreme Court</option>
    <option value="Fourth Judicial District - Warren County Supreme Court">Warren County Supreme Court</option>
    <option value="Fourth Judicial District - Washington County Supreme Court">Washington County Supreme Court</option>

    <option value="Fifth Judicial District - Herkimer County Supreme Court">Herkimer County Supreme Court</option>
    <option value="Fifth Judicial District - Jefferson County Supreme Court">Jefferson County Supreme Court</option>
    <option value="Fifth Judicial District - Lewis County Supreme Court">Lewis County Supreme Court</option>
    <option value="Fifth Judicial District - Oneida County Supreme Court">Oneida County Supreme Court</option>
    <option value="Fifth Judicial District - Onondaga County Supreme Court">Onondaga County Supreme Court</option>
    <option value="Fifth Judicial District - Oswego County Supreme Court">Oswego County Supreme Court</option>

    <option value="Sixth Judicial District - Broome County Supreme Court">Broome County Supreme Court</option>
    <option value="Sixth Judicial District - Chemung County Supreme Court">Chemung County Supreme Court</option>
    <option value="Sixth Judicial District - Chenango County Supreme Court">Chenango County Supreme Court</option>
    <option value="Sixth Judicial District - Cortland County Supreme Court">Cortland County Supreme Court</option>
    <option value="Sixth Judicial District - Delaware County Supreme Court">Delaware County Supreme Court</option>
    <option value="Sixth Judicial District - Madison County Supreme Court">Madison County Supreme Court</option>
    <option value="Sixth Judicial District - Otsego County Supreme Court">Otsego County Supreme Court</option>
    <option value="Sixth Judicial District - Schuyler County Supreme Court">Schuyler County Supreme Court</option>
    <option value="Sixth Judicial District - Tioga County Supreme Court">Tioga County Supreme Court</option>
    <option value="Sixth Judicial District - Tompkins County Supreme Court">Tompkins County Supreme Court</option>

    <option value="Seventh Judicial District - Cayuga County Supreme Court">Cayuga County Supreme Court</option>
    <option value="Seventh Judicial District - Livingston County Supreme Court">Livingston County Supreme Court</option>
    <option value="Seventh Judicial District - Monroe County Supreme Court">Monroe County Supreme Court</option>
    <option value="Seventh Judicial District - Ontario County Supreme Court">Ontario County Supreme Court</option>
    <option value="Seventh Judicial District - Seneca County Supreme Court">Seneca County Supreme Court</option>
    <option value="Seventh Judicial District - Steuben County Supreme Court">Steuben County Supreme Court</option>
    <option value="Seventh Judicial District - Wayne County Supreme Court">Wayne County Supreme Court</option>
    <option value="Seventh Judicial District - Yates County Supreme Court">Yates County Supreme Court</option>

    <option value="Eighth Judicial District - Allegany County Supreme Court">Allegany County Supreme Court</option>
    <option value="Eighth Judicial District - Cattaraugus County Supreme Court">Cattaraugus County Supreme Court</option>
    <option value="Eighth Judicial District - Chautauqua County Supreme Court">Chautauqua County Supreme Court</option>
    <option value="Eighth Judicial District - Erie County Supreme Court">Erie County Supreme Court</option>
    <option value="Eighth Judicial District - Genesee County Supreme Court">Genesee County Supreme Court</option>
    <option value="Eighth Judicial District - Niagara County Supreme Court">Niagara County Supreme Court</option>
    <option value="Eighth Judicial District - Orleans County Supreme Court">Orleans County Supreme Court</option>
    <option value="Eighth Judicial District - Wyoming County Supreme Court">Wyoming County Supreme Court</option>

    <option value="Ninth Judicial District - Dutchess County Supreme Court">Dutchess County Supreme Court</option>
    <option value="Ninth Judicial District - Orange County Supreme Court">Orange County Supreme Court</option>
    <option value="Ninth Judicial District - Putnam County Supreme Court">Putnam County Supreme Court</option>
    <option value="Ninth Judicial District - Rockland County Supreme Court">Rockland County Supreme Court</option>
    <option value="Ninth Judicial District - Westchester County Supreme Court">Westchester County Supreme Court</option>

    <option value="Tenth Judicial District - Nassau County Supreme Court">Nassau County Supreme Court</option>
    <option value="Tenth Judicial District - Suffolk County Supreme Court">Suffolk County Supreme Court</option>

    <option value="Eleventh Judicial District - Queens County Supreme Court">Queens County Supreme Court</option>

    <option value="Twelfth Judicial District - Bronx County Supreme Court">Bronx County Supreme Court</option>

    <option value="Thirteenth Judicial District - Richmond County Supreme Court">Richmond County Supreme Court</option>
  </>
)}
{secondValue === 'North Carolina' && (
  <>
    {/* First Judicial District */}
    <option value="First Judicial District - Camden County Superior Court">Camden County Superior Court</option>
    <option value="First Judicial District - Chowan County Superior Court">Chowan County Superior Court</option>
    <option value="First Judicial District - Currituck County Superior Court">Currituck County Superior Court</option>
    <option value="First Judicial District - Dare County Superior Court">Dare County Superior Court</option>
    <option value="First Judicial District - Gates County Superior Court">Gates County Superior Court</option>
    <option value="First Judicial District - Pasquotank County Superior Court">Pasquotank County Superior Court</option>
    <option value="First Judicial District - Perquimans County Superior Court">Perquimans County Superior Court</option>

    {/* Second Judicial District */}
    <option value="Second Judicial District - Beaufort County Superior Court">Beaufort County Superior Court</option>
    <option value="Second Judicial District - Hyde County Superior Court">Hyde County Superior Court</option>
    <option value="Second Judicial District - Martin County Superior Court">Martin County Superior Court</option>
    <option value="Second Judicial District - Tyrrell County Superior Court">Tyrrell County Superior Court</option>
    <option value="Second Judicial District - Washington County Superior Court">Washington County Superior Court</option>

    {/* Third Judicial District */}
    <option value="Third Judicial District - Carteret County Superior Court">Carteret County Superior Court</option>
    <option value="Third Judicial District - Craven County Superior Court">Craven County Superior Court</option>
    <option value="Third Judicial District - Pamlico County Superior Court">Pamlico County Superior Court</option>

    {/* Fourth Judicial District */}
    <option value="Fourth Judicial District - Duplin County Superior Court">Duplin County Superior Court</option>
    <option value="Fourth Judicial District - Jones County Superior Court">Jones County Superior Court</option>
    <option value="Fourth Judicial District - Onslow County Superior Court">Onslow County Superior Court</option>
    <option value="Fourth Judicial District - Sampson County Superior Court">Sampson County Superior Court</option>

    {/* Fifth Judicial District */}
    <option value="Fifth Judicial District - New Hanover County Superior Court">New Hanover County Superior Court</option>
    <option value="Fifth Judicial District - Pender County Superior Court">Pender County Superior Court</option>

    {/* Sixth Judicial District */}
    <option value="Sixth Judicial District - Bertie County Superior Court">Bertie County Superior Court</option>
    <option value="Sixth Judicial District - Halifax County Superior Court">Halifax County Superior Court</option>
    <option value="Sixth Judicial District - Hertford County Superior Court">Hertford County Superior Court</option>
    <option value="Sixth Judicial District - Northampton County Superior Court">Northampton County Superior Court</option>

    {/* Seventh Judicial District */}
    <option value="Seventh Judicial District - Edgecombe County Superior Court">Edgecombe County Superior Court</option>
    <option value="Seventh Judicial District - Nash County Superior Court">Nash County Superior Court</option>
    <option value="Seventh Judicial District - Wilson County Superior Court">Wilson County Superior Court</option>

    {/* Eighth Judicial District */}
    <option value="Eighth Judicial District - Greene County Superior Court">Greene County Superior Court</option>
    <option value="Eighth Judicial District - Lenoir County Superior Court">Lenoir County Superior Court</option>
    <option value="Eighth Judicial District - Wayne County Superior Court">Wayne County Superior Court</option>

    {/* Ninth Judicial District */}
    <option value="Ninth Judicial District - Franklin County Superior Court">Franklin County Superior Court</option>
    <option value="Ninth Judicial District - Granville County Superior Court">Granville County Superior Court</option>
    <option value="Ninth Judicial District - Person County Superior Court">Person County Superior Court</option>
    <option value="Ninth Judicial District - Vance County Superior Court">Vance County Superior Court</option>
    <option value="Ninth Judicial District - Warren County Superior Court">Warren County Superior Court</option>

    {/* Tenth Judicial District */}
    <option value="Tenth Judicial District - Wake County Superior Court">Wake County Superior Court</option>

    {/* Eleventh Judicial District */}
    <option value="Eleventh Judicial District - Harnett County Superior Court">Harnett County Superior Court</option>
    <option value="Eleventh Judicial District - Johnston County Superior Court">Johnston County Superior Court</option>
    <option value="Eleventh Judicial District - Lee County Superior Court">Lee County Superior Court</option>

    {/* Twelfth Judicial District */}
    <option value="Twelfth Judicial District - Cumberland County Superior Court">Cumberland County Superior Court</option>

    {/* Thirteenth Judicial District */}
    <option value="Thirteenth Judicial District - Bladen County Superior Court">Bladen County Superior Court</option>
    <option value="Thirteenth Judicial District - Brunswick County Superior Court">Brunswick County Superior Court</option>
    <option value="Thirteenth Judicial District - Columbus County Superior Court">Columbus County Superior Court</option>

    {/* Fourteenth Judicial District */}
    <option value="Fourteenth Judicial District - Durham County Superior Court">Durham County Superior Court</option>

    {/* Fifteenth Judicial District */}
    <option value="Fifteenth Judicial District - Alamance County Superior Court">Alamance County Superior Court</option>

    {/* Sixteenth Judicial District */}
    <option value="Sixteenth Judicial District - Anson County Superior Court">Anson County Superior Court</option>
    <option value="Sixteenth Judicial District - Richmond County Superior Court">Richmond County Superior Court</option>
    <option value="Sixteenth Judicial District - Scotland County Superior Court">Scotland County Superior Court</option>

    {/* Seventeenth Judicial District */}
    <option value="Seventeenth Judicial District - Caswell County Superior Court">Caswell County Superior Court</option>
    <option value="Seventeenth Judicial District - Rockingham County Superior Court">Rockingham County Superior Court</option>

    {/* Eighteenth Judicial District */}
    <option value="Eighteenth Judicial District - Guilford County Superior Court">Guilford County Superior Court</option>

    {/* Nineteenth Judicial District */}
    <option value="Nineteenth Judicial District - Cabarrus County Superior Court">Cabarrus County Superior Court</option>

    {/* Twentieth Judicial District */}
    <option value="Twentieth Judicial District - Davidson County Superior Court">Davidson County Superior Court</option>

    {/* Twenty-First Judicial District */}
    <option value="Twenty-First Judicial District - Forsyth County Superior Court">Forsyth County Superior Court</option>

    {/* Twenty-Second Judicial District */}
    <option value="Twenty-Second Judicial District - Iredell County Superior Court">Iredell County Superior Court</option>
    <option value="Twenty-Second Judicial District - Alexander County Superior Court">Alexander County Superior Court</option>

    {/* Twenty-Third Judicial District */}
    <option value="Twenty-Third Judicial District - Alleghany County Superior Court">Alleghany County Superior Court</option>
    <option value="Twenty-Third Judicial District - Ashe County Superior Court">Ashe County Superior Court</option>
    <option value="Twenty-Third Judicial District - Wilkes County Superior Court">Wilkes County Superior Court</option>
    <option value="Twenty-Third Judicial District - Yadkin County Superior Court">Yadkin County Superior Court</option>

    {/* Twenty-Fourth Judicial District */}
    <option value="Twenty-Fourth Judicial District - Avery County Superior Court">Avery County Superior Court</option>
    <option value="Twenty-Fourth Judicial District - Madison County Superior Court">Madison County Superior Court</option>
    <option value="Twenty-Fourth Judicial District - Mitchell County Superior Court">Mitchell County Superior Court</option>
    <option value="Twenty-Fourth Judicial District - Watauga County Superior Court">Watauga County Superior Court</option>
    <option value="Twenty-Fourth Judicial District - Yancey County Superior Court">Yancey County Superior Court</option>

    {/* Twenty-Fifth Judicial District */}
    <option value="Twenty-Fifth Judicial District - Burke County Superior Court">Burke County Superior Court</option>
    <option value="Twenty-Fifth Judicial District - Caldwell County Superior Court">Caldwell County Superior Court</option>
    <option value="Twenty-Fifth Judicial District - Catawba County Superior Court">Catawba County Superior Court</option>

    {/* Twenty-Sixth Judicial District */}
    <option value="Twenty-Sixth Judicial District - Mecklenburg County Superior Court">Mecklenburg County Superior Court</option>

    {/* Twenty-Seventh Judicial District */}
    <option value="Twenty-Seventh Judicial District - Gaston County Superior Court">Gaston County Superior Court</option>

    {/* Twenty-Eighth Judicial District */}
    <option value="Twenty-Eighth Judicial District - Buncombe County Superior Court">Buncombe County Superior Court</option>

    {/* Twenty-Ninth Judicial District */}
    <option value="Twenty-Ninth Judicial District - Henderson County Superior Court">Henderson County Superior Court</option>
    <option value="Twenty-Ninth Judicial District - McDowell County Superior Court">McDowell County Superior Court</option>
    <option value="Twenty-Ninth Judicial District - Polk County Superior Court">Polk County Superior Court</option>

    {/* Thirtieth Judicial District */}
    <option value="Thirtieth Judicial District - Cherokee County Superior Court">Cherokee County Superior Court</option>
    <option value="Thirtieth Judicial District - Clay County Superior Court">Clay County Superior Court</option>
    <option value="Thirtieth Judicial District - Graham County Superior Court">Graham County Superior Court</option>
    <option value="Thirtieth Judicial District - Haywood County Superior Court">Haywood County Superior Court</option>
    <option value="Thirtieth Judicial District - Jackson County Superior Court">Jackson County Superior Court</option>
    <option value="Thirtieth Judicial District - Macon County Superior Court">Macon County Superior Court</option>
    <option value="Thirtieth Judicial District - Swain County Superior Court">Swain County Superior Court</option>
  </>
)}
{secondValue === 'North Dakota' && (
  <>
    {/* Northeast Judicial District */}
    <option value="Northeast Judicial District - Benson County District Court">Benson County District Court</option>
    <option value="Northeast Judicial District - Bottineau County District Court">Bottineau County District Court</option>
    <option value="Northeast Judicial District - Cavalier County District Court">Cavalier County District Court</option>
    <option value="Northeast Judicial District - McHenry County District Court">McHenry County District Court</option>
    <option value="Northeast Judicial District - Pembina County District Court">Pembina County District Court</option>
    <option value="Northeast Judicial District - Pierce County District Court">Pierce County District Court</option>
    <option value="Northeast Judicial District - Ramsey County District Court">Ramsey County District Court</option>
    <option value="Northeast Judicial District - Renville County District Court">Renville County District Court</option>
    <option value="Northeast Judicial District - Rolette County District Court">Rolette County District Court</option>
    <option value="Northeast Judicial District - Towner County District Court">Towner County District Court</option>
    <option value="Northeast Judicial District - Walsh County District Court">Walsh County District Court</option>

    {/* Northeast Central Judicial District */}
    <option value="Northeast Central Judicial District - Grand Forks County District Court">Grand Forks County District Court</option>
    <option value="Northeast Central Judicial District - Nelson County District Court">Nelson County District Court</option>

    {/* Northwest Judicial District */}
    <option value="Northwest Judicial District - Burke County District Court">Burke County District Court</option>
    <option value="Northwest Judicial District - Divide County District Court">Divide County District Court</option>
    <option value="Northwest Judicial District - McKenzie County District Court">McKenzie County District Court</option>
    <option value="Northwest Judicial District - Mountrail County District Court">Mountrail County District Court</option>
    <option value="Northwest Judicial District - Ward County District Court">Ward County District Court</option>
    <option value="Northwest Judicial District - Williams County District Court">Williams County District Court</option>

    {/* East Central Judicial District */}
    <option value="East Central Judicial District - Cass County District Court">Cass County District Court</option>
    <option value="East Central Judicial District - Traill County District Court">Traill County District Court</option>

    {/* Southeast Judicial District */}
    <option value="Southeast Judicial District - Barnes County District Court">Barnes County District Court</option>
    <option value="Southeast Judicial District - Dickey County District Court">Dickey County District Court</option>
    <option value="Southeast Judicial District - Eddy County District Court">Eddy County District Court</option>
    <option value="Southeast Judicial District - Foster County District Court">Foster County District Court</option>
    <option value="Southeast Judicial District - Griggs County District Court">Griggs County District Court</option>
    <option value="Southeast Judicial District - LaMoure County District Court">LaMoure County District Court</option>
    <option value="Southeast Judicial District - Ransom County District Court">Ransom County District Court</option>
    <option value="Southeast Judicial District - Richland County District Court">Richland County District Court</option>
    <option value="Southeast Judicial District - Sargent County District Court">Sargent County District Court</option>
    <option value="Southeast Judicial District - Steele County District Court">Steele County District Court</option>
    <option value="Southeast Judicial District - Stutsman County District Court">Stutsman County District Court</option>

    {/* South Central Judicial District */}
    <option value="South Central Judicial District - Burleigh County District Court">Burleigh County District Court</option>
    <option value="South Central Judicial District - Emmons County District Court">Emmons County District Court</option>
    <option value="South Central Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="South Central Judicial District - Kidder County District Court">Kidder County District Court</option>
    <option value="South Central Judicial District - Logan County District Court">Logan County District Court</option>
    <option value="South Central Judicial District - McIntosh County District Court">McIntosh County District Court</option>
    <option value="South Central Judicial District - McLean County District Court">McLean County District Court</option>
    <option value="South Central Judicial District - Mercer County District Court">Mercer County District Court</option>
    <option value="South Central Judicial District - Morton County District Court">Morton County District Court</option>
    <option value="South Central Judicial District - Oliver County District Court">Oliver County District Court</option>
    <option value="South Central Judicial District - Sheridan County District Court">Sheridan County District Court</option>
    <option value="South Central Judicial District - Sioux County District Court">Sioux County District Court</option>

    {/* Southwest Judicial District */}
    <option value="Southwest Judicial District - Adams County District Court">Adams County District Court</option>
    <option value="Southwest Judicial District - Billings County District Court">Billings County District Court</option>
    <option value="Southwest Judicial District - Bowman County District Court">Bowman County District Court</option>
    <option value="Southwest Judicial District - Dunn County District Court">Dunn County District Court</option>
    <option value="Southwest Judicial District - Golden Valley County District Court">Golden Valley County District Court</option>
    <option value="Southwest Judicial District - Hettinger County District Court">Hettinger County District Court</option>
    <option value="Southwest Judicial District - Slope County District Court">Slope County District Court</option>
    <option value="Southwest Judicial District - Stark County District Court">Stark County District Court</option>
  </>
)}
{secondValue === 'North Dakota' && (
  <>
    {/* Northeast Judicial District */}
    <option value="Northeast Judicial District - Benson County District Court">Benson County District Court</option>
    <option value="Northeast Judicial District - Bottineau County District Court">Bottineau County District Court</option>
    <option value="Northeast Judicial District - Cavalier County District Court">Cavalier County District Court</option>
    <option value="Northeast Judicial District - McHenry County District Court">McHenry County District Court</option>
    <option value="Northeast Judicial District - Pembina County District Court">Pembina County District Court</option>
    <option value="Northeast Judicial District - Pierce County District Court">Pierce County District Court</option>
    <option value="Northeast Judicial District - Ramsey County District Court">Ramsey County District Court</option>
    <option value="Northeast Judicial District - Renville County District Court">Renville County District Court</option>
    <option value="Northeast Judicial District - Rolette County District Court">Rolette County District Court</option>
    <option value="Northeast Judicial District - Towner County District Court">Towner County District Court</option>
    <option value="Northeast Judicial District - Walsh County District Court">Walsh County District Court</option>

    {/* Northeast Central Judicial District */}
    <option value="Northeast Central Judicial District - Grand Forks County District Court">Grand Forks County District Court</option>
    <option value="Northeast Central Judicial District - Nelson County District Court">Nelson County District Court</option>

    {/* Northwest Judicial District */}
    <option value="Northwest Judicial District - Burke County District Court">Burke County District Court</option>
    <option value="Northwest Judicial District - Divide County District Court">Divide County District Court</option>
    <option value="Northwest Judicial District - McKenzie County District Court">McKenzie County District Court</option>
    <option value="Northwest Judicial District - Mountrail County District Court">Mountrail County District Court</option>
    <option value="Northwest Judicial District - Ward County District Court">Ward County District Court</option>
    <option value="Northwest Judicial District - Williams County District Court">Williams County District Court</option>

    {/* East Central Judicial District */}
    <option value="East Central Judicial District - Cass County District Court">Cass County District Court</option>
    <option value="East Central Judicial District - Traill County District Court">Traill County District Court</option>

    {/* Southeast Judicial District */}
    <option value="Southeast Judicial District - Barnes County District Court">Barnes County District Court</option>
    <option value="Southeast Judicial District - Dickey County District Court">Dickey County District Court</option>
    <option value="Southeast Judicial District - Eddy County District Court">Eddy County District Court</option>
    <option value="Southeast Judicial District - Foster County District Court">Foster County District Court</option>
    <option value="Southeast Judicial District - Griggs County District Court">Griggs County District Court</option>
    <option value="Southeast Judicial District - LaMoure County District Court">LaMoure County District Court</option>
    <option value="Southeast Judicial District - Ransom County District Court">Ransom County District Court</option>
    <option value="Southeast Judicial District - Richland County District Court">Richland County District Court</option>
    <option value="Southeast Judicial District - Sargent County District Court">Sargent County District Court</option>
    <option value="Southeast Judicial District - Steele County District Court">Steele County District Court</option>
    <option value="Southeast Judicial District - Stutsman County District Court">Stutsman County District Court</option>

    {/* South Central Judicial District */}
    <option value="South Central Judicial District - Burleigh County District Court">Burleigh County District Court</option>
    <option value="South Central Judicial District - Emmons County District Court">Emmons County District Court</option>
    <option value="South Central Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="South Central Judicial District - Kidder County District Court">Kidder County District Court</option>
    <option value="South Central Judicial District - Logan County District Court">Logan County District Court</option>
    <option value="South Central Judicial District - McIntosh County District Court">McIntosh County District Court</option>
    <option value="South Central Judicial District - McLean County District Court">McLean County District Court</option>
    <option value="South Central Judicial District - Mercer County District Court">Mercer County District Court</option>
    <option value="South Central Judicial District - Morton County District Court">Morton County District Court</option>
    <option value="South Central Judicial District - Oliver County District Court">Oliver County District Court</option>
    <option value="South Central Judicial District - Sheridan County District Court">Sheridan County District Court</option>
    <option value="South Central Judicial District - Sioux County District Court">Sioux County District Court</option>

    {/* Southwest Judicial District */}
    <option value="Southwest Judicial District - Adams County District Court">Adams County District Court</option>
    <option value="Southwest Judicial District - Billings County District Court">Billings County District Court</option>
    <option value="Southwest Judicial District - Bowman County District Court">Bowman County District Court</option>
    <option value="Southwest Judicial District - Dunn County District Court">Dunn County District Court</option>
    <option value="Southwest Judicial District - Golden Valley County District Court">Golden Valley County District Court</option>
    <option value="Southwest Judicial District - Hettinger County District Court">Hettinger County District Court</option>
    <option value="Southwest Judicial District - Slope County District Court">Slope County District Court</option>
    <option value="Southwest Judicial District - Stark County District Court">Stark County District Court</option>
  </>
)}
{secondValue === 'Ohio' && (
  <>
    <option value="Adams County Common Pleas Court">Adams County Common Pleas Court</option>
    <option value="Allen County Common Pleas Court">Allen County Common Pleas Court</option>
    <option value="Ashland County Common Pleas Court">Ashland County Common Pleas Court</option>
    <option value="Ashtabula County Common Pleas Court">Ashtabula County Common Pleas Court</option>
    <option value="Athens County Common Pleas Court">Athens County Common Pleas Court</option>
    <option value="Auglaize County Common Pleas Court">Auglaize County Common Pleas Court</option>
    <option value="Belmont County Common Pleas Court">Belmont County Common Pleas Court</option>
    <option value="Brown County Common Pleas Court">Brown County Common Pleas Court</option>
    <option value="Butler County Common Pleas Court">Butler County Common Pleas Court</option>
    <option value="Carroll County Common Pleas Court">Carroll County Common Pleas Court</option>
    <option value="Champaign County Common Pleas Court">Champaign County Common Pleas Court</option>
    <option value="Clark County Common Pleas Court">Clark County Common Pleas Court</option>
    <option value="Clermont County Common Pleas Court">Clermont County Common Pleas Court</option>
    <option value="Clinton County Common Pleas Court">Clinton County Common Pleas Court</option>
    <option value="Columbiana County Common Pleas Court">Columbiana County Common Pleas Court</option>
    <option value="Coshocton County Common Pleas Court">Coshocton County Common Pleas Court</option>
    <option value="Crawford County Common Pleas Court">Crawford County Common Pleas Court</option>
    <option value="Cuyahoga County Common Pleas Court">Cuyahoga County Common Pleas Court</option>
    <option value="Darke County Common Pleas Court">Darke County Common Pleas Court</option>
    <option value="Defiance County Common Pleas Court">Defiance County Common Pleas Court</option>
    <option value="Delaware County Common Pleas Court">Delaware County Common Pleas Court</option>
    <option value="Erie County Common Pleas Court">Erie County Common Pleas Court</option>
    <option value="Fairfield County Common Pleas Court">Fairfield County Common Pleas Court</option>
    <option value="Fayette County Common Pleas Court">Fayette County Common Pleas Court</option>
    <option value="Franklin County Common Pleas Court">Franklin County Common Pleas Court</option>
    <option value="Fulton County Common Pleas Court">Fulton County Common Pleas Court</option>
    <option value="Gallia County Common Pleas Court">Gallia County Common Pleas Court</option>
    <option value="Geauga County Common Pleas Court">Geauga County Common Pleas Court</option>
    <option value="Greene County Common Pleas Court">Greene County Common Pleas Court</option>
    <option value="Guernsey County Common Pleas Court">Guernsey County Common Pleas Court</option>
    <option value="Hamilton County Common Pleas Court">Hamilton County Common Pleas Court</option>
    <option value="Hancock County Common Pleas Court">Hancock County Common Pleas Court</option>
    <option value="Hardin County Common Pleas Court">Hardin County Common Pleas Court</option>
    <option value="Harrison County Common Pleas Court">Harrison County Common Pleas Court</option>
    <option value="Henry County Common Pleas Court">Henry County Common Pleas Court</option>
    <option value="Highland County Common Pleas Court">Highland County Common Pleas Court</option>
    <option value="Hocking County Common Pleas Court">Hocking County Common Pleas Court</option>
    <option value="Holmes County Common Pleas Court">Holmes County Common Pleas Court</option>
    <option value="Huron County Common Pleas Court">Huron County Common Pleas Court</option>
    <option value="Jackson County Common Pleas Court">Jackson County Common Pleas Court</option>
    <option value="Jefferson County Common Pleas Court">Jefferson County Common Pleas Court</option>
    <option value="Knox County Common Pleas Court">Knox County Common Pleas Court</option>
    <option value="Lake County Common Pleas Court">Lake County Common Pleas Court</option>
    <option value="Lawrence County Common Pleas Court">Lawrence County Common Pleas Court</option>
    <option value="Licking County Common Pleas Court">Licking County Common Pleas Court</option>
    <option value="Logan County Common Pleas Court">Logan County Common Pleas Court</option>
    <option value="Lorain County Common Pleas Court">Lorain County Common Pleas Court</option>
    <option value="Lucas County Common Pleas Court">Lucas County Common Pleas Court</option>
    <option value="Madison County Common Pleas Court">Madison County Common Pleas Court</option>
    <option value="Mahoning County Common Pleas Court">Mahoning County Common Pleas Court</option>
    <option value="Marion County Common Pleas Court">Marion County Common Pleas Court</option>
    <option value="Medina County Common Pleas Court">Medina County Common Pleas Court</option>
    <option value="Meigs County Common Pleas Court">Meigs County Common Pleas Court</option>
    <option value="Mercer County Common Pleas Court">Mercer County Common Pleas Court</option>
    <option value="Miami County Common Pleas Court">Miami County Common Pleas Court</option>
    <option value="Monroe County Common Pleas Court">Monroe County Common Pleas Court</option>
    <option value="Montgomery County Common Pleas Court">Montgomery County Common Pleas Court</option>
    <option value="Morgan County Common Pleas Court">Morgan County Common Pleas Court</option>
    <option value="Morrow County Common Pleas Court">Morrow County Common Pleas Court</option>
    <option value="Muskingum County Common Pleas Court">Muskingum County Common Pleas Court</option>
    <option value="Noble County Common Pleas Court">Noble County Common Pleas Court</option>
    <option value="Ottawa County Common Pleas Court">Ottawa County Common Pleas Court</option>
    <option value="Paulding County Common Pleas Court">Paulding County Common Pleas Court</option>
    <option value="Perry County Common Pleas Court">Perry County Common Pleas Court</option>
    <option value="Pickaway County Common Pleas Court">Pickaway County Common Pleas Court</option>
    <option value="Pike County Common Pleas Court">Pike County Common Pleas Court</option>
    <option value="Portage County Common Pleas Court">Portage County Common Pleas Court</option>
    <option value="Preble County Common Pleas Court">Preble County Common Pleas Court</option>
    <option value="Putnam County Common Pleas Court">Putnam County Common Pleas Court</option>
    <option value="Richland County Common Pleas Court">Richland County Common Pleas Court</option>
    <option value="Ross County Common Pleas Court">Ross County Common Pleas Court</option>
    <option value="Sandusky County Common Pleas Court">Sandusky County Common Pleas Court</option>
    <option value="Scioto County Common Pleas Court">Scioto County Common Pleas Court</option>
    <option value="Seneca County Common Pleas Court">Seneca County Common Pleas Court</option>
    <option value="Shelby County Common Pleas Court">Shelby County Common Pleas Court</option>
    <option value="Stark County Common Pleas Court">Stark County Common Pleas Court</option>
    <option value="Summit County Common Pleas Court">Summit County Common Pleas Court</option>
    <option value="Trumbull County Common Pleas Court">Trumbull County Common Pleas Court</option>
    <option value="Tuscarawas County Common Pleas Court">Tuscarawas County Common Pleas Court</option>
    <option value="Union County Common Pleas Court">Union County Common Pleas Court</option>
    <option value="Van Wert County Common Pleas Court">Van Wert County Common Pleas Court</option>
    <option value="Vinton County Common Pleas Court">Vinton County Common Pleas Court</option>
    <option value="Warren County Common Pleas Court">Warren County Common Pleas Court</option>
    <option value="Washington County Common Pleas Court">Washington County Common Pleas Court</option>
    <option value="Wayne County Common Pleas Court">Wayne County Common Pleas Court</option>
    <option value="Williams County Common Pleas Court">Williams County Common Pleas Court</option>
    <option value="Wood County Common Pleas Court">Wood County Common Pleas Court</option>
    <option value="Wyandot County Common Pleas Court">Wyandot County Common Pleas Court</option>
  </>
)}
{secondValue === 'Oklahoma' && (
  <>
    {/* First Judicial District */}
    <option value="First Judicial District - Beaver County District Court">Beaver County District Court</option>
    <option value="First Judicial District - Cimarron County District Court">Cimarron County District Court</option>
    <option value="First Judicial District - Texas County District Court">Texas County District Court</option>

    {/* Second Judicial District */}
    <option value="Second Judicial District - Beckham County District Court">Beckham County District Court</option>
    <option value="Second Judicial District - Custer County District Court">Custer County District Court</option>
    <option value="Second Judicial District - Ellis County District Court">Ellis County District Court</option>
    <option value="Second Judicial District - Roger Mills County District Court">Roger Mills County District Court</option>
    <option value="Second Judicial District - Washita County District Court">Washita County District Court</option>

    {/* Third Judicial District */}
    <option value="Third Judicial District - Greer County District Court">Greer County District Court</option>
    <option value="Third Judicial District - Harmon County District Court">Harmon County District Court</option>
    <option value="Third Judicial District - Jackson County District Court">Jackson County District Court</option>
    <option value="Third Judicial District - Kiowa County District Court">Kiowa County District Court</option>
    <option value="Third Judicial District - Tillman County District Court">Tillman County District Court</option>

    {/* Fourth Judicial District */}
    <option value="Fourth Judicial District - Blaine County District Court">Blaine County District Court</option>
    <option value="Fourth Judicial District - Dewey County District Court">Dewey County District Court</option>
    <option value="Fourth Judicial District - Garfield County District Court">Garfield County District Court</option>
    <option value="Fourth Judicial District - Grant County District Court">Grant County District Court</option>
    <option value="Fourth Judicial District - Kingfisher County District Court">Kingfisher County District Court</option>
    <option value="Fourth Judicial District - Major County District Court">Major County District Court</option>
    <option value="Fourth Judicial District - Woodward County District Court">Woodward County District Court</option>

    {/* Fifth Judicial District */}
    <option value="Fifth Judicial District - Comanche County District Court">Comanche County District Court</option>
    <option value="Fifth Judicial District - Cotton County District Court">Cotton County District Court</option>
    <option value="Fifth Judicial District - Jefferson County District Court">Jefferson County District Court</option>
    <option value="Fifth Judicial District - Stephens County District Court">Stephens County District Court</option>

    {/* Sixth Judicial District */}
    <option value="Sixth Judicial District - Caddo County District Court">Caddo County District Court</option>
    <option value="Sixth Judicial District - Grady County District Court">Grady County District Court</option>

    {/* Seventh Judicial District */}
    <option value="Seventh Judicial District - Oklahoma County District Court">Oklahoma County District Court</option>

    {/* Eighth Judicial District */}
    <option value="Eighth Judicial District - Kay County District Court">Kay County District Court</option>
    <option value="Eighth Judicial District - Noble County District Court">Noble County District Court</option>

    {/* Ninth Judicial District */}
    <option value="Ninth Judicial District - Logan County District Court">Logan County District Court</option>
    <option value="Ninth Judicial District - Payne County District Court">Payne County District Court</option>

    {/* Tenth Judicial District */}
    <option value="Tenth Judicial District - Osage County District Court">Osage County District Court</option>

    {/* Eleventh Judicial District */}
    <option value="Eleventh Judicial District - Nowata County District Court">Nowata County District Court</option>
    <option value="Eleventh Judicial District - Washington County District Court">Washington County District Court</option>

    {/* Twelfth Judicial District */}
    <option value="Twelfth Judicial District - Craig County District Court">Craig County District Court</option>
    <option value="Twelfth Judicial District - Mayes County District Court">Mayes County District Court</option>
    <option value="Twelfth Judicial District - Rogers County District Court">Rogers County District Court</option>

    {/* Thirteenth Judicial District */}
    <option value="Thirteenth Judicial District - Delaware County District Court">Delaware County District Court</option>
    <option value="Thirteenth Judicial District - Ottawa County District Court">Ottawa County District Court</option>

    {/* Fourteenth Judicial District */}
    <option value="Fourteenth Judicial District - Tulsa County District Court">Tulsa County District Court</option>

    {/* Fifteenth Judicial District */}
    <option value="Fifteenth Judicial District - Muskogee County District Court">Muskogee County District Court</option>

    {/* Sixteenth Judicial District */}
    <option value="Sixteenth Judicial District - Latimer County District Court">Latimer County District Court</option>
    <option value="Sixteenth Judicial District - Le Flore County District Court">Le Flore County District Court</option>

    {/* Seventeenth Judicial District */}
    <option value="Seventeenth Judicial District - McCurtain County District Court">McCurtain County District Court</option>
    <option value="Seventeenth Judicial District - Pushmataha County District Court">Pushmataha County District Court</option>

    {/* Eighteenth Judicial District */}
    <option value="Eighteenth Judicial District - Haskell County District Court">Haskell County District Court</option>
    <option value="Eighteenth Judicial District - Pittsburg County District Court">Pittsburg County District Court</option>

    {/* Nineteenth Judicial District */}
    <option value="Nineteenth Judicial District - Atoka County District Court">Atoka County District Court</option>
    <option value="Nineteenth Judicial District - Coal County District Court">Coal County District Court</option>

    {/* Twentieth Judicial District */}
    <option value="Twentieth Judicial District - Carter County District Court">Carter County District Court</option>
    <option value="Twentieth Judicial District - Love County District Court">Love County District Court</option>
    <option value="Twentieth Judicial District - Marshall County District Court">Marshall County District Court</option>

    {/* Twenty-First Judicial District */}
    <option value="Twenty-First Judicial District - Cleveland County District Court">Cleveland County District Court</option>
    <option value="Twenty-First Judicial District - McClain County District Court">McClain County District Court</option>
    <option value="Twenty-First Judicial District - Garvin County District Court">Garvin County District Court</option>

    {/* Twenty-Second Judicial District */}
    <option value="Twenty-Second Judicial District - Hughes County District Court">Hughes County District Court</option>
    <option value="Twenty-Second Judicial District - Pontotoc County District Court">Pontotoc County District Court</option>

    {/* Twenty-Third Judicial District */}
    <option value="Twenty-Third Judicial District - Pottawatomie County District Court">Pottawatomie County District Court</option>
    <option value="Twenty-Third Judicial District - Lincoln County District Court">Lincoln County District Court</option>

    {/* Twenty-Fourth Judicial District */}
    <option value="Twenty-Fourth Judicial District - Creek County District Court">Creek County District Court</option>

    {/* Twenty-Fifth Judicial District */}
    <option value="Twenty-Fifth Judicial District - Okfuskee County District Court">Okfuskee County District Court</option>
    <option value="Twenty-Fifth Judicial District - Seminole County District Court">Seminole County District Court</option>

    {/* Twenty-Sixth Judicial District */}
    <option value="Twenty-Sixth Judicial District - Alfalfa County District Court">Alfalfa County District Court</option>
    <option value="Twenty-Sixth Judicial District - Dewey County District Court">Dewey County District Court</option>
    <option value="Twenty-Sixth Judicial District - Harper County District Court">Harper County District Court</option>
  </>
)}
{secondValue === 'Oregon' && (
  <>
    {/* First Judicial District */}
    <option value="First Judicial District - Jackson County Circuit Court">Jackson County Circuit Court</option>

    {/* Second Judicial District */}
    <option value="Second Judicial District - Lane County Circuit Court">Lane County Circuit Court</option>

    {/* Third Judicial District */}
    <option value="Third Judicial District - Marion County Circuit Court">Marion County Circuit Court</option>

    {/* Fourth Judicial District */}
    <option value="Fourth Judicial District - Multnomah County Circuit Court">Multnomah County Circuit Court</option>

    {/* Fifth Judicial District */}
    <option value="Fifth Judicial District - Clackamas County Circuit Court">Clackamas County Circuit Court</option>

    {/* Sixth Judicial District */}
    <option value="Sixth Judicial District - Morrow County Circuit Court">Morrow County Circuit Court</option>
    <option value="Sixth Judicial District - Umatilla County Circuit Court">Umatilla County Circuit Court</option>

    {/* Seventh Judicial District */}
    <option value="Seventh Judicial District - Gilliam County Circuit Court">Gilliam County Circuit Court</option>
    <option value="Seventh Judicial District - Hood River County Circuit Court">Hood River County Circuit Court</option>
    <option value="Seventh Judicial District - Sherman County Circuit Court">Sherman County Circuit Court</option>
    <option value="Seventh Judicial District - Wasco County Circuit Court">Wasco County Circuit Court</option>
    <option value="Seventh Judicial District - Wheeler County Circuit Court">Wheeler County Circuit Court</option>

    {/* Eighth Judicial District */}
    <option value="Eighth Judicial District - Baker County Circuit Court">Baker County Circuit Court</option>

    {/* Ninth Judicial District */}
    <option value="Ninth Judicial District - Malheur County Circuit Court">Malheur County Circuit Court</option>

    {/* Tenth Judicial District */}
    <option value="Tenth Judicial District - Union County Circuit Court">Union County Circuit Court</option>
    <option value="Tenth Judicial District - Wallowa County Circuit Court">Wallowa County Circuit Court</option>

    {/* Eleventh Judicial District */}
    <option value="Eleventh Judicial District - Deschutes County Circuit Court">Deschutes County Circuit Court</option>

    {/* Twelfth Judicial District */}
    <option value="Twelfth Judicial District - Polk County Circuit Court">Polk County Circuit Court</option>

    {/* Thirteenth Judicial District */}
    <option value="Thirteenth Judicial District - Klamath County Circuit Court">Klamath County Circuit Court</option>

    {/* Fourteenth Judicial District */}
    <option value="Fourteenth Judicial District - Josephine County Circuit Court">Josephine County Circuit Court</option>

    {/* Fifteenth Judicial District */}
    <option value="Fifteenth Judicial District - Coos County Circuit Court">Coos County Circuit Court</option>

    {/* Sixteenth Judicial District */}
    <option value="Sixteenth Judicial District - Douglas County Circuit Court">Douglas County Circuit Court</option>

    {/* Seventeenth Judicial District */}
    <option value="Seventeenth Judicial District - Lincoln County Circuit Court">Lincoln County Circuit Court</option>

    {/* Eighteenth Judicial District */}
    <option value="Eighteenth Judicial District - Clatsop County Circuit Court">Clatsop County Circuit Court</option>

    {/* Nineteenth Judicial District */}
    <option value="Nineteenth Judicial District - Columbia County Circuit Court">Columbia County Circuit Court</option>

    {/* Twentieth Judicial District */}
    <option value="Twentieth Judicial District - Washington County Circuit Court">Washington County Circuit Court</option>

    {/* Twenty-First Judicial District */}
    <option value="Twenty-First Judicial District - Benton County Circuit Court">Benton County Circuit Court</option>

    {/* Twenty-Second Judicial District */}
    <option value="Twenty-Second Judicial District - Crook County Circuit Court">Crook County Circuit Court</option>
    <option value="Twenty-Second Judicial District - Jefferson County Circuit Court">Jefferson County Circuit Court</option>

    {/* Twenty-Third Judicial District */}
    <option value="Twenty-Third Judicial District - Linn County Circuit Court">Linn County Circuit Court</option>

    {/* Twenty-Fourth Judicial District */}
    <option value="Twenty-Fourth Judicial District - Harney County Circuit Court">Harney County Circuit Court</option>

    {/* Twenty-Fifth Judicial District */}
    <option value="Twenty-Fifth Judicial District - Yamhill County Circuit Court">Yamhill County Circuit Court</option>

    {/* Twenty-Sixth Judicial District */}
    <option value="Twenty-Sixth Judicial District - Lake County Circuit Court">Lake County Circuit Court</option>

    <option value="Twenty-Seventh Judicial District - Tillamook County Circuit Court">Tillamook County Circuit Court</option>
  </>
)}
{secondValue === 'Pennsylvania' && (
  <>
    <option value="First Judicial District - Philadelphia County Court of Common Pleas">Philadelphia County Court of Common Pleas</option>
    <option value="Second Judicial District - Lancaster County Court of Common Pleas">Lancaster County Court of Common Pleas</option>
    <option value="Third Judicial District - Northampton County Court of Common Pleas">Northampton County Court of Common Pleas</option>
    <option value="Fourth Judicial District - Tioga County Court of Common Pleas">Tioga County Court of Common Pleas</option>
    <option value="Fifth Judicial District - Allegheny County Court of Common Pleas">Allegheny County Court of Common Pleas</option>
    <option value="Sixth Judicial District - Erie County Court of Common Pleas">Erie County Court of Common Pleas</option>
    <option value="Seventh Judicial District - Bucks County Court of Common Pleas">Bucks County Court of Common Pleas</option>
    <option value="Eighth Judicial District - Northumberland County Court of Common Pleas">Northumberland County Court of Common Pleas</option>
    <option value="Ninth Judicial District - Cumberland County Court of Common Pleas">Cumberland County Court of Common Pleas</option>
    <option value="Tenth Judicial District - Westmoreland County Court of Common Pleas">Westmoreland County Court of Common Pleas</option>
    <option value="Eleventh Judicial District - Luzerne County Court of Common Pleas">Luzerne County Court of Common Pleas</option>
    <option value="Twelfth Judicial District - Dauphin County Court of Common Pleas">Dauphin County Court of Common Pleas</option>
    <option value="Thirteenth Judicial District - Lebanon County Court of Common Pleas">Lebanon County Court of Common Pleas</option>
    <option value="Fourteenth Judicial District - Fayette County Court of Common Pleas">Fayette County Court of Common Pleas</option>
    <option value="Fifteenth Judicial District - Chester County Court of Common Pleas">Chester County Court of Common Pleas</option>
    <option value="Sixteenth Judicial District - Somerset County Court of Common Pleas">Somerset County Court of Common Pleas</option>
    <option value="Seventeenth Judicial District - Snyder County Court of Common Pleas">Snyder County Court of Common Pleas</option>
    <option value="Seventeenth Judicial District - Union County Court of Common Pleas">Union County Court of Common Pleas</option>
    <option value="Eighteenth Judicial District - Clarion County Court of Common Pleas">Clarion County Court of Common Pleas</option>
    <option value="Nineteenth Judicial District - York County Court of Common Pleas">York County Court of Common Pleas</option>
    <option value="Twentieth Judicial District - Huntingdon County Court of Common Pleas">Huntingdon County Court of Common Pleas</option>
    <option value="Twenty-First Judicial District - Schuylkill County Court of Common Pleas">Schuylkill County Court of Common Pleas</option>
    <option value="Twenty-Second Judicial District - Wayne County Court of Common Pleas">Wayne County Court of Common Pleas</option>
    <option value="Twenty-Third Judicial District - Berks County Court of Common Pleas">Berks County Court of Common Pleas</option>
    <option value="Twenty-Fourth Judicial District - Blair County Court of Common Pleas">Blair County Court of Common Pleas</option>
    <option value="Twenty-Fifth Judicial District - Clinton County Court of Common Pleas">Clinton County Court of Common Pleas</option>
    <option value="Twenty-Sixth Judicial District - Columbia County Court of Common Pleas">Columbia County Court of Common Pleas</option>
    <option value="Twenty-Sixth Judicial District - Montour County Court of Common Pleas">Montour County Court of Common Pleas</option>
    <option value="Twenty-Seventh Judicial District - Washington County Court of Common Pleas">Washington County Court of Common Pleas</option>
    <option value="Twenty-Eighth Judicial District - Venango County Court of Common Pleas">Venango County Court of Common Pleas</option>
    <option value="Twenty-Ninth Judicial District - Lycoming County Court of Common Pleas">Lycoming County Court of Common Pleas</option>
    <option value="Thirtieth Judicial District - Crawford County Court of Common Pleas">Crawford County Court of Common Pleas</option>
    <option value="Thirty-First Judicial District - Lehigh County Court of Common Pleas">Lehigh County Court of Common Pleas</option>
    <option value="Thirty-Second Judicial District - Delaware County Court of Common Pleas">Delaware County Court of Common Pleas</option>
    <option value="Thirty-Third Judicial District - Armstrong County Court of Common Pleas">Armstrong County Court of Common Pleas</option>
    <option value="Thirty-Fourth Judicial District - Susquehanna County Court of Common Pleas">Susquehanna County Court of Common Pleas</option>
    <option value="Thirty-Fifth Judicial District - Mercer County Court of Common Pleas">Mercer County Court of Common Pleas</option>
    <option value="Thirty-Sixth Judicial District - Butler County Court of Common Pleas">Butler County Court of Common Pleas</option>
    <option value="Thirty-Seventh Judicial District - Forest County Court of Common Pleas">Forest County Court of Common Pleas</option>
    <option value="Thirty-Seventh Judicial District - Warren County Court of Common Pleas">Warren County Court of Common Pleas</option>
    <option value="Thirty-Eighth Judicial District - Montgomery County Court of Common Pleas">Montgomery County Court of Common Pleas</option>
    <option value="Thirty-Ninth Judicial District - Franklin County Court of Common Pleas">Franklin County Court of Common Pleas</option>
    <option value="Thirty-Ninth Judicial District - Fulton County Court of Common Pleas">Fulton County Court of Common Pleas</option>
    <option value="Fortieth Judicial District - Indiana County Court of Common Pleas">Indiana County Court of Common Pleas</option>
    <option value="Forty-First Judicial District - Juniata County Court of Common Pleas">Juniata County Court of Common Pleas</option>
    <option value="Forty-First Judicial District - Perry County Court of Common Pleas">Perry County Court of Common Pleas</option>
    <option value="Forty-Second Judicial District - Bradford County Court of Common Pleas">Bradford County Court of Common Pleas</option>
    <option value="Forty-Third Judicial District - Monroe County Court of Common Pleas">Monroe County Court of Common Pleas</option>
    <option value="Forty-Fourth Judicial District - Wyoming County Court of Common Pleas">Wyoming County Court of Common Pleas</option>
    <option value="Forty-Fifth Judicial District - Lackawanna County Court of Common Pleas">Lackawanna County Court of Common Pleas</option>
    <option value="Forty-Sixth Judicial District - Clearfield County Court of Common Pleas">Clearfield County Court of Common Pleas</option>
    <option value="Forty-Seventh Judicial District - Cambria County Court of Common Pleas">Cambria County Court of Common Pleas</option>
    <option value="Forty-Eighth Judicial District - McKean County Court of Common Pleas">McKean County Court of Common Pleas</option>
    <option value="Forty-Ninth Judicial District - Centre County Court of Common Pleas">Centre County Court of Common Pleas</option>
    <option value="Fiftieth Judicial District - Butler County Court of Common Pleas">Butler County Court of Common Pleas</option>
    <option value="Fifty-First Judicial District - Adams County Court of Common Pleas">Adams County Court of Common Pleas</option>
    <option value="Fifty-Second Judicial District - Lebanon County Court of Common Pleas">Lebanon County Court of Common Pleas</option>
    <option value="Fifty-Third Judicial District - Lawrence County Court of Common Pleas">Lawrence County Court of Common Pleas</option>
    <option value="Fifty-Fourth Judicial District - Wayne County Court of Common Pleas">Wayne County Court of Common Pleas</option>
    <option value="Fifty-Fifth Judicial District - Wyoming County Court of Common Pleas">Wyoming County Court of Common Pleas</option>
    <option value="Fifty-Sixth Judicial District - Sullivan County Court of Common Pleas">Sullivan County Court of Common Pleas</option>
    <option value="Fifty-Seventh Judicial District - Beaver County Court of Common Pleas">Beaver County Court of Common Pleas</option>
    <option value="Fifty-Eighth Judicial District - Wayne County Court of Common Pleas">Wayne County Court of Common Pleas</option>
    <option value="Fifty-Ninth Judicial District - Elk County Court of Common Pleas">Elk County Court of Common Pleas</option>
    <option value="Sixtieth Judicial District - Wayne County Court of Common Pleas">Wayne County Court of Common Pleas</option>
  </>
)}
{secondValue === 'Rhode Island' && (
  <>
    <option value="Providence County Superior Court">Providence County Superior Court</option>
    <option value="Kent County Superior Court">Kent County Superior Court</option>
    <option value="Washington County Superior Court">Washington County Superior Court</option>
    <option value="Newport County Superior Court">Newport County Superior Court</option>
  </>
)}
{secondValue === 'South Carolina' && (
  <>
    <option value="First Judicial Circuit - Calhoun County Circuit Court">Calhoun County Circuit Court</option>
    <option value="First Judicial Circuit - Dorchester County Circuit Court">Dorchester County Circuit Court</option>
    <option value="First Judicial Circuit - Orangeburg County Circuit Court">Orangeburg County Circuit Court</option>

    <option value="Second Judicial Circuit - Aiken County Circuit Court">Aiken County Circuit Court</option>
    <option value="Second Judicial Circuit - Bamberg County Circuit Court">Bamberg County Circuit Court</option>
    <option value="Second Judicial Circuit - Barnwell County Circuit Court">Barnwell County Circuit Court</option>

    <option value="Third Judicial Circuit - Clarendon County Circuit Court">Clarendon County Circuit Court</option>
    <option value="Third Judicial Circuit - Lee County Circuit Court">Lee County Circuit Court</option>
    <option value="Third Judicial Circuit - Sumter County Circuit Court">Sumter County Circuit Court</option>
    <option value="Third Judicial Circuit - Williamsburg County Circuit Court">Williamsburg County Circuit Court</option>

    <option value="Fourth Judicial Circuit - Chesterfield County Circuit Court">Chesterfield County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Darlington County Circuit Court">Darlington County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Dillon County Circuit Court">Dillon County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Marlboro County Circuit Court">Marlboro County Circuit Court</option>

    <option value="Fifth Judicial Circuit - Kershaw County Circuit Court">Kershaw County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Richland County Circuit Court">Richland County Circuit Court</option>

    <option value="Sixth Judicial Circuit - Chester County Circuit Court">Chester County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Fairfield County Circuit Court">Fairfield County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Lancaster County Circuit Court">Lancaster County Circuit Court</option>

    <option value="Seventh Judicial Circuit - Cherokee County Circuit Court">Cherokee County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Spartanburg County Circuit Court">Spartanburg County Circuit Court</option>

    <option value="Eighth Judicial Circuit - Abbeville County Circuit Court">Abbeville County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Greenwood County Circuit Court">Greenwood County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Laurens County Circuit Court">Laurens County Circuit Court</option>
    <option value="Eighth Judicial Circuit - Newberry County Circuit Court">Newberry County Circuit Court</option>

    <option value="Ninth Judicial Circuit - Berkeley County Circuit Court">Berkeley County Circuit Court</option>
    <option value="Ninth Judicial Circuit - Charleston County Circuit Court">Charleston County Circuit Court</option>

    <option value="Tenth Judicial Circuit - Anderson County Circuit Court">Anderson County Circuit Court</option>
    <option value="Tenth Judicial Circuit - Oconee County Circuit Court">Oconee County Circuit Court</option>

    <option value="Eleventh Judicial Circuit - Edgefield County Circuit Court">Edgefield County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Lexington County Circuit Court">Lexington County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - McCormick County Circuit Court">McCormick County Circuit Court</option>
    <option value="Eleventh Judicial Circuit - Saluda County Circuit Court">Saluda County Circuit Court</option>

    <option value="Twelfth Judicial Circuit - Florence County Circuit Court">Florence County Circuit Court</option>
    <option value="Twelfth Judicial Circuit - Marion County Circuit Court">Marion County Circuit Court</option>

    <option value="Thirteenth Judicial Circuit - Greenville County Circuit Court">Greenville County Circuit Court</option>
    <option value="Thirteenth Judicial Circuit - Pickens County Circuit Court">Pickens County Circuit Court</option>

    <option value="Fourteenth Judicial Circuit - Allendale County Circuit Court">Allendale County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Beaufort County Circuit Court">Beaufort County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Colleton County Circuit Court">Colleton County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Hampton County Circuit Court">Hampton County Circuit Court</option>
    <option value="Fourteenth Judicial Circuit - Jasper County Circuit Court">Jasper County Circuit Court</option>

    <option value="Fifteenth Judicial Circuit - Georgetown County Circuit Court">Georgetown County Circuit Court</option>
    <option value="Fifteenth Judicial Circuit - Horry County Circuit Court">Horry County Circuit Court</option>

    <option value="Sixteenth Judicial Circuit - York County Circuit Court">York County Circuit Court</option>
  </>
)}
{secondValue === 'South Dakota' && (
  <>
    <option value="First Judicial Circuit - Aurora County Circuit Court">Aurora County Circuit Court</option>
    <option value="First Judicial Circuit - Bon Homme County Circuit Court">Bon Homme County Circuit Court</option>
    <option value="First Judicial Circuit - Brule County Circuit Court">Brule County Circuit Court</option>
    <option value="First Judicial Circuit - Charles Mix County Circuit Court">Charles Mix County Circuit Court</option>
    <option value="First Judicial Circuit - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="First Judicial Circuit - Davison County Circuit Court">Davison County Circuit Court</option>
    <option value="First Judicial Circuit - Douglas County Circuit Court">Douglas County Circuit Court</option>
    <option value="First Judicial Circuit - Gregory County Circuit Court">Gregory County Circuit Court</option>
    <option value="First Judicial Circuit - Hutchinson County Circuit Court">Hutchinson County Circuit Court</option>
    <option value="First Judicial Circuit - McCook County Circuit Court">McCook County Circuit Court</option>
    <option value="First Judicial Circuit - Turner County Circuit Court">Turner County Circuit Court</option>
    <option value="First Judicial Circuit - Union County Circuit Court">Union County Circuit Court</option>
    <option value="First Judicial Circuit - Yankton County Circuit Court">Yankton County Circuit Court</option>

    <option value="Second Judicial Circuit - Lincoln County Circuit Court">Lincoln County Circuit Court</option>
    <option value="Second Judicial Circuit - Minnehaha County Circuit Court">Minnehaha County Circuit Court</option>

    <option value="Third Judicial Circuit - Brookings County Circuit Court">Brookings County Circuit Court</option>
    <option value="Third Judicial Circuit - Clark County Circuit Court">Clark County Circuit Court</option>
    <option value="Third Judicial Circuit - Codington County Circuit Court">Codington County Circuit Court</option>
    <option value="Third Judicial Circuit - Deuel County Circuit Court">Deuel County Circuit Court</option>
    <option value="Third Judicial Circuit - Grant County Circuit Court">Grant County Circuit Court</option>
    <option value="Third Judicial Circuit - Hamlin County Circuit Court">Hamlin County Circuit Court</option>
    <option value="Third Judicial Circuit - Kingsbury County Circuit Court">Kingsbury County Circuit Court</option>
    <option value="Third Judicial Circuit - Lake County Circuit Court">Lake County Circuit Court</option>
    <option value="Third Judicial Circuit - Miner County Circuit Court">Miner County Circuit Court</option>
    <option value="Third Judicial Circuit - Moody County Circuit Court">Moody County Circuit Court</option>

    <option value="Fourth Judicial Circuit - Butte County Circuit Court">Butte County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Corson County Circuit Court">Corson County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Dewey County Circuit Court">Dewey County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Harding County Circuit Court">Harding County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Lawrence County Circuit Court">Lawrence County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Meade County Circuit Court">Meade County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Perkins County Circuit Court">Perkins County Circuit Court</option>
    <option value="Fourth Judicial Circuit - Ziebach County Circuit Court">Ziebach County Circuit Court</option>

    <option value="Fifth Judicial Circuit - Brown County Circuit Court">Brown County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Campbell County Circuit Court">Campbell County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Day County Circuit Court">Day County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Edmunds County Circuit Court">Edmunds County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Faulk County Circuit Court">Faulk County Circuit Court</option>
    <option value="Fifth Judicial Circuit - McPherson County Circuit Court">McPherson County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Marshall County Circuit Court">Marshall County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Roberts County Circuit Court">Roberts County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Spink County Circuit Court">Spink County Circuit Court</option>
    <option value="Fifth Judicial Circuit - Walworth County Circuit Court">Walworth County Circuit Court</option>

    <option value="Sixth Judicial Circuit - Bennett County Circuit Court">Bennett County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Haakon County Circuit Court">Haakon County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Hughes County Circuit Court">Hughes County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Hyde County Circuit Court">Hyde County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Jones County Circuit Court">Jones County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Lyman County Circuit Court">Lyman County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Mellette County Circuit Court">Mellette County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Stanley County Circuit Court">Stanley County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Sully County Circuit Court">Sully County Circuit Court</option>
    <option value="Sixth Judicial Circuit - Tripp County Circuit Court">Tripp County Circuit Court</option>

    <option value="Seventh Judicial Circuit - Custer County Circuit Court">Custer County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Fall River County Circuit Court">Fall River County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Oglala Lakota County Circuit Court">Oglala Lakota County Circuit Court</option>
    <option value="Seventh Judicial Circuit - Pennington County Circuit Court">Pennington County Circuit Court</option>
  </>
)}
{secondValue === 'Tennessee' && (
  <>
    <option value="First Judicial District - Carter County Circuit Court">Carter County Circuit Court</option>
    <option value="First Judicial District - Johnson County Circuit Court">Johnson County Circuit Court</option>
    <option value="First Judicial District - Unicoi County Circuit Court">Unicoi County Circuit Court</option>
    <option value="First Judicial District - Washington County Circuit Court">Washington County Circuit Court</option>

    <option value="Second Judicial District - Sullivan County Circuit Court">Sullivan County Circuit Court</option>

    <option value="Third Judicial District - Greene County Circuit Court">Greene County Circuit Court</option>
    <option value="Third Judicial District - Hamblen County Circuit Court">Hamblen County Circuit Court</option>
    <option value="Third Judicial District - Hancock County Circuit Court">Hancock County Circuit Court</option>
    <option value="Third Judicial District - Hawkins County Circuit Court">Hawkins County Circuit Court</option>

    <option value="Fourth Judicial District - Cocke County Circuit Court">Cocke County Circuit Court</option>
    <option value="Fourth Judicial District - Grainger County Circuit Court">Grainger County Circuit Court</option>
    <option value="Fourth Judicial District - Jefferson County Circuit Court">Jefferson County Circuit Court</option>
    <option value="Fourth Judicial District - Sevier County Circuit Court">Sevier County Circuit Court</option>

    <option value="Fifth Judicial District - Blount County Circuit Court">Blount County Circuit Court</option>

    <option value="Sixth Judicial District - Knox County Circuit Court">Knox County Circuit Court</option>

    <option value="Seventh Judicial District - Anderson County Circuit Court">Anderson County Circuit Court</option>

    <option value="Eighth Judicial District - Campbell County Circuit Court">Campbell County Circuit Court</option>
    <option value="Eighth Judicial District - Claiborne County Circuit Court">Claiborne County Circuit Court</option>
    <option value="Eighth Judicial District - Fentress County Circuit Court">Fentress County Circuit Court</option>
    <option value="Eighth Judicial District - Scott County Circuit Court">Scott County Circuit Court</option>
    <option value="Eighth Judicial District - Union County Circuit Court">Union County Circuit Court</option>

    <option value="Ninth Judicial District - Loudon County Circuit Court">Loudon County Circuit Court</option>
    <option value="Ninth Judicial District - Meigs County Circuit Court">Meigs County Circuit Court</option>
    <option value="Ninth Judicial District - Morgan County Circuit Court">Morgan County Circuit Court</option>
    <option value="Ninth Judicial District - Roane County Circuit Court">Roane County Circuit Court</option>

    <option value="Tenth Judicial District - Bradley County Circuit Court">Bradley County Circuit Court</option>
    <option value="Tenth Judicial District - McMinn County Circuit Court">McMinn County Circuit Court</option>
    <option value="Tenth Judicial District - Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Tenth Judicial District - Polk County Circuit Court">Polk County Circuit Court</option>

    <option value="Eleventh Judicial District - Hamilton County Circuit Court">Hamilton County Circuit Court</option>

    <option value="Twelfth Judicial District - Bledsoe County Circuit Court">Bledsoe County Circuit Court</option>
    <option value="Twelfth Judicial District - Franklin County Circuit Court">Franklin County Circuit Court</option>
    <option value="Twelfth Judicial District - Grundy County Circuit Court">Grundy County Circuit Court</option>
    <option value="Twelfth Judicial District - Marion County Circuit Court">Marion County Circuit Court</option>
    <option value="Twelfth Judicial District - Rhea County Circuit Court">Rhea County Circuit Court</option>
    <option value="Twelfth Judicial District - Sequatchie County Circuit Court">Sequatchie County Circuit Court</option>

    <option value="Thirteenth Judicial District - Clay County Circuit Court">Clay County Circuit Court</option>
    <option value="Thirteenth Judicial District - Cumberland County Circuit Court">Cumberland County Circuit Court</option>
    <option value="Thirteenth Judicial District - DeKalb County Circuit Court">DeKalb County Circuit Court</option>
    <option value="Thirteenth Judicial District - Overton County Circuit Court">Overton County Circuit Court</option>
    <option value="Thirteenth Judicial District - Pickett County Circuit Court">Pickett County Circuit Court</option>
    <option value="Thirteenth Judicial District - Putnam County Circuit Court">Putnam County Circuit Court</option>
    <option value="Thirteenth Judicial District - White County Circuit Court">White County Circuit Court</option>

    <option value="Fourteenth Judicial District - Coffee County Circuit Court">Coffee County Circuit Court</option>

    <option value="Fifteenth Judicial District - Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Fifteenth Judicial District - Macon County Circuit Court">Macon County Circuit Court</option>
    <option value="Fifteenth Judicial District - Smith County Circuit Court">Smith County Circuit Court</option>
    <option value="Fifteenth Judicial District - Trousdale County Circuit Court">Trousdale County Circuit Court</option>
    <option value="Fifteenth Judicial District - Wilson County Circuit Court">Wilson County Circuit Court</option>

    <option value="Sixteenth Judicial District - Rutherford County Circuit Court">Rutherford County Circuit Court</option>
    <option value="Sixteenth Judicial District - Cannon County Circuit Court">Cannon County Circuit Court</option>

    <option value="Seventeenth Judicial District - Bedford County Circuit Court">Bedford County Circuit Court</option>
    <option value="Seventeenth Judicial District - Lincoln County Circuit Court">Lincoln County Circuit Court</option>
    <option value="Seventeenth Judicial District - Marshall County Circuit Court">Marshall County Circuit Court</option>
    <option value="Seventeenth Judicial District - Moore County Circuit Court">Moore County Circuit Court</option>

    <option value="Eighteenth Judicial District - Sumner County Circuit Court">Sumner County Circuit Court</option>

    <option value="Nineteenth Judicial District - Montgomery County Circuit Court">Montgomery County Circuit Court</option>
    <option value="Nineteenth Judicial District - Robertson County Circuit Court">Robertson County Circuit Court</option>

    <option value="Twentieth Judicial District - Davidson County Circuit Court">Davidson County Circuit Court</option>

    <option value="Twenty-First Judicial District - Hickman County Circuit Court">Hickman County Circuit Court</option>
    <option value="Twenty-First Judicial District - Lewis County Circuit Court">Lewis County Circuit Court</option>
    <option value="Twenty-First Judicial District - Perry County Circuit Court">Perry County Circuit Court</option>
    <option value="Twenty-First Judicial District - Williamson County Circuit Court">Williamson County Circuit Court</option>

    <option value="Twenty-Second Judicial District - Giles County Circuit Court">Giles County Circuit Court</option>
    <option value="Twenty-Second Judicial District - Lawrence County Circuit Court">Lawrence County Circuit Court</option>
    <option value="Twenty-Second Judicial District - Maury County Circuit Court">Maury County Circuit Court</option>
    <option value="Twenty-Second Judicial District - Wayne County Circuit Court">Wayne County Circuit Court</option>

    <option value="Twenty-Third Judicial District - Cheatham County Circuit Court">Cheatham County Circuit Court</option>
    <option value="Twenty-Third Judicial District - Dickson County Circuit Court">Dickson County Circuit Court</option>
    <option value="Twenty-Third Judicial District - Houston County Circuit Court">Houston County Circuit Court</option>
    <option value="Twenty-Third Judicial District - Humphreys County Circuit Court">Humphreys County Circuit Court</option>
    <option value="Twenty-Third Judicial District - Stewart County Circuit Court">Stewart County Circuit Court</option>

    <option value="Twenty-Fourth Judicial District - Benton County Circuit Court">Benton County Circuit Court</option>
    <option value="Twenty-Fourth Judicial District - Carroll County Circuit Court">Carroll County Circuit Court</option>
    <option value="Twenty-Fourth Judicial District - Decatur County Circuit Court">Decatur County Circuit Court</option>
    <option value="Twenty-Fourth Judicial District - Hardin County Circuit Court">Hardin County Circuit Court</option>
    <option value="Twenty-Fourth Judicial District - Henry County Circuit Court">Henry County Circuit Court</option>

    <option value="Twenty-Fifth Judicial District - Fayette County Circuit Court">Fayette County Circuit Court</option>
    <option value="Twenty-Fifth Judicial District - Hardeman County Circuit Court">Hardeman County Circuit Court</option>
    <option value="Twenty-Fifth Judicial District - Lauderdale County Circuit Court">Lauderdale County Circuit Court</option>
    <option value="Twenty-Fifth Judicial District - McNairy County Circuit Court">McNairy County Circuit Court</option>
    <option value="Twenty-Fifth Judicial District - Tipton County Circuit Court">Tipton County Circuit Court</option>

    <option value="Twenty-Sixth Judicial District - Chester County Circuit Court">Chester County Circuit Court</option>
    <option value="Twenty-Sixth Judicial District - Henderson County Circuit Court">Henderson County Circuit Court</option>
    <option value="Twenty-Sixth Judicial District - Madison County Circuit Court">Madison County Circuit Court</option>

    <option value="Twenty-Seventh Judicial District - Obion County Circuit Court">Obion County Circuit Court</option>
    <option value="Twenty-Seventh Judicial District - Weakley County Circuit Court">Weakley County Circuit Court</option>

    <option value="Twenty-Eighth Judicial District - Crockett County Circuit Court">Crockett County Circuit Court</option>
    <option value="Twenty-Eighth Judicial District - Gibson County Circuit Court">Gibson County Circuit Court</option>

    <option value="Twenty-Ninth Judicial District - Dyer County Circuit Court">Dyer County Circuit Court</option>

    <option value="Thirtieth Judicial District - Shelby County Circuit Court">Shelby County Circuit Court</option>

    <option value="Thirty-First Judicial District - Van Buren County Circuit Court">Van Buren County Circuit Court</option>
    <option value="Thirty-First Judicial District - Warren County Circuit Court">Warren County Circuit Court</option>
  </>
)}
{secondValue === 'Texas' && (
  <>
    <option value="1st Judicial District - Jasper County District Court">1st Judicial District - Jasper County District Court</option>
    <option value="2nd Judicial District - Cherokee County District Court">2nd Judicial District - Cherokee County District Court</option>
    <option value="3rd Judicial District - Anderson County District Court">3rd Judicial District - Anderson County District Court</option>
    <option value="4th Judicial District - Rusk County District Court">4th Judicial District - Rusk County District Court</option>
    <option value="5th Judicial District - Bowie County District Court">5th Judicial District - Bowie County District Court</option>
    <option value="6th Judicial District - Lamar County District Court">6th Judicial District - Lamar County District Court</option>
    <option value="7th Judicial District - Smith County District Court">7th Judicial District - Smith County District Court</option>
    <option value="8th Judicial District - Delta, Franklin, Hopkins, and Rains Counties District Court">8th Judicial District - Delta, Franklin, Hopkins, and Rains Counties District Court</option>
    <option value="9th Judicial District - Montgomery County District Court">9th Judicial District - Montgomery County District Court</option>
    <option value="10th Judicial District - Galveston County District Court">10th Judicial District - Galveston County District Court</option>
    <option value="11th Judicial District - Harris County District Court">11th Judicial District - Harris County District Court</option>
    <option value="12th Judicial District - Grimes, Madison, and Walker Counties District Court">12th Judicial District - Grimes, Madison, and Walker Counties District Court</option>
    <option value="13th Judicial District - Navarro County District Court">13th Judicial District - Navarro County District Court</option>
    <option value="14th Judicial District - Dallas County District Court">14th Judicial District - Dallas County District Court</option>
    <option value="15th Judicial District - Grayson County District Court">15th Judicial District - Grayson County District Court</option>
    <option value="16th Judicial District - Denton County District Court">16th Judicial District - Denton County District Court</option>
    <option value="17th Judicial District - Tarrant County District Court">17th Judicial District - Tarrant County District Court</option>
    <option value="18th Judicial District - Johnson and Somervell Counties District Court">18th Judicial District - Johnson and Somervell Counties District Court</option>
    <option value="19th Judicial District - McLennan County District Court">19th Judicial District - McLennan County District Court</option>
    <option value="20th Judicial District - Milam County District Court">20th Judicial District - Milam County District Court</option>
    <option value="21st Judicial District - Bastrop, Burleson, Lee, and Washington Counties District Court">21st Judicial District - Bastrop, Burleson, Lee, and Washington Counties District Court</option>
    <option value="22nd Judicial District - Comal and Hays Counties District Court">22nd Judicial District - Comal and Hays Counties District Court</option>
    <option value="23rd Judicial District - Brazoria, Matagorda, and Wharton Counties District Court">23rd Judicial District - Brazoria, Matagorda, and Wharton Counties District Court</option>
    <option value="24th Judicial District - Calhoun, DeWitt, Goliad, Jackson, Refugio, and Victoria Counties District Court">24th Judicial District - Calhoun, DeWitt, Goliad, Jackson, Refugio, and Victoria Counties District Court</option>
    <option value="25th Judicial District - Colorado, Gonzales, Guadalupe, and Lavaca Counties District Court">25th Judicial District - Colorado, Gonzales, Guadalupe, and Lavaca Counties District Court</option>
    <option value="26th Judicial District - Williamson County District Court">26th Judicial District - Williamson County District Court</option>
    <option value="27th Judicial District - Bell and Lampasas Counties District Court">27th Judicial District - Bell and Lampasas Counties District Court</option>
    <option value="28th Judicial District - Nueces County District Court">28th Judicial District - Nueces County District Court</option>
    <option value="29th Judicial District - Palo Pinto County District Court">29th Judicial District - Palo Pinto County District Court</option>
    <option value="30th Judicial District - Wichita County District Court">30th Judicial District - Wichita County District Court</option>
    <option value="31st Judicial District - Gray, Hemphill, Lipscomb, Roberts, and Wheeler Counties District Court">31st Judicial District - Gray, Hemphill, Lipscomb, Roberts, and Wheeler Counties District Court</option>
    <option value="32nd Judicial District - Fisher, Mitchell, and Nolan Counties District Court">32nd Judicial District - Fisher, Mitchell, and Nolan Counties District Court</option>
    <option value="33rd Judicial District - Blanco, Burnet, Llano, and San Saba Counties District Court">33rd Judicial District - Blanco, Burnet, Llano, and San Saba Counties District Court</option>
    <option value="34th Judicial District - Culberson, El Paso, and Hudspeth Counties District Court">34th Judicial District - Culberson, El Paso, and Hudspeth Counties District Court</option>
    <option value="35th Judicial District - Brown and Mills Counties District Court">35th Judicial District - Brown and Mills Counties District Court</option>
    <option value="36th Judicial District - Aransas, Bee, Live Oak, McMullen, and San Patricio Counties District Court">36th Judicial District - Aransas, Bee, Live Oak, McMullen, and San Patricio Counties District Court</option>
    <option value="37th Judicial District - Bexar County District Court">37th Judicial District - Bexar County District Court</option>
    <option value="38th Judicial District - Medina, Real, and Uvalde Counties District Court">38th Judicial District - Medina, Real, and Uvalde Counties District Court</option>
    <option value="39th Judicial District - Haskell, Kent, Stonewall, and Throckmorton Counties District Court">39th Judicial District - Haskell, Kent, Stonewall, and Throckmorton Counties District Court</option>
    <option value="40th Judicial District - Ellis County District Court">40th Judicial District - Ellis County District Court</option>
    <option value="41st Judicial District - El Paso County District Court">41st Judicial District - El Paso County District Court</option>
    <option value="42nd Judicial District - Callahan, Coleman, and Taylor Counties District Court">42nd Judicial District - Callahan, Coleman, and Taylor Counties District Court</option>
    <option value="43rd Judicial District - Parker County District Court">43rd Judicial District - Parker County District Court</option>
    <option value="44th Judicial District - Dallas County District Court">44th Judicial District - Dallas County District Court</option>
    <option value="45th Judicial District - Bexar County District Court">45th Judicial District - Bexar County District Court</option>
    <option value="46th Judicial District - Foard, Hardeman, and Wilbarger Counties District Court">46th Judicial District - Foard, Hardeman, and Wilbarger Counties District Court</option>
    <option value="47th Judicial District - Armstrong, Potter, and Randall Counties District Court">47th Judicial District - Armstrong, Potter, and Randall Counties District Court</option>
    <option value="48th Judicial District - Tarrant County District Court">48th Judicial District - Tarrant County District Court</option>
    <option value="49th Judicial District - Jim Hogg, Webb, and Zapata Counties District Court">49th Judicial District - Jim Hogg, Webb, and Zapata Counties District Court</option>
    <option value="50th Judicial District - Baylor, Cottle, King, and Knox Counties District Court">50th Judicial District - Baylor, Cottle, King, and Knox Counties District Court</option>
    <option value="51st Judicial District - Coke, Irion, Schleicher, Sterling, and Tom Green Counties District Court">51st Judicial District - Coke, Irion, Schleicher, Sterling, and Tom Green Counties District Court</option>
    <option value="52nd Judicial District - Coryell County District Court">52nd Judicial District - Coryell County District Court</option>
    <option value="53rd Judicial District - Travis County District Court">53rd Judicial District - Travis County District Court</option>
    <option value="54th Judicial District - McLennan County District Court">54th Judicial District - McLennan County District Court</option>
    <option value="55th Judicial District - Harris County District Court">55th Judicial District - Harris County District Court</option>
    <option value="56th Judicial District - Galveston County District Court">56th Judicial District - Galveston County District Court</option>
    <option value="57th Judicial District - Bexar County District Court">57th Judicial District - Bexar County District Court</option>
    <option value="58th Judicial District - Jefferson County District Court">58th Judicial District - Jefferson County District Court</option>
    <option value="59th Judicial District - Grayson County District Court">59th Judicial District - Grayson County District Court</option>
    <option value="60th Judicial District - Jefferson County District Court">60th Judicial District - Jefferson County District Court</option>
    <option value="61st Judicial District - Harris County District Court">61st Judicial District - Harris County District Court</option>
    <option value="62nd Judicial District - Delta, Franklin, and Hopkins Counties District Court">62nd Judicial District - Delta, Franklin, and Hopkins Counties District Court</option>
    <option value="63rd Judicial District - Kinney, Terrell, and Val Verde Counties District Court">63rd Judicial District - Kinney, Terrell, and Val Verde Counties District Court</option>
    <option value="64th Judicial District - Castro, Hale, and Swisher Counties District Court">64th Judicial District - Castro, Hale, and Swisher Counties District Court</option>
    <option value="65th Judicial District - El Paso County District Court">65th Judicial District - El Paso County District Court</option>
    <option value="66th Judicial District - Hill County District Court">66th Judicial District - Hill County District Court</option>
    <option value="67th Judicial District - Tarrant County District Court">67th Judicial District - Tarrant County District Court</option>
    <option value="68th Judicial District - Dallas County District Court">68th Judicial District - Dallas County District Court</option>
    <option value="69th Judicial District - Dallam, Hartley, Moore, and Sherman Counties District Court">69th Judicial District - Dallam, Hartley, Moore, and Sherman Counties District Court</option>
    <option value="70th Judicial District - Ector County District Court">70th Judicial District - Ector County District Court</option>
    <option value="71st Judicial District - Harrison County District Court">71st Judicial District - Harrison County District Court</option>
    <option value="72nd Judicial District - Crosby and Lubbock Counties District Court">72nd Judicial District - Crosby and Lubbock Counties District Court</option>
    <option value="73rd Judicial District - Bexar County District Court">73rd Judicial District - Bexar County District Court</option>
    <option value="74th Judicial District - McLennan County District Court">74th Judicial District - McLennan County District Court</option>
    <option value="75th Judicial District - Liberty County District Court">75th Judicial District - Liberty County District Court</option>
    <option value="76th Judicial District - Titus and Camp Counties District Court">76th Judicial District - Titus and Camp Counties District Court</option>
    <option value="77th Judicial District - Freestone and Limestone Counties District Court">77th Judicial District - Freestone and Limestone Counties District Court</option>
    <option value="78th Judicial District - Wichita County District Court">78th Judicial District - Wichita County District Court</option>
    <option value="79th Judicial District - Brooks and Jim Wells Counties District Court">79th Judicial District - Brooks and Jim Wells Counties District Court</option>
    <option value="80th Judicial District - Harris County District Court">80th Judicial District - Harris County District Court</option>
    <option value="81st Judicial District - Atascosa, Frio, Karnes, La Salle, and Wilson Counties District Court">81st Judicial District - Atascosa, Frio, Karnes, La Salle, and Wilson Counties District Court</option>
    <option value="82nd Judicial District - Falls and Robertson Counties District Court">82nd Judicial District - Falls and Robertson Counties District Court</option>
    <option value="83rd Judicial District - Pecos, Terrell, and Val Verde Counties District Court">83rd Judicial District - Pecos, Terrell, and Val Verde Counties District Court</option>
    <option value="84th Judicial District - Hansford, Hutchinson, and Ochiltree Counties District Court">84th Judicial District - Hansford, Hutchinson, and Ochiltree Counties District Court</option>
    <option value="85th Judicial District - Brazos County District Court">85th Judicial District - Brazos County District Court</option>
    <option value="86th Judicial District - Kaufman County District Court">86th Judicial District - Kaufman County District Court</option>
    <option value="87th Judicial District - Anderson County District Court">87th Judicial District - Anderson County District Court</option>
    <option value="88th Judicial District - Hardin and Tyler Counties District Court">88th Judicial District - Hardin and Tyler Counties District Court</option>
    <option value="89th Judicial District - Wichita County District Court">89th Judicial District - Wichita County District Court</option>
    <option value="90th Judicial District - Stephens and Young Counties District Court">90th Judicial District - Stephens and Young Counties District Court</option>
    <option value="91st Judicial District - Eastland County District Court">91st Judicial District - Eastland County District Court</option>
    <option value="92nd Judicial District - Hidalgo County District Court">92nd Judicial District - Hidalgo County District Court</option>
    <option value="93rd Judicial District - Hidalgo County District Court">93rd Judicial District - Hidalgo County District Court</option>
    <option value="94th Judicial District - Nueces County District Court">94th Judicial District - Nueces County District Court</option>
    <option value="95th Judicial District - Dallas County District Court">95th Judicial District - Dallas County District Court</option>
    <option value="96th Judicial District - Tarrant County District Court">96th Judicial District - Tarrant County District Court</option>
    <option value="97th Judicial District - Archer, Clay, and Montague Counties District Court">97th Judicial District - Archer, Clay, and Montague Counties District Court</option>
    <option value="98th Judicial District - Travis County District Court">98th Judicial District - Travis County District Court</option>
    <option value="99th Judicial District - Lubbock County District Court">99th Judicial District - Lubbock County District Court</option>
    <option value="100th Judicial District - Carson, Childress, Collingsworth, Donley, and Hall Counties District Court">100th Judicial District - Carson, Childress, Collingsworth, Donley, and Hall Counties District Court</option>
    <option value="101st Judicial District - Dallas County District Court">101st Judicial District - Dallas County District Court</option>
    <option value="102nd Judicial District - Bowie and Red River Counties District Court">102nd Judicial District - Bowie and Red River Counties District Court</option>
    <option value="103rd Judicial District - Cameron County District Court">103rd Judicial District - Cameron County District Court</option>
    <option value="104th Judicial District - Cameron County District Court">104th Judicial District - Cameron County District Court</option>
    <option value="105th Judicial District - Kenedy, Kleberg, and Nueces Counties District Court">105th Judicial District - Kenedy, Kleberg, and Nueces Counties District Court</option>
    <option value="106th Judicial District - Dawson, Gaines, Garza, and Lynn Counties District Court">106th Judicial District - Dawson, Gaines, Garza, and Lynn Counties District Court</option>
    <option value="107th Judicial District - Cameron County District Court">107th Judicial District - Cameron County District Court</option>
    <option value="108th Judicial District - Potter County District Court">108th Judicial District - Potter County District Court</option>
    <option value="109th Judicial District - Andrews, Crane, Ector, and Ward Counties District Court">109th Judicial District - Andrews, Crane, Ector, and Ward Counties District Court</option>
    <option value="110th Judicial District - Briscoe, Dickens, Floyd, and Motley Counties District Court">110th Judicial District - Briscoe, Dickens, Floyd, and Motley Counties District Court</option>
    <option value="111th Judicial District - Webb County District Court">111th Judicial District - Webb County District Court</option>
    <option value="112th Judicial District - Crockett, Pecos, Reagan, Sutton, and Upton Counties District Court">112th Judicial District - Crockett, Pecos, Reagan, Sutton, and Upton Counties District Court</option>
    <option value="113th Judicial District - Harris County District Court">113th Judicial District - Harris County District Court</option>
    <option value="114th Judicial District - Smith County District Court">114th Judicial District - Smith County District Court</option>
    <option value="115th Judicial District - Marion and Upshur Counties District Court">115th Judicial District - Marion and Upshur Counties District Court</option>
    <option value="116th Judicial District - Dallas County District Court">116th Judicial District - Dallas County District Court</option>
    <option value="117th Judicial District - Nueces County District Court">117th Judicial District - Nueces County District Court</option>
    <option value="118th Judicial District - Glasscock, Howard, and Martin Counties District Court">118th Judicial District - Glasscock, Howard, and Martin Counties District Court</option>
    <option value="119th Judicial District - Concho, Runnels, and Tom Green Counties District Court">119th Judicial District - Concho, Runnels, and Tom Green Counties District Court</option>
    <option value="120th Judicial District - El Paso County District Court">120th Judicial District - El Paso County District Court</option>
    <option value="121st Judicial District - Terry and Yoakum Counties District Court">121st Judicial District - Terry and Yoakum Counties District Court</option>
    <option value="122nd Judicial District - Galveston County District Court">122nd Judicial District - Galveston County District Court</option>
    <option value="123rd Judicial District - Panola and Shelby Counties District Court">123rd Judicial District - Panola and Shelby Counties District Court</option>
    <option value="124th Judicial District - Gregg County District Court">124th Judicial District - Gregg County District Court</option>
    <option value="125th Judicial District - Harris County District Court">125th Judicial District - Harris County District Court</option>
    <option value="126th Judicial District - Travis County District Court">126th Judicial District - Travis County District Court</option>
    <option value="127th Judicial District - Harris County District Court">127th Judicial District - Harris County District Court</option>
    <option value="128th Judicial District - Orange County District Court">128th Judicial District - Orange County District Court</option>
    <option value="129th Judicial District - Harris County District Court">129th Judicial District - Harris County District Court</option>
    <option value="130th Judicial District - Matagorda County District Court">130th Judicial District - Matagorda County District Court</option>
    <option value="131st Judicial District - Bexar County District Court">131st Judicial District - Bexar County District Court</option>
    <option value="132nd Judicial District - Scurry, Borden, and Mitchell Counties District Court">132nd Judicial District - Scurry, Borden, and Mitchell Counties District Court</option>
    <option value="133rd Judicial District - Harris County District Court">133rd Judicial District - Harris County District Court</option>
    <option value="134th Judicial District - Dallas County District Court">134th Judicial District - Dallas County District Court</option>
    <option value="135th Judicial District - DeWitt, Goliad, Jackson, Refugio, and Victoria Counties District Court">135th Judicial District - DeWitt, Goliad, Jackson, Refugio, and Victoria Counties District Court</option>
    <option value="136th Judicial District - Jefferson County District Court">136th Judicial District - Jefferson County District Court</option>
    <option value="137th Judicial District - Lubbock County District Court">137th Judicial District - Lubbock County District Court</option>
    <option value="138th Judicial District - Cameron County District Court">138th Judicial District - Cameron County District Court</option>
    <option value="139th Judicial District - Hidalgo County District Court">139th Judicial District - Hidalgo County District Court</option>
    <option value="140th Judicial District - Lubbock County District Court">140th Judicial District - Lubbock County District Court</option>
    <option value="141st Judicial District - Tarrant County District Court">141st Judicial District - Tarrant County District Court</option>
    <option value="142nd Judicial District - Midland County District Court">142nd Judicial District - Midland County District Court</option>
    <option value="143rd Judicial District - Loving, Reeves, and Ward Counties District Court">143rd Judicial District - Loving, Reeves, and Ward Counties District Court</option>
    <option value="144th Judicial District - Bexar County District Court">144th Judicial District - Bexar County District Court</option>
    <option value="145th Judicial District - Nacogdoches County District Court">145th Judicial District - Nacogdoches County District Court</option>
    <option value="146th Judicial District - Bell County District Court">146th Judicial District - Bell County District Court</option>
    <option value="147th Judicial District - Travis County District Court">147th Judicial District - Travis County District Court</option>
    <option value="148th Judicial District - Nueces County District Court">148th Judicial District - Nueces County District Court</option>
    <option value="149th Judicial District - Brazoria County District Court">149th Judicial District - Brazoria County District Court</option>
    <option value="150th Judicial District - Bexar County District Court">150th Judicial District - Bexar County District Court</option>
    <option value="151st Judicial District - Harris County District Court">151st Judicial District - Harris County District Court</option>
    <option value="152nd Judicial District - Harris County District Court">152nd Judicial District - Harris County District Court</option>
    <option value="153rd Judicial District - Tarrant County District Court">153rd Judicial District - Tarrant County District Court</option>
    <option value="154th Judicial District - Lamb, Bailey, Cochran, and Hockley Counties District Court">154th Judicial District - Lamb, Bailey, Cochran, and Hockley Counties District Court</option>
    <option value="155th Judicial District - Austin, Fayette, and Waller Counties District Court">155th Judicial District - Austin, Fayette, and Waller Counties District Court</option>
    <option value="156th Judicial District - Aransas, Bee, Live Oak, McMullen, and San Patricio Counties District Court">156th Judicial District - Aransas, Bee, Live Oak, McMullen, and San Patricio Counties District Court</option>
    <option value="157th Judicial District - Harris County District Court">157th Judicial District - Harris County District Court</option>
    <option value="158th Judicial District - Denton County District Court">158th Judicial District - Denton County District Court</option>
    <option value="159th Judicial District - Angelina County District Court">159th Judicial District - Angelina County District Court</option>
    <option value="160th Judicial District - Dallas County District Court">160th Judicial District - Dallas County District Court</option>
    <option value="161st Judicial District - Ector County District Court">161st Judicial District - Ector County District Court</option>
    <option value="162nd Judicial District - Dallas County District Court">162nd Judicial District - Dallas County District Court</option>
    <option value="163rd Judicial District - Orange County District Court">163rd Judicial District - Orange County District Court</option>
    <option value="164th Judicial District - Harris County District Court">164th Judicial District - Harris County District Court</option>
    <option value="165th Judicial District - Harris County District Court">165th Judicial District - Harris County District Court</option>
    <option value="166th Judicial District - Bexar County District Court">166th Judicial District - Bexar County District Court</option>
    <option value="167th Judicial District - Travis County District Court">167th Judicial District - Travis County District Court</option>
    <option value="168th Judicial District - El Paso County District Court">168th Judicial District - El Paso County District Court</option>
    <option value="169th Judicial District - Bell County District Court">169th Judicial District - Bell County District Court</option>
    <option value="170th Judicial District - McLennan County District Court">170th Judicial District - McLennan County District Court</option>
    <option value="171st Judicial District - El Paso County District Court">171st Judicial District - El Paso County District Court</option>
    <option value="172nd Judicial District - Jefferson County District Court">172nd Judicial District - Jefferson County District Court</option>
    <option value="173rd Judicial District - Henderson County District Court">173rd Judicial District - Henderson County District Court</option>
    <option value="174th Judicial District - Harris County District Court">174th Judicial District - Harris County District Court</option>
    <option value="175th Judicial District - Bexar County District Court">175th Judicial District - Bexar County District Court</option>
    <option value="176th Judicial District - Harris County District Court">176th Judicial District - Harris County District Court</option>
    <option value="177th Judicial District - Harris County District Court">177th Judicial District - Harris County District Court</option>
    <option value="178th Judicial District - Harris County District Court">178th Judicial District - Harris County District Court</option>
    <option value="179th Judicial District - Harris County District Court">179th Judicial District - Harris County District Court</option>
    <option value="180th Judicial District - Harris County District Court">180th Judicial District - Harris County District Court</option>
    <option value="181st Judicial District - Potter and Randall Counties District Court">181st Judicial District - Potter and Randall Counties District Court</option>
    <option value="182nd Judicial District - Harris County District Court">182nd Judicial District - Harris County District Court</option>
    <option value="183rd Judicial District - Harris County District Court">183rd Judicial District - Harris County District Court</option>
    <option value="184th Judicial District - Harris County District Court">184th Judicial District - Harris County District Court</option>
    <option value="185th Judicial District - Harris County District Court">185th Judicial District - Harris County District Court</option>
    <option value="186th Judicial District - Bexar County District Court">186th Judicial District - Bexar County District Court</option>
    <option value="187th Judicial District - Bexar County District Court">187th Judicial District - Bexar County District Court</option>
    <option value="188th Judicial District - Gregg County District Court">188th Judicial District - Gregg County District Court</option>
    <option value="189th Judicial District - Harris County District Court">189th Judicial District - Harris County District Court</option>
    <option value="190th Judicial District - Harris County District Court">190th Judicial District - Harris County District Court</option>
    <option value="191st Judicial District - Dallas County District Court">191st Judicial District - Dallas County District Court</option>
    <option value="192nd Judicial District - Dallas County District Court">192nd Judicial District - Dallas County District Court</option>
    <option value="193rd Judicial District - Dallas County District Court">193rd Judicial District - Dallas County District Court</option>
    <option value="194th Judicial District - Dallas County District Court">194th Judicial District - Dallas County District Court</option>
    <option value="195th Judicial District - Dallas County District Court">195th Judicial District - Dallas County District Court</option>
    <option value="196th Judicial District - Hunt County District Court">196th Judicial District - Hunt County District Court</option>
    <option value="197th Judicial District - Cameron County District Court">197th Judicial District - Cameron County District Court</option>
    <option value="198th Judicial District - Bandera and Kerr Counties District Court">198th Judicial District - Bandera and Kerr Counties District Court</option>
    <option value="199th Judicial District - Collin County District Court">199th Judicial District - Collin County District Court</option>
    <option value="200th Judicial District - Travis County District Court">200th Judicial District - Travis County District Court</option>
    <option value="201st Judicial District - Travis County District Court">201st Judicial District - Travis County District Court</option>
    <option value="202nd Judicial District - Bowie County District Court">202nd Judicial District - Bowie County District Court</option>
    <option value="203rd Judicial District - Dallas County District Court">203rd Judicial District - Dallas County District Court</option>
    <option value="204th Judicial District - Dallas County District Court">204th Judicial District - Dallas County District Court</option>
    <option value="205th Judicial District - Culberson and Hudspeth Counties District Court">205th Judicial District - Culberson and Hudspeth Counties District Court</option>
    <option value="206th Judicial District - Hays County District Court">206th Judicial District - Hays County District Court</option>
    <option value="207th Judicial District - Comal County District Court">207th Judicial District - Comal County District Court</option>
    <option value="208th Judicial District - Harris County District Court">208th Judicial District - Harris County District Court</option>
    <option value="209th Judicial District - Harris County District Court">209th Judicial District - Harris County District Court</option>
    <option value="210th Judicial District - El Paso County District Court">210th Judicial District - El Paso County District Court</option>
    <option value="211th Judicial District - Denton County District Court">211th Judicial District - Denton County District Court</option>
    <option value="212th Judicial District - Galveston County District Court">212th Judicial District - Galveston County District Court</option>
    <option value="213th Judicial District - Tarrant County District Court">213th Judicial District - Tarrant County District Court</option>
    <option value="214th Judicial District - Nueces County District Court">214th Judicial District - Nueces County District Court</option>
    <option value="215th Judicial District - Harris County District Court">215th Judicial District - Harris County District Court</option>
    <option value="216th Judicial District - Gillespie and Kerr Counties District Court">216th Judicial District - Gillespie and Kerr Counties District Court</option>
    <option value="217th Judicial District - Angelina County District Court">217th Judicial District - Angelina County District Court</option>
    <option value="218th Judicial District - Atascosa, Frio, Karnes, La Salle, and Wilson Counties District Court">218th Judicial District - Atascosa, Frio, Karnes, La Salle, and Wilson Counties District Court</option>
    <option value="219th Judicial District - Collin County District Court">219th Judicial District - Collin County District Court</option>
    <option value="220th Judicial District - Bosque, Comanche, and Hamilton Counties District Court">220th Judicial District - Bosque, Comanche, and Hamilton Counties District Court</option>
    <option value="221st Judicial District - Montgomery County District Court">221st Judicial District - Montgomery County District Court</option>
    <option value="222nd Judicial District - Deaf Smith and Oldham Counties District Court">222nd Judicial District - Deaf Smith and Oldham Counties District Court</option>
    <option value="223rd Judicial District - Gray County District Court">223rd Judicial District - Gray County District Court</option>
    <option value="224th Judicial District - Bexar County District Court">224th Judicial District - Bexar County District Court</option>
    <option value="225th Judicial District - Bexar County District Court">225th Judicial District - Bexar County District Court</option>
    <option value="226th Judicial District - Bexar County District Court">226th Judicial District - Bexar County District Court</option>
    <option value="227th Judicial District - Bexar County District Court">227th Judicial District - Bexar County District Court</option>
    <option value="228th Judicial District - Harris County District Court">228th Judicial District - Harris County District Court</option>
    <option value="229th Judicial District - Duval, Jim Hogg, and Starr Counties District Court">229th Judicial District - Duval, Jim Hogg, and Starr Counties District Court</option>
    <option value="230th Judicial District - Harris County District Court">230th Judicial District - Harris County District Court</option>
    <option value="231st Judicial District - Tarrant County District Court">231st Judicial District - Tarrant County District Court</option>
    <option value="232nd Judicial District - Harris County District Court">232nd Judicial District - Harris County District Court</option>
    <option value="233rd Judicial District - Tarrant County District Court">233rd Judicial District - Tarrant County District Court</option>
    <option value="234th Judicial District - Harris County District Court">234th Judicial District - Harris County District Court</option>
    <option value="235th Judicial District - Cooke County District Court">235th Judicial District - Cooke County District Court</option>
    <option value="236th Judicial District - Tarrant County District Court">236th Judicial District - Tarrant County District Court</option>
    <option value="237th Judicial District - Lubbock County District Court">237th Judicial District - Lubbock County District Court</option>
    <option value="238th Judicial District - Midland County District Court">238th Judicial District - Midland County District Court</option>
    <option value="239th Judicial District - Brazoria County District Court">239th Judicial District - Brazoria County District Court</option>
    <option value="240th Judicial District - Fort Bend County District Court">240th Judicial District - Fort Bend County District Court</option>
    <option value="241st Judicial District - Smith County District Court">241st Judicial District - Smith County District Court</option>
    <option value="242nd Judicial District - Castro and Swisher Counties District Court">242nd Judicial District - Castro and Swisher Counties District Court</option>
    <option value="243rd Judicial District - El Paso County District Court">243rd Judicial District - El Paso County District Court</option>
    <option value="244th Judicial District - Ector County District Court">244th Judicial District - Ector County District Court</option>
    <option value="245th Judicial District - Harris County District Court">245th Judicial District - Harris County District Court</option>
    <option value="246th Judicial District - Harris County District Court">246th Judicial District - Harris County District Court</option>
    <option value="247th Judicial District - Harris County District Court">247th Judicial District - Harris County District Court</option>
    <option value="248th Judicial District - Harris County District Court">248th Judicial District - Harris County District Court</option>
    <option value="249th Judicial District - Johnson and Somervell Counties District Court">249th Judicial District - Johnson and Somervell Counties District Court</option>
    <option value="250th Judicial District - Travis County District Court">250th Judicial District - Travis County District Court</option>
    <option value="251st Judicial District - Potter and Randall Counties District Court">251st Judicial District - Potter and Randall Counties District Court</option>
    <option value="252nd Judicial District - Jefferson County District Court">252nd Judicial District - Jefferson County District Court</option>
    <option value="253rd Judicial District - Chambers and Liberty Counties District Court">253rd Judicial District - Chambers and Liberty Counties District Court</option>
    <option value="254th Judicial District - Dallas County District Court">254th Judicial District - Dallas County District Court</option>
    <option value="255th Judicial District - Dallas County District Court">255th Judicial District - Dallas County District Court</option>
    <option value="256th Judicial District - Dallas County District Court">256th Judicial District - Dallas County District Court</option>
    <option value="257th Judicial District - Harris County District Court">257th Judicial District - Harris County District Court</option>
    <option value="258th Judicial District - Polk, San Jacinto, and Trinity Counties District Court">258th Judicial District - Polk, San Jacinto, and Trinity Counties District Court</option>
    <option value="259th Judicial District - Jones and Shackelford Counties District Court">259th Judicial District - Jones and Shackelford Counties District Court</option>
    <option value="260th Judicial District - Orange County District Court">260th Judicial District - Orange County District Court</option>
    <option value="261st Judicial District - Travis County District Court">261st Judicial District - Travis County District Court</option>
    <option value="262nd Judicial District - Harris County District Court">262nd Judicial District - Harris County District Court</option>
    <option value="263rd Judicial District - Harris County District Court">263rd Judicial District - Harris County District Court</option>
    <option value="264th Judicial District - Bell County District Court">264th Judicial District - Bell County District Court</option>
    <option value="265th Judicial District - Dallas County District Court">265th Judicial District - Dallas County District Court</option>
    <option value="266th Judicial District - Erath County District Court">266th Judicial District - Erath County District Court</option>
    <option value="267th Judicial District - Victoria County District Court">267th Judicial District - Victoria County District Court</option>
    <option value="268th Judicial District - Fort Bend County District Court">268th Judicial District - Fort Bend County District Court</option>
    <option value="269th Judicial District - Harris County District Court">269th Judicial District - Harris County District Court</option>
    <option value="270th Judicial District - Harris County District Court">270th Judicial District - Harris County District Court</option>
    <option value="271st Judicial District - Jack and Wise Counties District Court">271st Judicial District - Jack and Wise Counties District Court</option>
    <option value="272nd Judicial District - Brazos County District Court">272nd Judicial District - Brazos County District Court</option>
    <option value="273rd Judicial District - Sabine, San Augustine, and Shelby Counties District Court">273rd Judicial District - Sabine, San Augustine, and Shelby Counties District Court</option>
    <option value="274th Judicial District - Caldwell, Hays, and Comal Counties District Court">274th Judicial District - Caldwell, Hays, and Comal Counties District Court</option>
    <option value="275th Judicial District - Hidalgo County District Court">275th Judicial District - Hidalgo County District Court</option>
    <option value="276th Judicial District - Camp, Marion, and Morris Counties District Court">276th Judicial District - Camp, Marion, and Morris Counties District Court</option>
    <option value="277th Judicial District - Williamson County District Court">277th Judicial District - Williamson County District Court</option>
    <option value="278th Judicial District - Leon, Madison, and Walker Counties District Court">278th Judicial District - Leon, Madison, and Walker Counties District Court</option>
    <option value="279th Judicial District - Jefferson County District Court">279th Judicial District - Jefferson County District Court</option>
    <option value="280th Judicial District - Harris County District Court">280th Judicial District - Harris County District Court</option>
    <option value="281st Judicial District - Harris County District Court">281st Judicial District - Harris County District Court</option>
    <option value="282nd Judicial District - Dallas County District Court">282nd Judicial District - Dallas County District Court</option>
    <option value="283rd Judicial District - Dallas County District Court">283rd Judicial District - Dallas County District Court</option>
    <option value="284th Judicial District - Montgomery County District Court">284th Judicial District - Montgomery County District Court</option>
    <option value="285th Judicial District - Bexar County District Court">285th Judicial District - Bexar County District Court</option>
    <option value="286th Judicial District - Hockley and Cochran Counties District Court">286th Judicial District - Hockley and Cochran Counties District Court</option>
    <option value="287th Judicial District - Bailey, Lamb, and Cochran Counties District Court">287th Judicial District - Bailey, Lamb, and Cochran Counties District Court</option>
    <option value="288th Judicial District - Bexar County District Court">288th Judicial District - Bexar County District Court</option>
    <option value="289th Judicial District - Bexar County District Court">289th Judicial District - Bexar County District Court</option>
    <option value="290th Judicial District - Bexar County District Court">290th Judicial District - Bexar County District Court</option>
    <option value="291st Judicial District - Bexar County District Court">291st Judicial District - Bexar County District Court</option>
    <option value="292nd Judicial District - Dallas County District Court">292nd Judicial District - Dallas County District Court</option>
    <option value="293rd Judicial District - Henderson County District Court">293rd Judicial District - Henderson County District Court</option>
    <option value="294th Judicial District - Van Zandt and Wood Counties District Court">294th Judicial District - Van Zandt and Wood Counties District Court</option>
    <option value="295th Judicial District - Harris County District Court">295th Judicial District - Harris County District Court</option>
    <option value="296th Judicial District - Collin County District Court">296th Judicial District - Collin County District Court</option>
    <option value="297th Judicial District - Tarrant County District Court">297th Judicial District - Tarrant County District Court</option>
    <option value="298th Judicial District - Dallas County District Court">298th Judicial District - Dallas County District Court</option>
    <option value="299th Judicial District - Travis County District Court">299th Judicial District - Travis County District Court</option>
    <option value="300th Judicial District - Brazoria County District Court">300th Judicial District - Brazoria County District Court</option>

    <option value="301st Judicial District - Dallas County District Court">301st Judicial District - Dallas County District Court</option>
    <option value="302nd Judicial District - Dallas County District Court">302nd Judicial District - Dallas County District Court</option>
    <option value="303rd Judicial District - Dallas County District Court">303rd Judicial District - Dallas County District Court</option>
    <option value="304th Judicial District - Dallas County District Court">304th Judicial District - Dallas County District Court</option>
    <option value="305th Judicial District - Dallas County District Court">305th Judicial District - Dallas County District Court</option>
    <option value="306th Judicial District - Galveston County District Court">306th Judicial District - Galveston County District Court</option>
    <option value="307th Judicial District - Gregg County District Court">307th Judicial District - Gregg County District Court</option>
    <option value="308th Judicial District - Harris County District Court">308th Judicial District - Harris County District Court</option>
    <option value="309th Judicial District - Harris County District Court">309th Judicial District - Harris County District Court</option>
    <option value="310th Judicial District - Harris County District Court">310th Judicial District - Harris County District Court</option>
    <option value="311th Judicial District - Harris County District Court">311th Judicial District - Harris County District Court</option>
    <option value="312th Judicial District - Harris County District Court">312th Judicial District - Harris County District Court</option>
    <option value="313th Judicial District - Harris County District Court">313th Judicial District - Harris County District Court</option>
    <option value="314th Judicial District - Harris County District Court">314th Judicial District - Harris County District Court</option>
    <option value="315th Judicial District - Harris County District Court">315th Judicial District - Harris County District Court</option>
    <option value="316th Judicial District - Hutchinson County District Court">316th Judicial District - Hutchinson County District Court</option>
    <option value="317th Judicial District - Jefferson County District Court">317th Judicial District - Jefferson County District Court</option>
    <option value="318th Judicial District - Midland County District Court">318th Judicial District - Midland County District Court</option>
    <option value="319th Judicial District - Nueces County District Court">319th Judicial District - Nueces County District Court</option>
    <option value="320th Judicial District - Potter County District Court">320th Judicial District - Potter County District Court</option>
    <option value="321st Judicial District - Smith County District Court">321st Judicial District - Smith County District Court</option>
    <option value="322nd Judicial District - Tarrant County District Court">322nd Judicial District - Tarrant County District Court</option>
    <option value="323rd Judicial District - Tarrant County District Court">323rd Judicial District - Tarrant County District Court</option>
    <option value="324th Judicial District - Tarrant County District Court">324th Judicial District - Tarrant County District Court</option>
    <option value="325th Judicial District - Tarrant County District Court">325th Judicial District - Tarrant County District Court</option>
    <option value="326th Judicial District - Taylor County District Court">326th Judicial District - Taylor County District Court</option>
    <option value="327th Judicial District - El Paso County District Court">327th Judicial District - El Paso County District Court</option>
    <option value="328th Judicial District - Fort Bend County District Court">328th Judicial District - Fort Bend County District Court</option>
    <option value="329th Judicial District - Wharton County District Court">329th Judicial District - Wharton County District Court</option>
    <option value="330th Judicial District - Dallas County District Court">330th Judicial District - Dallas County District Court</option>
    <option value="331st Judicial District - Travis County District Court">331st Judicial District - Travis County District Court</option>
    <option value="332nd Judicial District - Hidalgo County District Court">332nd Judicial District - Hidalgo County District Court</option>
    <option value="333rd Judicial District - Harris County District Court">333rd Judicial District - Harris County District Court</option>
    <option value="334th Judicial District - Harris County District Court">334th Judicial District - Harris County District Court</option>
    <option value="335th Judicial District - Bastrop, Burleson, Lee, and Washington Counties District Court">335th Judicial District - Bastrop, Burleson, Lee, and Washington Counties District Court</option>
    <option value="336th Judicial District - Fannin County District Court">336th Judicial District - Fannin County District Court</option>
    <option value="337th Judicial District - Harris County District Court">337th Judicial District - Harris County District Court</option>
    <option value="338th Judicial District - Harris County District Court">338th Judicial District - Harris County District Court</option>
    <option value="339th Judicial District - Harris County District Court">339th Judicial District - Harris County District Court</option>
    <option value="340th Judicial District - Tom Green County District Court">340th Judicial District - Tom Green County District Court</option>
    <option value="341st Judicial District - Webb County District Court">341st Judicial District - Webb County District Court</option>
    <option value="342nd Judicial District - Tarrant County District Court">342nd Judicial District - Tarrant County District Court</option>
    <option value="343rd Judicial District - Bee, Live Oak, and McMullen Counties District Court">343rd Judicial District - Bee, Live Oak, and McMullen Counties District Court</option>
    <option value="344th Judicial District - Chambers County District Court">344th Judicial District - Chambers County District Court</option>
    <option value="345th Judicial District - Travis County District Court">345th Judicial District - Travis County District Court</option>
    <option value="346th Judicial District - El Paso County District Court">346th Judicial District - El Paso County District Court</option>
    <option value="347th Judicial District - Nueces County District Court">347th Judicial District - Nueces County District Court</option>
    <option value="348th Judicial District - Tarrant County District Court">348th Judicial District - Tarrant County District Court</option>
    <option value="349th Judicial District - Anderson and Houston Counties District Court">349th Judicial District - Anderson and Houston Counties District Court</option>
    <option value="350th Judicial District - Taylor County District Court">350th Judicial District - Taylor County District Court</option>
    <option value="351st Judicial District - Harris County District Court">351st Judicial District - Harris County District Court</option>
    <option value="352nd Judicial District - Tarrant County District Court">352nd Judicial District - Tarrant County District Court</option>
    <option value="353rd Judicial District - Travis County District Court">353rd Judicial District - Travis County District Court</option>
    <option value="354th Judicial District - Hunt and Rains Counties District Court">354th Judicial District - Hunt and Rains Counties District Court</option>
    <option value="355th Judicial District - Hood County District Court">355th Judicial District - Hood County District Court</option>
    <option value="356th Judicial District - Hardin County District Court">356th Judicial District - Hardin County District Court</option>
    <option value="357th Judicial District - Cameron County District Court">357th Judicial District - Cameron County District Court</option>
    <option value="358th Judicial District - Ector County District Court">358th Judicial District - Ector County District Court</option>
    <option value="359th Judicial District - Montgomery County District Court">359th Judicial District - Montgomery County District Court</option>
    <option value="360th Judicial District - Tarrant County District Court">360th Judicial District - Tarrant County District Court</option>
    <option value="361st Judicial District - Brazos County District Court">361st Judicial District - Brazos County District Court</option>
    <option value="362nd Judicial District - Denton County District Court">362nd Judicial District - Denton County District Court</option>
    <option value="363rd Judicial District - Dallas County District Court">363rd Judicial District - Dallas County District Court</option>
    <option value="364th Judicial District - Lubbock County District Court">364th Judicial District - Lubbock County District Court</option>
    <option value="365th Judicial District - Dimmit, Maverick, and Zavala Counties District Court">365th Judicial District - Dimmit, Maverick, and Zavala Counties District Court</option>
    <option value="366th Judicial District - Collin County District Court">366th Judicial District - Collin County District Court</option>
    <option value="367th Judicial District - Denton County District Court">367th Judicial District - Denton County District Court</option>
    <option value="368th Judicial District - Williamson County District Court">368th Judicial District - Williamson County District Court</option>
    <option value="369th Judicial District - Anderson, Cherokee, and Houston Counties District Court">369th Judicial District - Anderson, Cherokee, and Houston Counties District Court</option>
    <option value="370th Judicial District - Hidalgo County District Court">370th Judicial District - Hidalgo County District Court</option>
    <option value="371st Judicial District - Tarrant County District Court">371st Judicial District - Tarrant County District Court</option>
    <option value="372nd Judicial District - Tarrant County District Court">372nd Judicial District - Tarrant County District Court</option>
    <option value="373rd Judicial District - Tarrant County District Court">373rd Judicial District - Tarrant County District Court</option>
    <option value="374th Judicial District - Tarrant County District Court">374th Judicial District - Tarrant County District Court</option>
    <option value="375th Judicial District - Tarrant County District Court">375th Judicial District - Tarrant County District Court</option>
    <option value="376th Judicial District - Tarrant County District Court">376th Judicial District - Tarrant County District Court</option>
    <option value="377th Judicial District - Tarrant County District Court">377th Judicial District - Tarrant County District Court</option>
    <option value="378th Judicial District - Tarrant County District Court">378th Judicial District - Tarrant County District Court</option>
    <option value="379th Judicial District - Tarrant County District Court">379th Judicial District - Tarrant County District Court</option>
    <option value="380th Judicial District - Tarrant County District Court">380th Judicial District - Tarrant County District Court</option>
    <option value="381st Judicial District - Tarrant County District Court">381st Judicial District - Tarrant County District Court</option>
    <option value="382nd Judicial District - Tarrant County District Court">382nd Judicial District - Tarrant County District Court</option>
    <option value="383rd Judicial District - Tarrant County District Court">383rd Judicial District - Tarrant County District Court</option>
    <option value="384th Judicial District - Tarrant County District Court">384th Judicial District - Tarrant County District Court</option>
    <option value="385th Judicial District - Tarrant County District Court">385th Judicial District - Tarrant County District Court</option>
    <option value="386th Judicial District - Tarrant County District Court">386th Judicial District - Tarrant County District Court</option>
    <option value="387th Judicial District - Tarrant County District Court">387th Judicial District - Tarrant County District Court</option>
    <option value="388th Judicial District - Tarrant County District Court">388th Judicial District - Tarrant County District Court</option>
    <option value="389th Judicial District - Tarrant County District Court">389th Judicial District - Tarrant County District Court</option>
    <option value="390th Judicial District - Tarrant County District Court">390th Judicial District - Tarrant County District Court</option>
    <option value="391st Judicial District - Tarrant County District Court">391st Judicial District - Tarrant County District Court</option>
    <option value="392nd Judicial District - Tarrant County District Court">392nd Judicial District - Tarrant County District Court</option>
    <option value="393rd Judicial District - Tarrant County District Court">393rd Judicial District - Tarrant County District Court</option>
    <option value="394th Judicial District - Tarrant County District Court">394th Judicial District - Tarrant County District Court</option>
    <option value="395th Judicial District - Tarrant County District Court">395th Judicial District - Tarrant County District Court</option>
    <option value="396th Judicial District - Tarrant County District Court">396th Judicial District - Tarrant County District Court</option>
    <option value="397th Judicial District - Tarrant County District Court">397th Judicial District - Tarrant County District Court</option>
    <option value="398th Judicial District - Tarrant County District Court">398th Judicial District - Tarrant County District Court</option>
    <option value="399th Judicial District - Tarrant County District Court">399th Judicial District - Tarrant County District Court</option>
    <option value="400th Judicial District - Tarrant County District Court">400th Judicial District - Tarrant County District Court</option>

    <option value="401st Judicial District - Collin County District Court">401st Judicial District - Collin County District Court</option>
    <option value="402nd Judicial District - Wood County District Court">402nd Judicial District - Wood County District Court</option>
    <option value="403rd Judicial District - Travis County District Court">403rd Judicial District - Travis County District Court</option>
    <option value="404th Judicial District - Cameron County District Court">404th Judicial District - Cameron County District Court</option>
    <option value="405th Judicial District - Galveston County District Court">405th Judicial District - Galveston County District Court</option>
    <option value="406th Judicial District - Webb County District Court">406th Judicial District - Webb County District Court</option>
    <option value="407th Judicial District - Bexar County District Court">407th Judicial District - Bexar County District Court</option>
    <option value="408th Judicial District - Bexar County District Court">408th Judicial District - Bexar County District Court</option>
    <option value="409th Judicial District - El Paso County District Court">409th Judicial District - El Paso County District Court</option>
    <option value="410th Judicial District - Montgomery County District Court">410th Judicial District - Montgomery County District Court</option>
    <option value="411th Judicial District - San Jacinto, Polk, and Trinity Counties District Court">411th Judicial District - San Jacinto, Polk, and Trinity Counties District Court</option>
    <option value="412th Judicial District - Brazoria County District Court">412th Judicial District - Brazoria County District Court</option>
    <option value="413th Judicial District - Johnson County District Court">413th Judicial District - Johnson County District Court</option>
    <option value="414th Judicial District - McLennan County District Court">414th Judicial District - McLennan County District Court</option>
    <option value="415th Judicial District - Parker County District Court">415th Judicial District - Parker County District Court</option>
    <option value="416th Judicial District - Collin County District Court">416th Judicial District - Collin County District Court</option>
    <option value="417th Judicial District - Collin County District Court">417th Judicial District - Collin County District Court</option>
    <option value="418th Judicial District - Montgomery County District Court">418th Judicial District - Montgomery County District Court</option>
    <option value="419th Judicial District - Travis County District Court">419th Judicial District - Travis County District Court</option>
    <option value="420th Judicial District - Nacogdoches County District Court">420th Judicial District - Nacogdoches County District Court</option>
    <option value="421st Judicial District - Caldwell County District Court">421st Judicial District - Caldwell County District Court</option>
    <option value="422nd Judicial District - Kaufman County District Court">422nd Judicial District - Kaufman County District Court</option>
    <option value="423rd Judicial District - Bastrop County District Court">423rd Judicial District - Bastrop County District Court</option>
    <option value="424th Judicial District - Blanco, Burnet, Llano, and San Saba Counties District Court">424th Judicial District - Blanco, Burnet, Llano, and San Saba Counties District Court</option>
    <option value="425th Judicial District - Williamson County District Court">425th Judicial District - Williamson County District Court</option>
    <option value="426th Judicial District - Bell County District Court">426th Judicial District - Bell County District Court</option>
    <option value="427th Judicial District - Travis County District Court">427th Judicial District - Travis County District Court</option>
    <option value="428th Judicial District - Hays County District Court">428th Judicial District - Hays County District Court</option>
    <option value="429th Judicial District - Collin County District Court">429th Judicial District - Collin County District Court</option>
    <option value="430th Judicial District - Hidalgo County District Court">430th Judicial District - Hidalgo County District Court</option>
    <option value="431st Judicial District - Denton County District Court">431st Judicial District - Denton County District Court</option>
    <option value="432nd Judicial District - Tarrant County District Court">432nd Judicial District - Tarrant County District Court</option>
    <option value="433rd Judicial District - Comal County District Court">433rd Judicial District - Comal County District Court</option>
    <option value="434th Judicial District - Fort Bend County District Court">434th Judicial District - Fort Bend County District Court</option>
    <option value="435th Judicial District - Montgomery County District Court">435th Judicial District - Montgomery County District Court</option>
    <option value="436th Judicial District - Bexar County District Court">436th Judicial District - Bexar County District Court</option>
    <option value="437th Judicial District - Bexar County District Court">437th Judicial District - Bexar County District Court</option>
    <option value="438th Judicial District - Bexar County District Court">438th Judicial District - Bexar County District Court</option>
    <option value="439th Judicial District - Rockwall County District Court">439th Judicial District - Rockwall County District Court</option>
    <option value="440th Judicial District - Coryell County District Court">440th Judicial District - Coryell County District Court</option>
    <option value="441st Judicial District - Midland County District Court">441st Judicial District - Midland County District Court</option>
    <option value="442nd Judicial District - Denton County District Court">442nd Judicial District - Denton County District Court</option>
    <option value="443rd Judicial District - Ellis County District Court">443rd Judicial District - Ellis County District Court</option>
    <option value="444th Judicial District - Cameron County District Court">444th Judicial District - Cameron County District Court</option>
    <option value="445th Judicial District - Cameron County District Court">445th Judicial District - Cameron County District Court</option>
    <option value="446th Judicial District - Ector County District Court">446th Judicial District - Ector County District Court</option>
    <option value="447th Judicial District - Comal County District Court">447th Judicial District - Comal County District Court</option>
    <option value="448th Judicial District - El Paso County District Court">448th Judicial District - El Paso County District Court</option>
    <option value="449th Judicial District - Hidalgo County District Court">449th Judicial District - Hidalgo County District Court</option>
    <option value="450th Judicial District - Travis County District Court">450th Judicial District - Travis County District Court</option>
    <option value="451st Judicial District - Kendall County District Court">451st Judicial District - Kendall County District Court</option>
    <option value="452nd Judicial District - Edwards, Kimble, McCulloch, Mason, and Menard Counties District Court">452nd Judicial District - Edwards, Kimble, McCulloch, Mason, and Menard Counties District Court</option>
    <option value="453rd Judicial District - Hays County District Court">453rd Judicial District - Hays County District Court</option>
    <option value="454th Judicial District - Medina County District Court">454th Judicial District - Medina County District Court</option>
    <option value="455th Judicial District - Travis County District Court">455th Judicial District - Travis County District Court</option>
    <option value="456th Judicial District - Guadalupe County District Court">456th Judicial District - Guadalupe County District Court</option>
    <option value="457th Judicial District - Montgomery County District Court">457th Judicial District - Montgomery County District Court</option>
    <option value="458th Judicial District - Fort Bend County District Court">458th Judicial District - Fort Bend County District Court</option>
    <option value="459th Judicial District - Travis County District Court">459th Judicial District - Travis County District Court</option>
    <option value="460th Judicial District - Travis County District Court">460th Judicial District - Travis County District Court</option>
    <option value="461st Judicial District - Brazoria County District Court">461st Judicial District - Brazoria County District Court</option>
    <option value="462nd Judicial District - Denton County District Court">462nd Judicial District - Denton County District Court</option>
    <option value="463rd Judicial District - Denton County District Court">463rd Judicial District - Denton County District Court</option>
    <option value="464th Judicial District - Hidalgo County District Court">464th Judicial District - Hidalgo County District Court</option>
    <option value="465th Judicial District - Hidalgo County District Court">465th Judicial District - Hidalgo County District Court</option>
    <option value="466th Judicial District - Comal County District Court">466th Judicial District - Comal County District Court</option>
    <option value="467th Judicial District - Denton County District Court">467th Judicial District - Denton County District Court</option>
    <option value="468th Judicial District - Collin County District Court">468th Judicial District - Collin County District Court</option>
    <option value="469th Judicial District - Collin County District Court">469th Judicial District - Collin County District Court</option>
    <option value="470th Judicial District - Collin County District Court">470th Judicial District - Collin County District Court</option>
    <option value="471st Judicial District - Collin County District Court">471st Judicial District - Collin County District Court</option>
    <option value="472nd Judicial District - Collin County District Court">472nd Judicial District - Collin County District Court</option>
{secondValue === 'Utah' && (
  <>
    <option value="First Judicial District - Box Elder County District Court">First Judicial District - Box Elder County District Court</option>
    <option value="First Judicial District - Cache County District Court">First Judicial District - Cache County District Court</option>
    <option value="Second Judicial District - Davis County District Court">Second Judicial District - Davis County District Court</option>
    <option value="Second Judicial District - Morgan County District Court">Second Judicial District - Morgan County District Court</option>
    <option value="Second Judicial District - Weber County District Court">Second Judicial District - Weber County District Court</option>
    <option value="Third Judicial District - Salt Lake County District Court">Third Judicial District - Salt Lake County District Court</option>
    <option value="Third Judicial District - Summit County District Court">Third Judicial District - Summit County District Court</option>
    <option value="Third Judicial District - Tooele County District Court">Third Judicial District - Tooele County District Court</option>
    <option value="Fourth Judicial District - Juab County District Court">Fourth Judicial District - Juab County District Court</option>
    <option value="Fourth Judicial District - Millard County District Court">Fourth Judicial District - Millard County District Court</option>
    <option value="Fourth Judicial District - Utah County District Court">Fourth Judicial District - Utah County District Court</option>
    <option value="Fourth Judicial District - Wasatch County District Court">Fourth Judicial District - Wasatch County District Court</option>
    <option value="Fifth Judicial District - Beaver County District Court">Fifth Judicial District - Beaver County District Court</option>
    <option value="Fifth Judicial District - Iron County District Court">Fifth Judicial District - Iron County District Court</option>
    <option value="Fifth Judicial District - Washington County District Court">Fifth Judicial District - Washington County District Court</option>
    <option value="Sixth Judicial District - Garfield County District Court">Sixth Judicial District - Garfield County District Court</option>
    <option value="Sixth Judicial District - Kane County District Court">Sixth Judicial District - Kane County District Court</option>
    <option value="Sixth Judicial District - Piute County District Court">Sixth Judicial District - Piute County District Court</option>
    <option value="Sixth Judicial District - Sanpete County District Court">Sixth Judicial District - Sanpete County District Court</option>
    <option value="Sixth Judicial District - Sevier County District Court">Sixth Judicial District - Sevier County District Court</option>
    <option value="Sixth Judicial District - Wayne County District Court">Sixth Judicial District - Wayne County District Court</option>
    <option value="Seventh Judicial District - Carbon County District Court">Seventh Judicial District - Carbon County District Court</option>
    <option value="Seventh Judicial District - Emery County District Court">Seventh Judicial District - Emery County District Court</option>
    <option value="Seventh Judicial District - Grand County District Court">Seventh Judicial District - Grand County District Court</option>
    <option value="Seventh Judicial District - San Juan County District Court">Seventh Judicial District - San Juan County District Court</option>
    <option value="Eighth Judicial District - Daggett County District Court">Eighth Judicial District - Daggett County District Court</option>
    <option value="Eighth Judicial District - Duchesne County District Court">Eighth Judicial District - Duchesne County District Court</option>
    <option value="Eighth Judicial District - Uintah County District Court">Eighth Judicial District - Uintah County District Court</option>
  </>
)}
{secondValue === 'Vermont' && (
  <>
    <option value="Addison County Superior Court">Addison County Superior Court</option>
    <option value="Bennington County Superior Court">Bennington County Superior Court</option>
    <option value="Caledonia County Superior Court">Caledonia County Superior Court</option>
    <option value="Chittenden County Superior Court">Chittenden County Superior Court</option>
    <option value="Essex County Superior Court">Essex County Superior Court</option>
    <option value="Franklin County Superior Court">Franklin County Superior Court</option>
    <option value="Grand Isle County Superior Court">Grand Isle County Superior Court</option>
    <option value="Lamoille County Superior Court">Lamoille County Superior Court</option>
    <option value="Orange County Superior Court">Orange County Superior Court</option>
    <option value="Orleans County Superior Court">Orleans County Superior Court</option>
    <option value="Rutland County Superior Court">Rutland County Superior Court</option>
    <option value="Washington County Superior Court">Washington County Superior Court</option>
    <option value="Windham County Superior Court">Windham County Superior Court</option>
    <option value="Windsor County Superior Court">Windsor County Superior Court</option>
  </>
)}
{secondValue === 'Virginia' && (
  <>
    <option value="1st Judicial Circuit - Chesapeake Circuit Court">1st Judicial Circuit - Chesapeake Circuit Court</option>
    <option value="2nd Judicial Circuit - Virginia Beach Circuit Court">2nd Judicial Circuit - Virginia Beach Circuit Court</option>
    <option value="3rd Judicial Circuit - Portsmouth Circuit Court">3rd Judicial Circuit - Portsmouth Circuit Court</option>
    <option value="4th Judicial Circuit - Norfolk Circuit Court">4th Judicial Circuit - Norfolk Circuit Court</option>
    <option value="5th Judicial Circuit - Franklin, Southampton County, and Suffolk Circuit Courts">5th Judicial Circuit - Franklin, Southampton County, and Suffolk Circuit Courts</option>
    <option value="6th Judicial Circuit - Brunswick County, Emporia, Greensville County, Hopewell, Prince George County, and Surry County Circuit Courts">6th Judicial Circuit - Brunswick County, Emporia, Greensville County, Hopewell, Prince George County, and Surry County Circuit Courts</option>
    <option value="7th Judicial Circuit - Newport News Circuit Court">7th Judicial Circuit - Newport News Circuit Court</option>
    <option value="8th Judicial Circuit - Hampton Circuit Court">8th Judicial Circuit - Hampton Circuit Court</option>
    <option value="9th Judicial Circuit - Gloucester County, James City County, Mathews County, Middlesex County, Williamsburg, and York County Circuit Courts">9th Judicial Circuit - Gloucester County, James City County, Mathews County, Middlesex County, Williamsburg, and York County Circuit Courts</option>
    <option value="10th Judicial Circuit - Appomattox County, Buckingham County, Charlotte County, Cumberland County, Halifax County, Lunenburg County, Mecklenburg County, and Prince Edward County Circuit Courts">10th Judicial Circuit - Appomattox County, Buckingham County, Charlotte County, Cumberland County, Halifax County, Lunenburg County, Mecklenburg County, and Prince Edward County Circuit Courts</option>
    <option value="11th Judicial Circuit - Amelia County, Dinwiddie County, Nottoway County, and Petersburg Circuit Courts">11th Judicial Circuit - Amelia County, Dinwiddie County, Nottoway County, and Petersburg Circuit Courts</option>
    <option value="12th Judicial Circuit - Chesterfield County and Colonial Heights Circuit Courts">12th Judicial Circuit - Chesterfield County and Colonial Heights Circuit Courts</option>
    <option value="13th Judicial Circuit - Richmond Circuit Court">13th Judicial Circuit - Richmond Circuit Court</option>
    <option value="14th Judicial Circuit - Henrico County Circuit Court">14th Judicial Circuit - Henrico County Circuit Court</option>
    <option value="15th Judicial Circuit - Caroline County, Essex County, Fredericksburg, Hanover County, King George County, Lancaster County, Northumberland County, Richmond County, Spotsylvania County, Stafford County, and Westmoreland County Circuit Courts">15th Judicial Circuit - Caroline County, Essex County, Fredericksburg, Hanover County, King George County, Lancaster County, Northumberland County, Richmond County, Spotsylvania County, Stafford County, and Westmoreland County Circuit Courts</option>
    <option value="16th Judicial Circuit - Albemarle County, Charlottesville, Culpeper County, Fluvanna County, Greene County, Goochland County, Louisa County, Madison County, and Orange County Circuit Courts">16th Judicial Circuit - Albemarle County, Charlottesville, Culpeper County, Fluvanna County, Greene County, Goochland County, Louisa County, Madison County, and Orange County Circuit Courts</option>
    <option value="17th Judicial Circuit - Arlington County and Falls Church Circuit Courts">17th Judicial Circuit - Arlington County and Falls Church Circuit Courts</option>
    <option value="18th Judicial Circuit - Alexandria Circuit Court">18th Judicial Circuit - Alexandria Circuit Court</option>
    <option value="19th Judicial Circuit - Fairfax County and Fairfax City Circuit Courts">19th Judicial Circuit - Fairfax County and Fairfax City Circuit Courts</option>
    <option value="20th Judicial Circuit - Fauquier County, Loudoun County, and Rappahannock County Circuit Courts">20th Judicial Circuit - Fauquier County, Loudoun County, and Rappahannock County Circuit Courts</option>
    <option value="21st Judicial Circuit - Martinsville, Henry County, and Patrick County Circuit Courts">21st Judicial Circuit - Martinsville, Henry County, and Patrick County Circuit Courts</option>
    <option value="22nd Judicial Circuit - Danville, Franklin County, and Pittsylvania County Circuit Courts">22nd Judicial Circuit - Danville, Franklin County, and Pittsylvania County Circuit Courts</option>
    <option value="23rd Judicial Circuit - Roanoke, Roanoke County, and Salem Circuit Courts">23rd Judicial Circuit - Roanoke, Roanoke County, and Salem Circuit Courts</option>
    <option value="24th Judicial Circuit - Amherst County, Bedford County, Campbell County, Lynchburg, and Nelson County Circuit Courts">24th Judicial Circuit - Amherst County, Bedford County, Campbell County, Lynchburg, and Nelson County Circuit Courts</option>
    <option value="25th Judicial Circuit - Alleghany County, Augusta County, Bath County, Botetourt County, Buena Vista, Clifton Forge, Craig County, Highland County, Lexington, Rockbridge County, Staunton, and Waynesboro Circuit Courts">25th Judicial Circuit - Alleghany County, Augusta County, Bath County, Botetourt County, Buena Vista, Clifton Forge, Craig County, Highland County, Lexington, Rockbridge County, Staunton, and Waynesboro Circuit Courts</option>
    <option value="26th Judicial Circuit - Clarke County, Frederick County, Page County, Rockingham County, Shenandoah County, Warren County, and Winchester Circuit Courts">26th Judicial Circuit - Clarke County, Frederick County, Page County, Rockingham County, Shenandoah County, Warren County, and Winchester Circuit Courts</option>
    <option value="27th Judicial Circuit - Bland County, Carroll County, Floyd County, Galax, Giles County, Grayson County, Montgomery County, Pulaski County, Radford, and Wythe County Circuit Courts">27th Judicial Circuit - Bland County, Carroll County, Floyd County, Galax, Giles County, Grayson County, Montgomery County, Pulaski County, Radford, and Wythe County Circuit Courts</option>
    <option value="28th Judicial Circuit - Bristol, Smyth County, and Washington County Circuit Courts">28th Judicial Circuit - Bristol, Smyth County, and Washington County Circuit Courts</option>
    <option value="29th Judicial Circuit - Buchanan County, Dickenson County, Russell County, and Tazewell County Circuit Courts">29th Judicial Circuit - Buchanan County, Dickenson County, Russell County, and Tazewell County Circuit Courts</option>
    <option value="30th Judicial Circuit - Lee County, Scott County, and Wise County Circuit Courts">30th Judicial Circuit - Lee County, Scott County, and Wise County Circuit Courts</option>
    <option value="31st Judicial Circuit - Manassas and Prince William County Circuit Courts">31st Judicial Circuit - Manassas and Prince William County Circuit Courts</option>
  </>
)}
{secondValue === 'Washington' && (
  <>
    <option value="Adams County Superior Court">Adams County Superior Court</option>
    <option value="Asotin County Superior Court">Asotin County Superior Court</option>
    <option value="Benton County Superior Court">Benton County Superior Court</option>
    <option value="Chelan County Superior Court">Chelan County Superior Court</option>
    <option value="Clallam County Superior Court">Clallam County Superior Court</option>
    <option value="Clark County Superior Court">Clark County Superior Court</option>
    <option value="Columbia County Superior Court">Columbia County Superior Court</option>
    <option value="Cowlitz County Superior Court">Cowlitz County Superior Court</option>
    <option value="Douglas County Superior Court">Douglas County Superior Court</option>
    <option value="Ferry County Superior Court">Ferry County Superior Court</option>
    <option value="Franklin County Superior Court">Franklin County Superior Court</option>
    <option value="Garfield County Superior Court">Garfield County Superior Court</option>
    <option value="Grant County Superior Court">Grant County Superior Court</option>
    <option value="Grays Harbor County Superior Court">Grays Harbor County Superior Court</option>
    <option value="Island County Superior Court">Island County Superior Court</option>
    <option value="Jefferson County Superior Court">Jefferson County Superior Court</option>
    <option value="King County Superior Court">King County Superior Court</option>
    <option value="Kitsap County Superior Court">Kitsap County Superior Court</option>
    <option value="Kittitas County Superior Court">Kittitas County Superior Court</option>
    <option value="Klickitat County Superior Court">Klickitat County Superior Court</option>
    <option value="Lewis County Superior Court">Lewis County Superior Court</option>
    <option value="Lincoln County Superior Court">Lincoln County Superior Court</option>
    <option value="Mason County Superior Court">Mason County Superior Court</option>
    <option value="Okanogan County Superior Court">Okanogan County Superior Court</option>
    <option value="Pacific County Superior Court">Pacific County Superior Court</option>
    <option value="Pend Oreille County Superior Court">Pend Oreille County Superior Court</option>
    <option value="Pierce County Superior Court">Pierce County Superior Court</option>
    <option value="San Juan County Superior Court">San Juan County Superior Court</option>
    <option value="Skagit County Superior Court">Skagit County Superior Court</option>
    <option value="Skamania County Superior Court">Skamania County Superior Court</option>
    <option value="Snohomish County Superior Court">Snohomish County Superior Court</option>
    <option value="Spokane County Superior Court">Spokane County Superior Court</option>
    <option value="Stevens County Superior Court">Stevens County Superior Court</option>
    <option value="Thurston County Superior Court">Thurston County Superior Court</option>
    <option value="Wahkiakum County Superior Court">Wahkiakum County Superior Court</option>
    <option value="Walla Walla County Superior Court">Walla Walla County Superior Court</option>
    <option value="Whatcom County Superior Court">Whatcom County Superior Court</option>
    <option value="Whitman County Superior Court">Whitman County Superior Court</option>
    <option value="Yakima County Superior Court">Yakima County Superior Court</option>
  </>
)}
{secondValue === 'West Virginia' && (
  <>
    <option value="1st Judicial Circuit - Brooke, Hancock, and Ohio Counties Circuit Court">1st Judicial Circuit - Brooke, Hancock, and Ohio Counties Circuit Court</option>
    <option value="2nd Judicial Circuit - Marshall, Tyler, and Wetzel Counties Circuit Court">2nd Judicial Circuit - Marshall, Tyler, and Wetzel Counties Circuit Court</option>
    <option value="3rd Judicial Circuit - Doddridge, Pleasants, and Ritchie Counties Circuit Court">3rd Judicial Circuit - Doddridge, Pleasants, and Ritchie Counties Circuit Court</option>
    <option value="4th Judicial Circuit - Wood and Wirt Counties Circuit Court">4th Judicial Circuit - Wood and Wirt Counties Circuit Court</option>
    <option value="5th Judicial Circuit - Calhoun, Jackson, Mason, and Roane Counties Circuit Court">5th Judicial Circuit - Calhoun, Jackson, Mason, and Roane Counties Circuit Court</option>
    <option value="6th Judicial Circuit - Cabell County Circuit Court">6th Judicial Circuit - Cabell County Circuit Court</option>
    <option value="7th Judicial Circuit - Logan County Circuit Court">7th Judicial Circuit - Logan County Circuit Court</option>
    <option value="8th Judicial Circuit - McDowell County Circuit Court">8th Judicial Circuit - McDowell County Circuit Court</option>
    <option value="9th Judicial Circuit - Mercer County Circuit Court">9th Judicial Circuit - Mercer County Circuit Court</option>
    <option value="10th Judicial Circuit - Raleigh County Circuit Court">10th Judicial Circuit - Raleigh County Circuit Court</option>
    <option value="11th Judicial Circuit - Greenbrier and Pocahontas Counties Circuit Court">11th Judicial Circuit - Greenbrier and Pocahontas Counties Circuit Court</option>
    <option value="12th Judicial Circuit - Fayette County Circuit Court">12th Judicial Circuit - Fayette County Circuit Court</option>
    <option value="13th Judicial Circuit - Kanawha County Circuit Court">13th Judicial Circuit - Kanawha County Circuit Court</option>
    <option value="14th Judicial Circuit - Braxton, Clay, Gilmer, and Webster Counties Circuit Court">14th Judicial Circuit - Braxton, Clay, Gilmer, and Webster Counties Circuit Court</option>
    <option value="15th Judicial Circuit - Harrison County Circuit Court">15th Judicial Circuit - Harrison County Circuit Court</option>
    <option value="16th Judicial Circuit - Marion County Circuit Court">16th Judicial Circuit - Marion County Circuit Court</option>
    <option value="17th Judicial Circuit - Monongalia County Circuit Court">17th Judicial Circuit - Monongalia County Circuit Court</option>
    <option value="18th Judicial Circuit - Preston County Circuit Court">18th Judicial Circuit - Preston County Circuit Court</option>
    <option value="19th Judicial Circuit - Barbour and Taylor Counties Circuit Court">19th Judicial Circuit - Barbour and Taylor Counties Circuit Court</option>
    <option value="20th Judicial Circuit - Randolph County Circuit Court">20th Judicial Circuit - Randolph County Circuit Court</option>
    <option value="21st Judicial Circuit - Grant, Mineral, and Tucker Counties Circuit Court">21st Judicial Circuit - Grant, Mineral, and Tucker Counties Circuit Court</option>
    <option value="22nd Judicial Circuit - Hampshire, Hardy, and Pendleton Counties Circuit Court">22nd Judicial Circuit - Hampshire, Hardy, and Pendleton Counties Circuit Court</option>
    <option value="23rd Judicial Circuit - Berkeley, Jefferson, and Morgan Counties Circuit Court">23rd Judicial Circuit - Berkeley, Jefferson, and Morgan Counties Circuit Court</option>
    <option value="24th Judicial Circuit - Wayne County Circuit Court">24th Judicial Circuit - Wayne County Circuit Court</option>
    <option value="25th Judicial Circuit - Boone and Lincoln Counties Circuit Court">25th Judicial Circuit - Boone and Lincoln Counties Circuit Court</option>
    <option value="26th Judicial Circuit - Lewis and Upshur Counties Circuit Court">26th Judicial Circuit - Lewis and Upshur Counties Circuit Court</option>
    <option value="27th Judicial Circuit - Wyoming County Circuit Court">27th Judicial Circuit - Wyoming County Circuit Court</option>
    <option value="28th Judicial Circuit - Nicholas County Circuit Court">28th Judicial Circuit - Nicholas County Circuit Court</option>
    <option value="29th Judicial Circuit - Putnam County Circuit Court">29th Judicial Circuit - Putnam County Circuit Court</option>
    <option value="30th Judicial Circuit - Mingo County Circuit Court">30th Judicial Circuit - Mingo County Circuit Court</option>
    <option value="31st Judicial Circuit - Monroe and Summers Counties Circuit Court">31st Judicial Circuit - Monroe and Summers Counties Circuit Court</option>
  </>
)}
{secondValue === 'Wisconsin' && (
  <>
    <option value="Adams County Circuit Court">Adams County Circuit Court</option>
    <option value="Ashland County Circuit Court">Ashland County Circuit Court</option>
    <option value="Barron County Circuit Court">Barron County Circuit Court</option>
    <option value="Bayfield County Circuit Court">Bayfield County Circuit Court</option>
    <option value="Brown County Circuit Court">Brown County Circuit Court</option>
    <option value="Buffalo County Circuit Court">Buffalo County Circuit Court</option>
    <option value="Burnett County Circuit Court">Burnett County Circuit Court</option>
    <option value="Calumet County Circuit Court">Calumet County Circuit Court</option>
    <option value="Chippewa County Circuit Court">Chippewa County Circuit Court</option>
    <option value="Clark County Circuit Court">Clark County Circuit Court</option>
    <option value="Columbia County Circuit Court">Columbia County Circuit Court</option>
    <option value="Crawford County Circuit Court">Crawford County Circuit Court</option>
    <option value="Dane County Circuit Court">Dane County Circuit Court</option>
    <option value="Dodge County Circuit Court">Dodge County Circuit Court</option>
    <option value="Door County Circuit Court">Door County Circuit Court</option>
    <option value="Douglas County Circuit Court">Douglas County Circuit Court</option>
    <option value="Dunn County Circuit Court">Dunn County Circuit Court</option>
    <option value="Eau Claire County Circuit Court">Eau Claire County Circuit Court</option>
    <option value="Florence County Circuit Court">Florence County Circuit Court</option>
    <option value="Fond du Lac County Circuit Court">Fond du Lac County Circuit Court</option>
    <option value="Forest County Circuit Court">Forest County Circuit Court</option>
    <option value="Grant County Circuit Court">Grant County Circuit Court</option>
    <option value="Green County Circuit Court">Green County Circuit Court</option>
    <option value="Green Lake County Circuit Court">Green Lake County Circuit Court</option>
    <option value="Iowa County Circuit Court">Iowa County Circuit Court</option>
    <option value="Iron County Circuit Court">Iron County Circuit Court</option>
    <option value="Jackson County Circuit Court">Jackson County Circuit Court</option>
    <option value="Jefferson County Circuit Court">Jefferson County Circuit Court</option>
    <option value="Juneau County Circuit Court">Juneau County Circuit Court</option>
    <option value="Kenosha County Circuit Court">Kenosha County Circuit Court</option>
    <option value="Kewaunee County Circuit Court">Kewaunee County Circuit Court</option>
    <option value="La Crosse County Circuit Court">La Crosse County Circuit Court</option>
    <option value="Lafayette County Circuit Court">Lafayette County Circuit Court</option>
    <option value="Langlade County Circuit Court">Langlade County Circuit Court</option>
    <option value="Lincoln County Circuit Court">Lincoln County Circuit Court</option>
    <option value="Manitowoc County Circuit Court">Manitowoc County Circuit Court</option>
    <option value="Marathon County Circuit Court">Marathon County Circuit Court</option>
    <option value="Marinette County Circuit Court">Marinette County Circuit Court</option>
    <option value="Marquette County Circuit Court">Marquette County Circuit Court</option>
    <option value="Menominee County Circuit Court">Menominee County Circuit Court</option>
    <option value="Milwaukee County Circuit Court">Milwaukee County Circuit Court</option>
    <option value="Monroe County Circuit Court">Monroe County Circuit Court</option>
    <option value="Oconto County Circuit Court">Oconto County Circuit Court</option>
    <option value="Oneida County Circuit Court">Oneida County Circuit Court</option>
    <option value="Outagamie County Circuit Court">Outagamie County Circuit Court</option>
    <option value="Ozaukee County Circuit Court">Ozaukee County Circuit Court</option>
    <option value="Pepin County Circuit Court">Pepin County Circuit Court</option>
    <option value="Pierce County Circuit Court">Pierce County Circuit Court</option>
    <option value="Polk County Circuit Court">Polk County Circuit Court</option>
    <option value="Portage County Circuit Court">Portage County Circuit Court</option>
    <option value="Price County Circuit Court">Price County Circuit Court</option>
    <option value="Racine County Circuit Court">Racine County Circuit Court</option>
    <option value="Richland County Circuit Court">Richland County Circuit Court</option>
    <option value="Rock County Circuit Court">Rock County Circuit Court</option>
    <option value="Rusk County Circuit Court">Rusk County Circuit Court</option>
    <option value="St. Croix County Circuit Court">St. Croix County Circuit Court</option>
    <option value="Sauk County Circuit Court">Sauk County Circuit Court</option>
    <option value="Sawyer County Circuit Court">Sawyer County Circuit Court</option>
    <option value="Shawano County Circuit Court">Shawano County Circuit Court</option>
    <option value="Sheboygan County Circuit Court">Sheboygan County Circuit Court</option>
    <option value="Taylor County Circuit Court">Taylor County Circuit Court</option>
    <option value="Trempealeau County Circuit Court">Trempealeau County Circuit Court</option>
    <option value="Vernon County Circuit Court">Vernon County Circuit Court</option>
    <option value="Vilas County Circuit Court">Vilas County Circuit Court</option>
    <option value="Walworth County Circuit Court">Walworth County Circuit Court</option>
    <option value="Washburn County Circuit Court">Washburn County Circuit Court</option>
    <option value="Washington County Circuit Court">Washington County Circuit Court</option>
    <option value="Waukesha County Circuit Court">Waukesha County Circuit Court</option>
    <option value="Waupaca County Circuit Court">Waupaca County Circuit Court</option>
    <option value="Waushara County Circuit Court">Waushara County Circuit Court</option>
    <option value="Winnebago County Circuit Court">Winnebago County Circuit Court</option>
    <option value="Wood County Circuit Court">Wood County Circuit Court</option>
  </>
)}
{secondValue === 'Wyoming' && (
  <>
    <option value="1st Judicial District - Laramie County District Court">1st Judicial District - Laramie County District Court</option>
    <option value="2nd Judicial District - Albany and Carbon Counties District Court">2nd Judicial District - Albany and Carbon Counties District Court</option>
    <option value="3rd Judicial District - Lincoln, Sweetwater, and Uinta Counties District Court">3rd Judicial District - Lincoln, Sweetwater, and Uinta Counties District Court</option>
    <option value="4th Judicial District - Johnson and Sheridan Counties District Court">4th Judicial District - Johnson and Sheridan Counties District Court</option>
    <option value="5th Judicial District - Big Horn, Hot Springs, Park, and Washakie Counties District Court">5th Judicial District - Big Horn, Hot Springs, Park, and Washakie Counties District Court</option>
    <option value="6th Judicial District - Campbell, Crook, and Weston Counties District Court">6th Judicial District - Campbell, Crook, and Weston Counties District Court</option>
    <option value="7th Judicial District - Natrona County District Court">7th Judicial District - Natrona County District Court</option>
    <option value="8th Judicial District - Converse, Goshen, Niobrara, and Platte Counties District Court">8th Judicial District - Converse, Goshen, Niobrara, and Platte Counties District Court</option>
    <option value="9th Judicial District - Fremont, Sublette, and Teton Counties District Court">9th Judicial District - Fremont, Sublette, and Teton Counties District Court</option>
  </>
)}
  </>
)}
          </select>
        </div>
        )}
    </div>
  );
};

export default CourtSelector;
