// Step5.js

import React from 'react'; 
import '../global.css';
function Step5({onSubmit, advSt, halls}) {

  return (
    <div>
      <div className='block'><h3 className="title is-4">Hallucination Guard</h3></div>
      <div className='block'>Hallucination Guard checks each citation to ensure it is accurate, relevant, and supports the legal argument. This may take a few minutes.
      </div>
      <div className='block'>
        {/* <form onSubmit={onSubmit}>
          <button className="button is-success" type="submit">Run Hallucination Guard</button>
        </form> */}
      </div>
      <div className='block'>
          {halls.map((hallarr, index) => (
            <div key={index} className="box">
              <div className="titles-container"> 
                <div className='title is-3'>Citation {index + 1}</div>
                {hallarr[0] === "correct" ? (
                  <div className='title is-3 has-text-success'>No issue</div>
                ) : (
                  <div className='title is-3 has-text-danger'>Possible Issue</div>
                )}
              </div>
              <textarea className="textarea" id= {`pfr${index}`} name={`pfr${index}`} defaultValue={hallarr[1]} rows="4" cols="50" />
              <a href={hallarr[2]} target="_blank" rel="noopener noreferrer">Citation Reference</a>
            </div>
          ))}
      </div>

      <div className="has-text-right">
        <button className="button is-normal is-success" type="button" onClick={advSt}>Review and finalize draft</button>
      </div>
    </div>
  );
}

export default Step5;