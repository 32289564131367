// src/amplify-confg.js

import { userPoolId, userPoolClientId, identityPoolId } from './Constants.js';

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: userPoolId,
      userPoolClientId: userPoolClientId,
      identityPoolId: identityPoolId,
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
      mfaConfiguration: "ON", // Enable MFA
      mfaTypes: ["TOTP"], // Define types of MFA
      totpEnabled: true, // Enable Time-based One-Time Password (TOTP)
    },
  },
};

export default awsConfig;
