// ModalNewMatter.js

import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from './Constants';
import 'bulma-switch/dist/css/bulma-switch.min.css';
import './global.css';

const ModalNewMatter = ({ isActive, stateSetter, refreshProjects }) => {
    const [newProjectName, setNewProjectName] = useState('');
    const [newDraftType, setNewDraftType] = useState('0');
    const [draftName, setDraftName] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    function dismiss() {
        stateSetter(false);
    };

    const handleCreateProject = async (e) => {
        e.preventDefault();
        try {
          await axios.post(API_URL + 'create-project', {
            'proj-name': newProjectName,
            'draft-name': draftName,
            'draft-type': newDraftType
          });
          setNewProjectName(''); // Reset input field
          refreshProjects(); // Refresh project list
          setDraftName('');
          dismiss(); // Hide form
        } catch (error) {
          console.error('Error creating project:', error);
        }
      };

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="block">
          <div className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
            <h2 className="title is-4" style={{ marginBottom: '0px'}}>Create New Matter</h2>
            <button className="modal-close is-large" aria-label="close" onClick={dismiss} style={{position: 'relative', top: 'auto', right: 'auto', color: '#000000'}}></button>
          </div>
            <form onSubmit={handleCreateProject}>
                <div className="field" style={{marginBottom: '16px'}}>
                <div className="control">
                  <label className="label">Matter Name</label>
                    <input
                    className="input"
                    type="text"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    placeholder="Enter matter name"
                    required
                    />
                </div>
                </div>
                <div className="field" style={{marginBottom: '16px'}}>
                    <input id="switchRoundedInfo" type="checkbox" name="switchRoundedInfo" className="switch is-rounded is-info is-medium" checked={isChecked} onChange={handleToggle}></input>
                    <label htmlFor="switchRoundedInfo">Create a draft for this matter</label>
                </div>
                {isChecked && (
                    <div className="field"  style={{marginBottom: '32px'}}>
                        <div className="control" style={{marginBottom: '16px'}}>
                          <label className="label">Draft Name</label>
                            <input
                            className="input"
                            type="text"
                            value={draftName}
                            onChange={(e) => setDraftName(e.target.value)}
                            placeholder="Enter draft name"
                            />
                        </div>
                        <div className="control">
                            <label className="label" htmlFor="draftType">Draft Type</label>
                            <select
                                className="select"
                                value={newDraftType}
                                onChange={(e) => setNewDraftType(e.target.value)}
                                required
                            >
                                <option value="0">Complaint</option>
                                <option value="1">Answer</option>
                                <option value="2">RFP</option>
                                <option value="3">ROGs</option>
                                <option value="4">RFAs</option>
                                {/* <option value="5">Objections to RFPs</option> */}
                                <option value="6">Complaint Analyzer</option>
                            </select>
                        </div>
                    </div>
                    
                )}
                <button className="button is-link" type="submit" style={{width: '100%'}}>Create</button>
            </form>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close"></button>
    </div>
  );
};

export default ModalNewMatter;