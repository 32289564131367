// Step1.js

// import React, { useEffect } from 'react'; // useState import removed since it's unused
import React, { useState } from 'react';
import '../global.css';


function Step1({onSubmit, advSt, generalDenial, inputArray, defendant}) {
  const [selectedValues, setSelectedValues] = useState({});

  const handleSelectChange = (id, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: value
    }));
  };

  const [selectedDefendants, setSelectedDefendants] = useState([]);

  const handleDefendantCheckboxChange = (e, name) => {
    if (e.target.checked) {
      setSelectedDefendants([...selectedDefendants, name]);
    } else {
      setSelectedDefendants(selectedDefendants.filter(def => def !== name));
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          <label className='label'>Defendants</label>
          <div>
            {defendant && defendant.map((name, index) => (
              <div key={index} className="defendant-item">
                <label>
                  <input
                    type="checkbox"
                    value={name}
                    name={`defendant-${index}`} 
                    onChange={(e) => handleDefendantCheckboxChange(e, name)}/>
                  {name}
                </label>
              </div>
            ))}
          </div>
        </div>

        {inputArray ? (
          inputArray.map((item, index) => {
            // Destructure the tuple to get the label and the unique identifier
            const [label, uniqueId] = item;

            // Generate a fallback ID using index if uniqueId is None (or falsy)
            const id = uniqueId || index;

            // Convert id to a string and pad it as needed
            const paddedId = `para${String(id).padStart(4, '0')}`;

            return (
              <><div key={`${uniqueId || paddedId}-${index}`}>
                {label.startsWith('Paragraph') ? (
                  <div style={{ marginBottom:'8px' }}>
                    <label htmlFor={paddedId} className='label'>{label}</label>
                    <select id={paddedId} name={paddedId} onChange={(e) => handleSelectChange(paddedId, e.target.value)} className='select'>
                      <option value="deny">Deny</option>
                      <option value="admit">Admit</option>
                      <option value="notEnoughInfo">Without Knowledge</option>
                      <option value="custom">Custom</option>
                    </select>
                    {selectedValues[paddedId] === 'custom' && (
                      <textarea
                        className="textarea"
                        type="text"
                        placeholder="Enter custom response"
                        name={`customResponse-${paddedId}`} style={{marginTop:'8px' }} />
                    )}
                  </div>
                ) : (
                  <label className='title is-5' style={{ marginTop:'40px', marginBottom:'4px'}}>{label}</label>
                )}
              </div></>
            );
          })
        ) : (
          <div className='block'>
            <label htmlFor="generalDenial">Suggested General Denail</label>
            <textarea className="textarea" id="generalDenial" name="generalDenial" defaultValue={generalDenial} rows="8" cols="50"></textarea>
          </div>
        )}
        <div className="has-text-right" style={{ marginTop:'24px' }}>
          <button className="button is-normal is-success" type="submit">Next: Affirmative defenses</button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
